import {
  Flex,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'
import React from 'react'
import Cropper from 'react-easy-crop'
import { Point } from 'react-easy-crop/types'
import getCroppedImg from 'src/helpers/imageHelper'
import { PrimaryButton } from './Button/PrimaryButton'

interface CropImageDialogProps {
  image: string
  open: boolean
  handleClose: () => void
  aspect: number
  onComplete: (img: { url: string; file: File }) => void
}

const CropImageDialog = ({ image, open, handleClose, aspect, onComplete }: CropImageDialogProps) => {
  const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<{ x: number; y: number; width: number; height: number }>()

  const onCropComplete = React.useCallback(async (_croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onSubmit = async () => {
    if (image && croppedAreaPixels) {
      const croppedImage: any = await getCroppedImg(image, croppedAreaPixels)
      onComplete(croppedImage)
      setCroppedAreaPixels(undefined)
      setCrop({ x: 0, y: 0 })
      setZoom(1)
      handleClose()
    }
  }

  return (
    <Modal isOpen={open} onClose={handleClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crop image </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid spacing={2}>
            <GridItem position="relative" minH="20rem">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </GridItem>
            <GridItem>
              <Slider defaultValue={1} value={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(zoom) => setZoom(zoom)}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb bg="primary.500" />
              </Slider>
            </GridItem>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <PrimaryButton variant="outline" onClick={handleClose}>
              Cancel
            </PrimaryButton>
            <PrimaryButton onClick={onSubmit} autoFocus>
              Crop
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CropImageDialog
