import { Box, Center, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IAddress } from 'src/types/Address'
import { IStore } from 'src/types/User'

interface StoreCardProps {
  store: IStore
}

export function StoreCard({ store }: StoreCardProps) {
  const navigate = useNavigate()

  const handleClickStore = () => {
    navigate(`/app/stores/${store._id}`)
  }

  return (
    <Flex
      w="100%"
      maxW="20.9375rem"
      h="15.4375rem"
      direction="column"
      borderRadius="xl"
      overflow="hidden"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)"
      onClick={handleClickStore}
      cursor="pointer"
      role="group"
    >
      <Center objectFit="cover" w="100%" h="9.375rem" overflow="hidden">
        <Image src={store.businessLogo} alt={store.name} minW="100%" minH="100%" />
      </Center>
      <Box p=".5rem .75rem">
        <Text variant="text-20-bold" _groupHover={{ color: 'primary.500' }}>
          {store.businessName}
        </Text>
        <Text color="gray.600">{(store.address as IAddress)?.suburb}</Text>
        <Divider my="2" />
        <Text color="gray.600">Code: {store.code}</Text>
      </Box>
    </Flex>
  )
}
