import { generateMultipartBodyForm } from 'src/helpers/ApiHelper'
import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { ICharity } from 'src/types/Charity'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { api } from '.'

type ICharityApi = {
  get(params: PaginationData<ICharity>): Promise<PaginationResponse<ICharity>>
  getAllowedToReceiveBags(params: PaginationData<ICharity>): Promise<PaginationResponse<ICharity>>
  getById(id: string): Promise<ICharity>
  create(charity: ICharity): Promise<{ success: boolean }>
  update(charity: ICharity): Promise<{ success: boolean }>
  validateABN(abn: string): Promise<{ legalName: string }>
  getForPayment(): Promise<ICharity[]>
  payCharities(idList: string[]): Promise<{ success: boolean }>
}

const CharityApi = (): ICharityApi => {
  const charityApi = '/admin/charity'

  const get = (params: PaginationData<ICharity>): Promise<PaginationResponse<ICharity>> => api.get(`${charityApi}${generateParamsFromPagination(params)}`)
  const getAllowedToReceiveBags = (params: PaginationData<ICharity>): Promise<PaginationResponse<ICharity>> => api.get(`${charityApi}${generateParamsFromPagination(params)}&isAllowedToReceiveBags=true`)
  const create = (charity: ICharity): Promise<{ success: boolean }> => api.post(charityApi, generateMultipartBodyForm(charity), { headers: { 'Content-Type': 'multipart/form-data' } })
  const update = (charity: ICharity): Promise<{ success: boolean }> => {
    const { id, ...rest } = charity
    return api.patch(`${charityApi}/${id}`, generateMultipartBodyForm(rest), { headers: { 'Content-Type': 'multipart/form-data' } })
  }
  const getById = (id: string): Promise<ICharity> => api.get(`${charityApi}/${id}`)
  const validateABN = (abn: string): Promise<{ legalName: string }> => api.get(`${charityApi}/validate-abn/?abn=${abn}`)
  const payCharities = (idList: string[]): Promise<{ success: boolean }> => api.post(`${charityApi}/pay`, { charityIdList: idList })
  const getForPayment = (): Promise<ICharity[]> => api.get(`${charityApi}/list-for-payment`)

  return {
    get,
    getAllowedToReceiveBags,
    getById,
    create,
    update,
    validateABN,
    getForPayment,
    payCharities
  }
}

export default CharityApi()
