import { Button, Icon, Td } from '@chakra-ui/react'
import { AiOutlineEye } from 'react-icons/ai'
import { RiPencilLine } from 'react-icons/ri'
import { CustomDataTableProps } from '.'

interface CustomDataTableActionButtonsProps<T> extends Pick<CustomDataTableProps<T>, 'onEditClick' | 'onViewClick'> {
  rowIndex: number
}

export function CustomDataTableActionButtons<T>(props: CustomDataTableActionButtonsProps<T>) {
  return (
    <>
      {props.onEditClick && (
        <Td isNumeric>
          <Button
            as="a"
            size="sm"
            fontSize="sm"
            leftIcon={<Icon as={RiPencilLine} fontSize="16" />}
            onClick={() => props.onEditClick(props.rowIndex)}
            cursor="pointer"
          >
            Edit
          </Button>
        </Td>
      )}
      {props.onViewClick && (
        <Td isNumeric>
          <Button
            as="a"
            size="sm"
            fontSize="sm"
            leftIcon={<Icon as={AiOutlineEye} fontSize="16" />}
            onClick={() => props.onViewClick(props.rowIndex)}
            cursor="pointer"
          >
            View
          </Button>
        </Td>
      )}
    </>
  )
}
