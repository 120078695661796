import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AnalyticsQuickTipApi from 'src/api/analyticsQuickTip'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IAnalyticsQuickTip } from 'src/types/AnalyticsQuickTip'
import tableColumns from './tableColumns'

const AnalyticsQuickTip = () => {
  const navigate = useNavigate()
  const [analyticsQuickTipList, setAnalyticsQuickTipList] = React.useState<IAnalyticsQuickTip[]>([])
  const { dispatch } = React.useContext(AppContext)

  const getAnalyticsQuickTipList = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AnalyticsQuickTipApi.get()
      .then((data: IAnalyticsQuickTip[]) => {
        setAnalyticsQuickTipList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setAnalyticsQuickTipList])

  React.useEffect(() => {
    getAnalyticsQuickTipList()
  }, [getAnalyticsQuickTipList])

  const handleClick = (rowIndex: number) => navigate(analyticsQuickTipList[rowIndex].id)

  const handleAdd = () => navigate('new')

  return (
    <Page title="Analytics Quick Tips">
      <Toolbar
        title="Analytics Quick Tips"
        buttons={
          <Flex gap={2}>
            <PrimaryButton onClick={handleAdd}>Add AnalyticsQuickTip</PrimaryButton>
          </Flex>
        }
      />
      <Box mt={3}>
        <CustomDataTable onEditClick={handleClick} data={analyticsQuickTipList || []} tableColumns={tableColumns} />
      </Box>
    </Page>
  )
}

export default AnalyticsQuickTip
