import { Badge, Flex, GridItem, Link, SimpleGrid } from '@chakra-ui/react'
import { formatDateToString } from 'src/helpers/stringHelper'
import { IAmbassador } from 'src/types/Ambassador'
import { DataTextField } from '../Form/DataTextField'
import { FormCard } from './FormCard'

interface AmbassadorDetailsCardProps {
  ambassador: IAmbassador
}

export function AmbassadorDetailsCard({ ambassador }: AmbassadorDetailsCardProps) {
  return (
    <FormCard title="Ambassador Details">
      <SimpleGrid columns={[1, 1, 3]} spacing={4}>
        <GridItem>
          <Flex gap={2} align="center">
            <DataTextField title="Name">
              {ambassador?.firstName} {ambassador?.lastName}
            </DataTextField>
          </Flex>
        </GridItem>
        <GridItem>
          <DataTextField title="Email">{ambassador?.email ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Phone Number">{ambassador?.phoneNumber ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Referral Code">{ambassador?.referralCode ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Ambassador Since">{formatDateToString(ambassador?.createdAt) ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="User"><Link textDecoration="underline" href={`/app/customers/${ambassador?.userId}`}>{ambassador?.firstName} {ambassador?.lastName}</Link></DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Status">
            <Badge colorScheme={ambassador?.isBlocked ? "red" : "green"}>
              {ambassador?.isBlocked ? 'Banned' : "Active"}
            </Badge>
          </DataTextField>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}
