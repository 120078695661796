import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { IDashboard } from 'src/types/Dashboard'
import { api } from '.'

export interface IDashboardUserFilter {
  state: string | null
}

type IDashboardApi = {
  get(payload: IDashboardUserFilter): Promise<IDashboard>
}

const DashboardApi = (): IDashboardApi => {
  const dashboardApi = '/admin/dashboard'

  const get = (payload: IDashboardUserFilter): Promise<IDashboard> =>
    api.get(`${dashboardApi}${generateParamsFromPagination({ filter: { state: payload.state } })}`)

  return {
    get,
  }
}

export default DashboardApi()
