import { Link, LinkProps } from '@chakra-ui/react'
import { ReactElement, cloneElement } from 'react'
import { useLocation } from 'react-router-dom'

interface ActiveLinkProps extends LinkProps {
  children: ReactElement
  shouldMatchExactHref?: boolean
}

export function ActiveLink({ children, shouldMatchExactHref = false, as, ...rest }: ActiveLinkProps) {
  const { pathname } = useLocation()

  let isActive = false

  if (shouldMatchExactHref && (pathname === rest.href || pathname === as)) {
    isActive = true
  }

  if (!shouldMatchExactHref && (pathname.startsWith(String(rest.href)) || pathname.startsWith(String(as)))) {
    isActive = true
  }

  return (
    <Link display="flex" {...rest}>
      {cloneElement(children, { color: isActive ? 'primary.500' : 'gray.800' })}
    </Link>
  )
}
