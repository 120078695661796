import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import MerchandiseApi from 'src/api/merchandise'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IStore } from 'src/types/User'
import StoreActivity from './StoreActivity'
import StoreDetailsForm from './StoreDetailsForm'
import StoreImagesPreview from './StoreImagesPreview'
import StoreMerchandiseStock from './StoreMerchandiseStock'

interface StoreFormProps {
  store: IStore
  setStore: React.Dispatch<React.SetStateAction<IStore>>
  formik: Partial<Formik<IStore>>
}

const StoreForm = (props: StoreFormProps) => {
  const { dispatch } = useContext(AppContext)
  const [activeMerchandises, setActiveMerchandises] = React.useState([])

  useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    MerchandiseApi.listActive()
      .then((res) => setActiveMerchandises(res))
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch])

  return (
    <Tabs isFitted mt="3">
      <TabList bg="white" borderRadius="lg" boxShadow="xs">
        <Tab borderLeftRadius="lg">Store Details</Tab>
        <Tab>Images Preview</Tab>
        <Tab>Activity</Tab>
        <Tab borderRightRadius="lg">Merchandise Stock</Tab>
      </TabList>
      <TabPanels>
        <TabPanel p="0">
          <StoreDetailsForm store={props.store} setStore={props.setStore} formik={props.formik} />
        </TabPanel>
        <TabPanel p="0">
          <StoreImagesPreview store={props.store} setStore={props.setStore} />
        </TabPanel>
        <TabPanel p="0">
          <StoreActivity store={props.store} />
        </TabPanel>
        <TabPanel p="0">
          <StoreMerchandiseStock store={props.store} activeMerchandises={activeMerchandises} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default StoreForm
