import { generateMultipartBodyForm } from 'src/helpers/ApiHelper'
import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { IGetRepeatedCustomersStatsResponse } from 'src/types/RepeatedCustomersResponse'
import { IStore } from 'src/types/User'
import { IUserActivity } from 'src/types/UserActivity'
import { api } from '.'

interface IGetStoresWithFavouritesParams extends PaginationData<IStore> {
  dateRange: string
}

type IStoreApi = {
  get(params: PaginationData<IStore>): Promise<PaginationResponse<IStore>>
  list(): Promise<IStore[]>
  listActive(): Promise<IStore[]>
  getActivities(storeId: string, limit: number): Promise<PaginationResponse<IUserActivity>>
  getWithPayment(): Promise<IStore[]>
  getById(id: string): Promise<IStore>
  changeStatus(idList: string[]): Promise<{ success: boolean }>
  triggerResetPassword(id: string): Promise<{ success: boolean }>
  changeVisibility(idList: string[]): Promise<{ success: boolean }>
  createTransfer(businessId: string): Promise<IStore[]>
  approve(id: string): Promise<IStore>
  updateSettings(id: string, data: Partial<IStore>): Promise<{ success: boolean }>
  getStoreFavouritesForCSV(): Promise<Partial<IStore>[]>
  getStoresWithFavourites(paginationData: IGetStoresWithFavouritesParams): Promise<Partial<IStore>[]>
  getRepeatedCustomers(storeId: string): Promise<IGetRepeatedCustomersStatsResponse>
}

const StoreApi = (): IStoreApi => {
  const storeApi = '/admin/store'

  const get = (params: PaginationData<IStore>): Promise<PaginationResponse<IStore>> => api.get(`${storeApi}${generateParamsFromPagination(params)}`)
  const list = (): Promise<IStore[]> => api.get(`${storeApi}/list`)
  const listActive = (): Promise<IStore[]> => api.get(`${storeApi}/active`)
  const getWithPayment = (): Promise<IStore[]> => api.get(`${storeApi}/payments`)
  const createTransfer = (businessId: string): Promise<IStore[]> => api.post(`${storeApi}/payments/${businessId}`)
  const getById = (id: string): Promise<IStore> => api.get(`${storeApi}/${id}`)
  const getActivities = (userId: string, limit: number): Promise<PaginationResponse<IUserActivity>> =>
    api.get(`${storeApi}/${userId}/activity?limit=${limit}`)
  const triggerResetPassword = (id: string): Promise<{ success: boolean }> => api.post(`${storeApi}/${id}/reset-password`)
  const changeStatus = (idList: string[]): Promise<{ success: boolean }> => api.post(`${storeApi}/${idList}/status`)
  const changeVisibility = (idList: string[]): Promise<{ success: boolean }> => api.post(`${storeApi}/${idList}/visibility`)
  const approve = (id: string): Promise<IStore> => api.post(`${storeApi}/${id}/approve`)
  const updateSettings = (id: string, data: Partial<IStore>): Promise<{ success: boolean }> =>
    api.patch(`${storeApi}/${id}/settings`, generateMultipartBodyForm(data))
  const getStoreFavouritesForCSV = (): Promise<Partial<IStore>[]> => api.get(`${storeApi}/favourites/csv`)
  const getStoresWithFavourites = (payload: IGetStoresWithFavouritesParams): Promise<Partial<IStore>[]> => {
    let url = `${storeApi}/favourites${generateParamsFromPagination(payload)}`
    if (payload.dateRange) url += `&dateRange=${payload.dateRange}`
    return api.get(url)
  }

  const getRepeatedCustomers = (storeId: string): Promise<IGetRepeatedCustomersStatsResponse> => api.get(`${storeApi}/${storeId}/repeated-customers`)

  return {
    get,
    list,
    listActive,
    getActivities,
    getWithPayment,
    getById,
    changeStatus,
    triggerResetPassword,
    changeVisibility,
    createTransfer,
    approve,
    updateSettings,
    getStoreFavouritesForCSV,
    getStoresWithFavourites,
    getRepeatedCustomers
  }
}

export default StoreApi()
