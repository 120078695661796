import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import OrderApi from 'src/api/order'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import OrderCancellationReasonDialog from 'src/components/Overlay/OrderCancellationReasonModal'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import OrderStatusEnum from 'src/enum/OrderStatusEnum'
import { getKeyEnum } from 'src/helpers/enumHelper'
import { Types } from 'src/reducers/AppReducer'
import IOrder, { IOrderCancellationInitialValueProps, orderInitialValue } from 'src/types/Order'
import { OrderForm } from './OrderForm'

const ViewOrder = () => {
  const pageName = 'View Order'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()
  const [order, setOrder] = React.useState<IOrder>(orderInitialValue)
  const cancelOrderDisclosure = useDisclosure()

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    OrderApi.getById(params.id as string)
      .then((res) => {
        setOrder(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id])

  const handleChangeStatus = (values: IOrderCancellationInitialValueProps) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    OrderApi.cancelOrder(params.id as string, values)
      .then(() => {
        AlertSuccess('Order cancelled successfully')
        navigate('/app/orders')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  if (!order)
    return (
      <Page title={pageName}>
        <Toolbar title={pageName} showBack={true} />
        <Text>Loading...</Text>
      </Page>
    )

  return (
    <Page title={pageName}>
      <Toolbar
        title={pageName}
        showBack={true}
        buttons={
          <Flex>
            {order.status !== getKeyEnum(OrderStatusEnum.COLLECTED, OrderStatusEnum) &&
            order.status !== getKeyEnum(OrderStatusEnum.CANCELLED, OrderStatusEnum) ? (
              <PrimaryButton onClick={cancelOrderDisclosure.onOpen}>Cancel Order</PrimaryButton>
            ) : null}
          </Flex>
        }
      />
      <OrderForm order={order} />

      <OrderCancellationReasonDialog
        orderFee={order.fee}
        orderTotal={order.orderTotal}
        open={cancelOrderDisclosure.isOpen}
        handleClose={cancelOrderDisclosure.onClose}
        handleConfirm={handleChangeStatus}
      />
    </Page>
  )
}

export default ViewOrder
