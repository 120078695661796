import { IBusinessSuggestion } from 'src/types/BusinessSuggestion'
import { api } from '.'

type IBusinessSuggestionApi = {
  get(): Promise<IBusinessSuggestion[]>
  getById(id: string): Promise<IBusinessSuggestion>
  update(pb: IBusinessSuggestion): Promise<{ success: boolean }>
  sendEmail(id: string): Promise<{ success: boolean }>
}

const BusinessSuggestionApi = (): IBusinessSuggestionApi => {
  const businessSuggestionApi = '/admin/suggest-business'

  const get = (): Promise<IBusinessSuggestion[]> => api.get(businessSuggestionApi)
  const getById = (id: string): Promise<IBusinessSuggestion> => api.get(`${businessSuggestionApi}/${id}`)
  const update = (pb: IBusinessSuggestion): Promise<{ success: boolean }> => api.patch(`${businessSuggestionApi}/${pb._id}`, pb)
  const sendEmail = (id: string): Promise<{ success: boolean }> => api.get(`${businessSuggestionApi}/${id}/send-email`)

  return {
    get,
    getById,
    update,
    sendEmail,
  }
}

export default BusinessSuggestionApi()
