import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, GridItem, SimpleGrid } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { Checkbox } from 'src/components/Form/Checkbox'
import { Input } from 'src/components/Form/Input'
import { IStoreFilter, storeFilterInitialValue } from 'src/types/User'

interface StoreFilterProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (filter: IStoreFilter) => void
}

const StoreFilter = ({ isOpen, onClose, onSubmit }: StoreFilterProps) => {
  const { errors, values, handleBlur, handleChange, resetForm, handleSubmit } = useFormik({
    initialValues: storeFilterInitialValue,
    onSubmit,
  })

  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">Filter</DrawerHeader>
        <DrawerBody pt="8">
          <form onSubmit={handleSubmit}>
            <SimpleGrid spacing={['6', '8']} w="100%">
              <GridItem>
                <Input name="code" label="Code" error={errors.code} onBlur={handleBlur} onChange={handleChange} value={values.code} />
              </GridItem>
              <GridItem>
                <Input name="businessName" label="Store name" error={errors.businessName} onBlur={handleBlur} onChange={handleChange} value={values.businessName} />
              </GridItem>
              <GridItem>
                <Input name="contactName" label="Contact name" error={errors.contactName} onBlur={handleBlur} onChange={handleChange} value={values.contactName} />
              </GridItem>
              <GridItem>
                <Checkbox name="pendingApproval" label="Pending approval" error={errors.pendingApproval} onBlur={handleBlur} onChange={handleChange} isChecked={values.pendingApproval} />
              </GridItem>
              <GridItem>
                <Flex gap="4" align="flex-end" w="100%">
                  <PrimaryButton variant="outline" onClick={() => resetForm()}>
                    Reset
                  </PrimaryButton>
                  <PrimaryButton type="submit">Apply</PrimaryButton>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default StoreFilter
