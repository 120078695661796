export interface ISuggestionReason {
  _id?: string
  description: string
  emailDescription: string
  isActive: boolean

  [key: string]: any
}

export const suggestionReasonInitialValue: ISuggestionReason = {
  description: '',
  emailDescription: '',
  isActive: false,
}
