import { Box } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AmbassadorApi from 'src/api/ambassador'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableState } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { IAmbassador } from 'src/types/Ambassador'
import { PaginationResponse } from 'src/types/Pagination'
import tableColumns from './tableColumns'

const Ambassador = () => {
  const navigate = useNavigate()
  const [ambassadorList, setAmbassadorList] = React.useState<PaginationResponse<IAmbassador>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const [tableState, setTableState] = React.useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  const getAmbassadorList = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AmbassadorApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IAmbassador>) => {
        setAmbassadorList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setAmbassadorList, tableState])

  React.useEffect(() => {
    getAmbassadorList()
  }, [getAmbassadorList])

  const handleClick = (rowIndex: number) => navigate(ambassadorList.data[rowIndex].id)

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  return (
    <Page title="Ambassadors">
      <Toolbar title="Ambassadors" />
      <Box mt={3}>
        <CustomDataTable onEditClick={handleClick} data={ambassadorList} tableColumns={tableColumns} tableState={tableState}
          onTableChange={onTableChange} serverSidePagination />
      </Box>
    </Page>
  )
}

export default Ambassador
