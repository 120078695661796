import ScreenEnum from 'src/enum/ScreenEnum'
import { generateDeepLink } from 'src/helpers/stringHelper'
import { AlertSuccess } from './Alert'
import { PrimaryButton } from './Button/PrimaryButton'

interface CopyLinkButtonProps {
  screen?: ScreenEnum
  shortUrl?: string
  id?: string
}

const CopyLinkButton = ({ shortUrl, screen, id }: CopyLinkButtonProps) => {
  const copyLink = () => {
    if (screen) {
      navigator.clipboard.writeText(generateDeepLink(screen, id))
    } else if (shortUrl) {
      navigator.clipboard.writeText(shortUrl)
    }

    AlertSuccess('Link copied to clipboard')
  }

  return (
    <PrimaryButton variant="outline" onClick={copyLink} w="100%" mt="2">
      Copy Link to App
    </PrimaryButton>
  )
}

export default CopyLinkButton
