import { Button } from '@chakra-ui/react'
import { TableColumn } from 'src/components/CustomDataTable'
import { IAccountManager } from 'src/types/AccountManager'

const tableColumns: TableColumn<IAccountManager>[] = [
  {
    label: 'Code',
    name: 'code',
  },
  {
    label: 'First name',
    name: 'firstName',
  },
  {
    label: 'Last name',
    name: 'lastName',
  },
  {
    label: 'Company name',
    name: 'companyName',
    options: {
      customRender: (values) => values.companyName || '-',
    },
  },
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
  },
  {
    label: 'Referral Link',
    name: 'referralLink',
    options: {
      customRender: (values) => <Button onClick={() => navigator.clipboard.writeText(values.referralLink)}>Copy</Button>,
    },
  },
]

export default tableColumns
