import IReview from 'src/types/Review'
import { api } from '.'

type IReviewApi = {
  get(): Promise<IReview[]>
}

const ReviewApi = (): IReviewApi => {
  const reviewApi = '/admin/review'

  const get = (): Promise<IReview[]> => api.get(reviewApi)

  return {
    get,
  }
}

export default ReviewApi()
