import { TableColumn } from 'src/components/CustomDataTable'
import { IAmbassador } from 'src/types/Ambassador'

const tableColumns: TableColumn<IAmbassador>[] = [
  {
    label: 'Name',
    name: 'name',
    options: {
      customRender: (value: IAmbassador) => <span>{value.firstName} {value.lastName}</span>,
    }
  },
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
  },
  {
    label: 'Referral Code',
    name: 'referralCode',
  },
  {
    label: 'Location',
    name: 'address.description',
  },
  {
    label: "Is Approved",
    name: "isApproved",
    options: {
      customRender: (value: IAmbassador) => <span>{value.isApproved ? 'Yes' : 'No'}</span>,
    }
  },
  {
    label: "Is Banned",
    name: "isBlocked",
    options: {
      customRender: (value: IAmbassador) => <span>{value.isBlocked ? 'Yes' : 'No'}</span>,
    }
  }
]

export default tableColumns
