import { TableColumn } from 'src/components/CustomDataTable'
import { IPromotionalBanner } from 'src/types/PromotionalBanner'

const tableColumns: TableColumn<IPromotionalBanner>[] = [
  {
    label: 'Title',
    name: 'title',
  },
  {
    label: 'Description',
    name: 'description',
  },
  {
    label: 'Location',
    name: 'location.state',
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true,
  },
]

export default tableColumns
