import { GridItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import ProductApi from 'src/api/product'
import ProductCategoryApi from 'src/api/productCategory'
import AppContext from 'src/contexts/AppContext'
import productPackageTypeEnum from 'src/enum/productPackageTypeEnum'
import productTypeEnum from 'src/enum/productTypeEnum'
import { enumKeys, getEnumValue, getKeyEnum } from 'src/helpers/enumHelper'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import IProduct, { productInitialValue } from 'src/types/Product'
import { IProductCategory } from 'src/types/ProductCategory'
import { IUploadFile } from 'src/types/UploadFile'
import { IStore } from 'src/types/User'
import productValidationSchema from 'src/views/Order/productValidationSchema'
import { AlertError, AlertSuccess } from './Alert'
import { PrimaryButton } from './Button/PrimaryButton'
import { Checkbox } from './Form/Checkbox'
import ImageUpload from './Form/ImageUpload'
import { Input } from './Form/Input'
import { MultiSelect } from './Form/MultiSelect'

interface AddProductModalProps {
  open: boolean
  handleClose: () => void
  store?: IStore
  setStore: (store: IStore) => void
}

const AddProductModal: React.FC<AddProductModalProps> = ({ open, handleClose, store, setStore }) => {
  const [productCategoryList, setProductCategoryList] = React.useState<IProductCategory[]>([])
  const { dispatch } = React.useContext(AppContext)

  const onSubmit = async (values: IProduct) => {
    if (!values.pictureFile) {
      AlertError('Please, upload your product image')
      return false
    }
    if (values.grocerPrice >= values.regularPrice) {
      AlertError('Foody Bag Price must be lower than Regular Price')
      return false
    }
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ProductApi.create({ ...values, isActive: undefined })
      .then((result: IProduct) => {
        if (result) {
          const tempStore = { ...store } as IStore
          tempStore.products.push(result)
          setStore(tempStore)
          AlertSuccess('Product created successfully!')
          handleClose()
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const { setFieldValue, errors, handleBlur, handleChange, handleSubmit, values } = useFormik({
    initialValues: productInitialValue,
    onSubmit,
    validationSchema: productValidationSchema,
  }) as unknown as Formik<IProduct>

  React.useEffect(() => {
    setFieldValue('grocerId', store?._id)
  }, [setFieldValue, store?._id])

  React.useEffect(() => {
    ProductCategoryApi.get()
      .then((res) => {
        if (res) setProductCategoryList(res)
      })
      .catch((err: Error) => console.log(err))
  }, [dispatch])

  React.useEffect(() => {
    if (values.type === getKeyEnum(productTypeEnum.ONE_ITEM, productTypeEnum)) setFieldValue('packageType', '')
  }, [values.type, setFieldValue])

  const onUpload = (_name: string, file: IUploadFile) => {
    setFieldValue('pictureFile', file)
  }

  return (
    <Modal isOpen={open} onClose={handleClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Create Product for {store?.businessName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid spacing={2} columns={[1, 1, 4]}>
              <GridItem colSpan={1}>
                <ImageUpload onUpload={onUpload} name="picture" showPreview={true} />
              </GridItem>
              <GridItem colSpan={[1, 1, 3]}>
                <SimpleGrid spacing={2} columns={[1, 1, 3]}>
                  <GridItem>
                    <Input error={errors.name} label="Name" name="name" onBlur={handleBlur} onChange={handleChange} value={values.name} />
                  </GridItem>
                  <GridItem>
                    <MultiSelect
                      isMulti={false}
                      error={errors.type}
                      label="Type"
                      name="type"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.type}
                      options={enumKeys(productTypeEnum).map((key: string) => ({ label: getEnumValue(key, productTypeEnum), value: key }))}
                    />
                  </GridItem>

                  <GridItem>
                    <MultiSelect
                      isMulti={false}
                      error={errors.packageType}
                      label="Food Type"
                      name="packageType"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.packageType}
                      options={enumKeys(productPackageTypeEnum).map((key: string) => ({
                        label: getEnumValue(key, productPackageTypeEnum),
                        value: key,
                      }))}
                    />
                  </GridItem>

                  <GridItem>
                    <Input
                      error={errors.regularPrice}
                      label="Regular Price"
                      name="regularPrice"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.regularPrice}
                      type="number"
                      isCurrency
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      error={errors.grocerPrice}
                      label="Foody Bag Price"
                      name="grocerPrice"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.grocerPrice}
                      type="number"
                      isCurrency
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      type="number"
                      error={errors.quantityAvailable}
                      label="Quantity available"
                      name="quantityAvailable"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.quantityAvailable}
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      error={errors.defaultQuantity}
                      label="Default Quantity"
                      name="defaultQuantity"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.defaultQuantity}
                      type="number"
                    />
                  </GridItem>

                  <GridItem>
                    <MultiSelect
                      isMulti={false}
                      error={errors.category}
                      label="Category"
                      name="category"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.category}
                      options={productCategoryList.map((pc: IProductCategory) => ({ label: pc.name, value: pc._id }))}
                    />
                  </GridItem>

                  <GridItem>
                    <Input
                      error={errors.dailyLimit}
                      label="Daily limit per user"
                      name="dailyLimit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dailyLimit}
                      type="number"
                    />
                  </GridItem>

                  <GridItem>
                    <Input
                      error={errors.itemsInBag}
                      label="Items in Bag"
                      name="itemsInBag"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.itemsInBag}
                      type="number"
                    />
                  </GridItem>

                  <GridItem>
                    <Checkbox
                      error={errors.isGlutenFree}
                      label="Is Gluten Free?"
                      name="isGlutenFree"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultChecked={values.isGlutenFree}
                      checked={values.isGlutenFree}
                      isChecked={values.isGlutenFree}
                    />
                  </GridItem>

                  <GridItem colSpan={[1, 1, 3]}>
                    <Input
                      error={errors.description}
                      label="Description"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <PrimaryButton type="submit">Save</PrimaryButton>
            <PrimaryButton onClick={handleClose} variant="outline">
              Close
            </PrimaryButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default AddProductModal
