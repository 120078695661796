import { ISurvey } from 'src/types/Survey'
import { api } from '.'

type ISurveyApi = {
  get(): Promise<ISurvey[]>
  getById(id: string): Promise<ISurvey>
  create(survey: ISurvey): Promise<ISurvey>
  update(survey: ISurvey): Promise<ISurvey>
}

const SurveyApi = (): ISurveyApi => {
  const surveyApi = '/admin/survey'

  const get = (): Promise<ISurvey[]> => api.get(surveyApi)
  const create = (survey: ISurvey): Promise<ISurvey> => api.post(surveyApi, survey)
  const update = (survey: ISurvey): Promise<ISurvey> => {
    const { id, ...rest } = survey
    return api.patch(`${surveyApi}/${id}`, rest)
  }
  const getById = (id: string): Promise<ISurvey> => api.get(`${surveyApi}/${id}`)

  return {
    get,
    getById,
    create,
    update,
  }
}

export default SurveyApi()
