import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import addressApi from 'src/api/address'
import { AutoCompleteInput } from 'src/components/Form/AutoCompleteInput'
import ImageUpload from 'src/components/Form/ImageUpload'
import { Input } from 'src/components/Form/Input'
import { Textarea } from 'src/components/Form/Textarea'
import { FormCard } from 'src/components/Layout/FormCard'
import { useAppContext } from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IPromotionalBanner } from 'src/types/PromotionalBanner'
import { IUploadFile } from 'src/types/UploadFile'
import { debounce } from 'throttle-debounce'

const PromotionalBannerForm: React.FC<{ formik: Formik<IPromotionalBanner> }> = ({ formik }) => {
  const { dispatch } = useAppContext()
  const [addressList, setAddressList] = useState<{ label: string; value: string }[]>([])
  const { errors, values, handleBlur, setFieldValue, handleChange } = formik

  const handleSearchAddress = useMemo(
    () =>
      debounce(800, (address?: string) => {
        if (!address || address.length <= 3) {
          setAddressList([{ label: 'Remove location', value: null }])
          return false
        }
        dispatch({ type: Types.Loading, payload: { loading: true } })
        addressApi
          .getByAddress(address, true)
          .then((addressesResult) => {
            setAddressList(addressesResult.map((address) => ({ label: address.description, value: address.placeId })))
          })
          .catch((err: Error) => console.log(err))
          .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
      }),
    [dispatch]
  )

  const handleAddressSelect = useCallback(
    (option: { value: string; label: string }) => {
      setFieldValue('placeId', option.value)
      setFieldValue('locationDescription', option.label)
    },
    [setFieldValue]
  )

  const onUpload = React.useCallback(
    (name: string, file: IUploadFile) => {
      setFieldValue(name, file)
    },
    [setFieldValue]
  )

  return (
    <FormCard title="Banner Details">
      <SimpleGrid columns={[1, 1, 4]} spacing={4}>
        <GridItem>
          <ImageUpload onUpload={onUpload} name="pictureFile" showPreview={true} currentPicture={values.picture} />
        </GridItem>
        <GridItem colSpan={3}>
          <SimpleGrid columns={1} spacing={4}>
            <GridItem>
              <Input error={errors.title} label="Title" name="title" onBlur={handleBlur} onChange={handleChange} value={values.title} />
            </GridItem>
            <GridItem>
              <Input error={errors.subtitle} label="Subtitle" name="subtitle" onBlur={handleBlur} onChange={handleChange} value={values.subtitle} />
            </GridItem>
            <GridItem>
              <Textarea
                rows={6}
                error={errors.description}
                label="Description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
              />
            </GridItem>
            <GridItem>
              <AutoCompleteInput
                label="Location - State (optional) - Australia only"
                name="locationDescription"
                error={errors.placeId}
                options={addressList}
                getData={handleSearchAddress}
                onValueSelected={handleAddressSelect}
                onChange={handleChange}
                value={values.locationDescription}
              />
            </GridItem>
          </SimpleGrid>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default PromotionalBannerForm
