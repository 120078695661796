import { GridItem, SimpleGrid } from '@chakra-ui/react'
import OrderStatusEnum from 'src/enum/OrderStatusEnum'
import { formatDateToString } from 'src/helpers/stringHelper'
import { IOrderHistory } from 'src/types/Order'
import { DataTextField } from '../Form/DataTextField'
import { FormCard } from './FormCard'

interface OrderTimelineCardProps {
  history: IOrderHistory[]
}

export function OrderTimelineCard({ history }: OrderTimelineCardProps) {
  return (
    <FormCard title="Order Timeline">
      {history?.length > 0 &&
        history.map((item: IOrderHistory, i: number) => (
          <SimpleGrid key={`${item.status}-${i}`} columns={[1, 1, 2]} borderBottom="1px solid" _first={{ pt: 0 }} py="4" borderColor="gray.200">
            <GridItem>
              <DataTextField title="Status">{OrderStatusEnum[item.status]}</DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Date">
                {item.createdAt
                  ? formatDateToString(item.createdAt, {
                      month: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  : '-'}
              </DataTextField>
            </GridItem>
            <GridItem colSpan={[1, 1, 2]}>
              <DataTextField title="Details">{item.comment}</DataTextField>
            </GridItem>
          </SimpleGrid>
        ))}
    </FormCard>
  )
}
