import { Box, BoxProps } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { Ref } from 'src/types'

interface PageProps extends BoxProps {
  children: React.ReactNode
  title: string
  className?: string
}

const Page = forwardRef<Ref, PageProps>(({ children, title = '', ...rest }: PageProps, ref) => {
  return (
    <Box ref={ref} w="100%" h="100%" {...rest}>
      <Helmet>
        <title>{`Foody Bag - ${title}`}</title>
      </Helmet>
      {children}
    </Box>
  )
})

export default Page
