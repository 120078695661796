import React, { useEffect, useRef, useState } from 'react'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box, Button } from '@chakra-ui/react'
import { CSVLink } from 'react-csv'
import UserApi from 'src/api/user'
import { FormCard } from 'src/components/Layout/FormCard'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'

const CustomerReports = () => {
  const { dispatch } = React.useContext(AppContext)
  const [downloadData, setDownloadData] = useState<any[]>([])
  const csvLinkEl = useRef<any>()
  const [reportFileName, setReportFileName] = useState<string>('')

  const getCustomerFavouritesDataForCSV = async () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    const data = await UserApi.getUserWithOrdersListForCSV().finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    setDownloadData(data)
    setReportFileName('users-with-orders.csv')
  }

  useEffect(() => {
    if (csvLinkEl?.current && downloadData.length > 0) csvLinkEl.current.link.click()
  }, [reportFileName, downloadData])

  const headers = [
    { key: 'firstName', label: 'First name' },
    { key: 'lastName', label: 'Last name' },
    { key: 'email', label: 'Email' },
    { key: 'loginPhoneNumber', label: 'Phone Number' },
    { key: 'suburb', label: 'Suburb' },
    { key: 'state', label: 'State' },
    { key: 'country', label: 'Country' },
    { key: 'createdAt', label: 'User since' },
    { key: 'ordersCount', label: 'Orders' },
  ]

  return (
    <Page title="Customer Reports">
      <Toolbar title="Customer Reports" />
      <Box mt={3}>
        <FormCard title="Customer list with orders count" minW="22.5rem" mt="0">
          <Button onClick={getCustomerFavouritesDataForCSV}>Download </Button>
          {/* @ts-ignore-next-line */}
          <CSVLink data={downloadData} headers={headers} filename={reportFileName} ref={csvLinkEl} />
        </FormCard>
      </Box>
    </Page>
  )
}

export default CustomerReports
