import { IVoucher } from 'src/types/Voucher'
import { api } from '.'

type IVoucherApi = {
  get(): Promise<IVoucher[]>
  getById(id: string): Promise<IVoucher>
  create(voucher: IVoucher): Promise<{ success: boolean }>
  update(voucher: IVoucher): Promise<{ success: boolean }>
  changeStatus(id: string): Promise<{ success: boolean }>
}

const VoucherApi = (): IVoucherApi => {
  const voucherApi = '/admin/voucher'

  const get = (): Promise<IVoucher[]> => api.get(voucherApi)
  const getById = (id: string): Promise<IVoucher> => api.get(`${voucherApi}/${id}`)
  const create = (voucher: IVoucher): Promise<{ success: boolean }> => api.post(voucherApi, voucher)
  const update = (voucher: IVoucher): Promise<{ success: boolean }> => api.patch(`${voucherApi}/${voucher._id}`, voucher)
  const changeStatus = (id: string): Promise<{ success: boolean }> => api.post(`${voucherApi}/${id}/status`)

  return {
    get,
    getById,
    create,
    update,
    changeStatus,
  }
}

export default VoucherApi()
