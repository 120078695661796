import { IUploadFile } from "./UploadFile"
import IUser from "./User"

export interface IMerchandise {
  id?: string
  name: string
  description: string
  picture?: string
  price: number
  isActive?: boolean
  pictureFile?: IUploadFile
  availableForGrocers: IMerchandiseAvailability[]
}

export interface IMerchandiseAvailability {
  grocerId: string
  quantityAvailable: number

  grocer?: IUser
}

export const merchandiseInitialValue: IMerchandise = {
  name: '',
  description: '',
  price: null,
  availableForGrocers: [],
}

export const merchandiseAvailabilityInitialValue = {
  quantityAvailable: null
}
