import IProduct from 'src/types/Product'
import { api } from '.'

type IProductApi = {
  changeStatus(id: string): Promise<{ success: boolean }>
  togglePriceCheck(id: string): Promise<{ success: boolean }>
  create(product: IProduct): Promise<IProduct>
  update(id: string, product: IProduct): Promise<IProduct>
}

const ProductApi = (): IProductApi => {
  const productApi = '/admin/product'

  const changeStatus = (id: string): Promise<{ success: boolean }> => api.post(`${productApi}/${id}/status`)
  const togglePriceCheck = (id: string): Promise<{ success: boolean }> => api.post(`${productApi}/${id}/toggle-check-price`)
  const create = (product: IProduct): Promise<IProduct> => api.post(productApi, product)
  const update = (id: string, product: IProduct): Promise<IProduct> => api.patch(`${productApi}/${id}`, product)

  return {
    changeStatus,
    togglePriceCheck,
    create,
    update,
  }
}

export default ProductApi()
