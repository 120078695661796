import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MerchandiseApi from 'src/api/merchandise'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IMerchandise, merchandiseInitialValue } from 'src/types/Merchandise'
import validationSchema from 'src/views/Merchandise/validationSchema'
import MerchandiseForm from './MerchandiseForm'

const EditMerchandise = () => {
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = (values: IMerchandise) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    MerchandiseApi.update({ id: params.id as string, name: values.name, price: values.price, description: values.description, isActive: values.isActive, pictureFile: values.pictureFile } as IMerchandise)
      .then((result) => {
        if (result) {
          AlertSuccess('Merchandise saved successfully!')
          navigate('/app/merchandise')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: merchandiseInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues, handleSubmit } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    MerchandiseApi.getById(params.id as string)
      .then((res: IMerchandise) => {
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title="Edit Merchandise">
      <form onSubmit={handleSubmit}>
        <Toolbar
          title="Edit Merchandise"
          showBack
          buttons={
            <>
              <PrimaryButton variant="solid" type="submit">
                Save
              </PrimaryButton>
            </>
          }
        />
        <MerchandiseForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditMerchandise
