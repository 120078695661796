import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Checkbox } from 'src/components/Form/Checkbox'
import { Input } from 'src/components/Form/Input'
import { MultiSelect } from 'src/components/Form/MultiSelect'
import { FormCard } from 'src/components/Layout/FormCard'
import { OPERATING_SYSTEM_FOR_SELECT } from 'src/constants'
import { IAppVersion } from 'src/types/AppVersion'
import { Formik } from 'src/types/Formik'

const AppVersionForm: React.FC<{ formik: Formik<IAppVersion> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="Voucher Amount Suggestion">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <MultiSelect
            isMulti={false}
            error={errors.os}
            label="Operating System"
            name="os"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.os}
            options={OPERATING_SYSTEM_FOR_SELECT}
          />
        </GridItem>
        <GridItem>
          <Input
            error={errors.version}
            type="number"
            label="Version"
            name="version"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.version}
          />
        </GridItem>
        <GridItem>
          <Checkbox
            error={errors.isRequired}
            label="Is Mandatory?"
            name="isRequired"
            onBlur={handleBlur}
            onChange={handleChange}
            defaultChecked={values.isRequired}
            checked={values.isRequired}
            isChecked={values.isRequired}
            helperText={`Be careful. If true, users won't be able to use old versions`}
          />
        </GridItem>
        <GridItem>
          <Checkbox
            error={errors.isForBusinessApp}
            label="Is for Stores?"
            name="isForBusinessApp"
            onBlur={handleBlur}
            onChange={handleChange}
            defaultChecked={values.isForBusinessApp}
            checked={values.isForBusinessApp}
            isChecked={values.isForBusinessApp}
          />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default AppVersionForm
