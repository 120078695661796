import { Box, Flex } from '@chakra-ui/react'
import { CiFilter } from 'react-icons/ci'
import { ChakraSpan } from '../Form/ChakraSpan'
import { PrimaryButton } from './PrimaryButton'

interface FilterButtonProps {
  onFilterOpen: () => void
  isActive?: boolean
}

export function FilterButton({ onFilterOpen, isActive }: FilterButtonProps) {
  return (
    <Flex position="relative">
      {isActive && <Box borderRadius="full" bg="red.500" w=".425rem" h=".425rem" zIndex="5" top="0.625rem" right="0.5rem" position="absolute"></Box>}
      <PrimaryButton variant="outline" onClick={onFilterOpen} p={'.3125rem'} _hover={{ backgroundColor: 'primaryAlpha.300' }}>
        <ChakraSpan fontSize="lg">
          <CiFilter />
        </ChakraSpan>
      </PrimaryButton>
    </Flex>
  )
}
