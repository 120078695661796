import { Card, CardBody, CardProps, Divider, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface FromCardProps extends CardProps {
  title?: string
  children: ReactNode
}

export function FormCard({ title, children, ...rest }: FromCardProps) {
  return (
    <Card mt={3} {...rest}>
      <CardBody>
        {title && (
          <>
            <Text variant="text-18-med">{title}</Text>
            <Divider my="3" />
          </>
        )}
        {children}
      </CardBody>
    </Card>
  )
}
