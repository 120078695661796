import { Box, BoxProps, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface DataTextFieldProps extends BoxProps {
  title: string
  children?: ReactNode
}

export function DataTextField({ title, children, ...rest }: DataTextFieldProps) {
  return (
    <Box>
      <Text variant="text-16-semi">{title}</Text>
      <Box {...rest}>{children}</Box>
    </Box>
  )
}
