import { Flex, GridItem, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthApi from 'src/api/auth'
import { AlertError, dismissAllAlerts } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { Input } from 'src/components/Form/Input'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { AuthMfaInitialValue, IAuthResponse } from 'src/types/Auth'
import validationSchema from './validationSchema'

const Mfa = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { dispatch } = React.useContext(AppContext)

  const params = React.useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const qrCode = params.get('code') ? decodeURIComponent(params.get('code')) : undefined
  const email = decodeURIComponent(params.get('email'))

  const onSubmit = React.useCallback(
    (values: any) => {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      dismissAllAlerts()
      AuthApi.validateMfa({ email, passcode: values.passcode })
        .then((data: IAuthResponse) => {
          if (data.user) {
            dispatch({
              type: Types.Authenticate,
              payload: {
                user: data.user,
                isAuthenticated: true,
                token: data.token,
                refreshToken: data.refreshToken,
              },
            })

            navigate('/app/dashboard', { replace: true })
          }
        })
        .catch((err) => {
          AlertError('Invalid MFA code')
          console.log(err)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    },
    [navigate, dispatch, email]
  )

  const { errors, values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: AuthMfaInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title="Generate your MFA" w="100%">
      <Flex direction="column" h="100%" w="100%" justify="center" align="center" gap="4">
        {qrCode && (
          <>
            <VStack spacing="0">
              <Text variant="text-20-bold">You must enable MFA before continuing.</Text>
              <Text variant="text-16-reg" align="center">
                Please, scan the following QRCODE with your Authenticator App.
              </Text>
            </VStack>
            <Image src={qrCode} w="20rem" h="20rem" />
          </>
        )}
        <form onSubmit={handleSubmit}>
          <SimpleGrid spacing="4" w="100%" mb="3">
            <GridItem>
              <Text variant="text-16-bold">Enter the code generated by your Authenticator App</Text>
            </GridItem>
            <GridItem>
              <Input error={errors.passcode} label="Code" name="passcode" onBlur={handleBlur} onChange={handleChange} value={values.passcode} />
            </GridItem>
          </SimpleGrid>

          <PrimaryButton w="100%" type="submit" mt="4">
            Validate
          </PrimaryButton>
        </form>
      </Flex>
    </Page>
  )
}

export default Mfa
