import { TableColumn } from 'src/components/CustomDataTable'
import { IMerchandise } from 'src/types/Merchandise'

const tableColumns: TableColumn<IMerchandise>[] = [
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'Price',
    name: 'price',
  },
  {
    label: "Stores Allowed",
    name: "countGrocersAssigned",
  },
  {
    label: "Is Active",
    name: "isActive",
    isBoolean: true
  }
]

export default tableColumns
