import axios, { AxiosRequestConfig, CreateAxiosDefaults } from 'axios'
import { AlertError } from 'src/components/Alert'
import config from 'src/config'
import { refreshTokenApi } from './auth/refreshTokenApi'

export const api = axios.create({
  baseURL: config.apiUrl as string,
  timeout: 20000,
  headers: { Authorization: localStorage.getItem('fbaToken') },
} as CreateAxiosDefaults)

let isRefreshingToken = false
let subscribers = []

api.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = localStorage.getItem('fbaToken')
    return config
  },
  (err) => {
    throw err.response
  }
)

api.interceptors.response.use(
  (result) => {
    if (result?.data?.total) {
      result.data.count = result.data.total
      delete result.data.total
    }
    return result.data
  },
  async (err) => {
    const originalRequest = err.config
    if (!err.response || err.response.status === 500) {
      AlertError('There was a problem connecting to the server. Please try again later')
    } else {
      if (err.response?.data?.code === 'INVALID_TOKEN') {
        localStorage.removeItem('fbaToken')
        // window.location.href = '/login'
      } else if (err.response?.data?.code === 'TOKEN_EXPIRED') {
        if (!isRefreshingToken) {
          isRefreshingToken = true
          subscribers = []
          const response = await getNewAccessToken(originalRequest)
          return response.data
        } else {
          return new Promise((resolve) => {
            subscribers.push(async (token: string) => {
              originalRequest.headers.Authorization = token
              const result = await axios(originalRequest)
              resolve(result.data)
            })
          })
        }
      }

      AlertError(err.response.data.error)
    }
  }
)

const getNewAccessToken = async (originalRequest: AxiosRequestConfig) => {
  const refreshToken = localStorage.getItem('fbaRefreshToken')
  const response = await refreshTokenApi({ refreshToken })

  localStorage.setItem('fbaToken', response.accessToken)
  localStorage.setItem('fbaRefreshToken', response.refreshToken)

  api.defaults.headers['Authorization'] = response.accessToken
  originalRequest.headers['Authorization'] = response.accessToken

  isRefreshingToken = false
  subscribers.forEach((pausedCall) => pausedCall(response.accessToken))

  // Return the original request with the updated headers
  return api(originalRequest)
}
