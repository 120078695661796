import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppVersionApi from 'src/api/appVersion'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IAppVersion, appVersionInitialValue } from 'src/types/AppVersion'
import validationSchema from 'src/views/AppVersion/validationSchema'
import AppVersionForm from './AppVersionForm'

const CreateAppVersion: React.FC = () => {
  const pageName: string = 'Create App Version'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IAppVersion) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AppVersionApi.create({
      os: values.os,
      version: values.version,
      isRequired: values.isRequired,
      isForBusinessApp: values.isForBusinessApp,
    } as IAppVersion)
      .then((result) => {
        if (result?.success) {
          AlertSuccess('App version saved successfully!')
          navigate('/app/app-version')
        }
      })
      .catch((err: Error) => {
        AlertError(err.message)
      })
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: appVersionInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <AppVersionForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreateAppVersion
