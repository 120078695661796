import { Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import ProductApi from 'src/api/product'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import IProduct, { productInitialValue } from 'src/types/Product'
import { IUploadFile } from 'src/types/UploadFile'
import { IStore } from 'src/types/User'
import { AlertError, AlertSuccess } from './Alert'
import { PrimaryButton } from './Button/PrimaryButton'
import ImageUpload from './Form/ImageUpload'

interface UpdateProductPictureModalProps {
  open: boolean
  handleClose: () => void
  product?: IProduct
  setStore: React.Dispatch<React.SetStateAction<IStore>>
}

const UpdateProductPictureModal: React.FC<UpdateProductPictureModalProps> = ({ open, handleClose, product, setStore }) => {
  const { dispatch } = React.useContext(AppContext)

  const onSubmit = async (values: IProduct) => {
    if (!values.pictureFile) {
      AlertError('Please, upload an image')
      return false
    }
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ProductApi.update(product?._id, { pictureFile: values.pictureFile } as IProduct)
      .then((result: IProduct) => {
        if (result) {
          AlertSuccess('Product picture updated successfully!')
          setStore((prev) => {
            const products = prev.products.map((p: IProduct) => {
              if (p._id === result._id) p.picture = result.picture
              return p
            })
            return { ...prev, products }
          })
          handleClose()
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const { setFieldValue, handleSubmit } = useFormik({
    initialValues: productInitialValue,
    onSubmit,
  }) as unknown as Formik<IProduct>

  const onUpload = (_name: string, file: IUploadFile) => {
    setFieldValue('pictureFile', file)
  }

  return (
    <Modal isOpen={open} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Update Product {product?.name} Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <ImageUpload onUpload={onUpload} currentPicture={product?.picture} name="picture" showPreview={true} />
            </Center>
          </ModalBody>
          <ModalFooter gap={2}>
            <PrimaryButton type="submit">Save</PrimaryButton>
            <PrimaryButton onClick={handleClose} variant="outline">
              Close
            </PrimaryButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default UpdateProductPictureModal
