import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Input } from 'src/components/Form/Input'
import { FormCard } from 'src/components/Layout/FormCard'
import { Formik } from 'src/types/Formik'
import { IReviewReplySuggestion } from 'src/types/ReviewReplySuggestion'

const ReviewReplySuggestionForm: React.FC<{ formik: Formik<IReviewReplySuggestion> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <Input error={errors.text} label="Suggestion" name="text" onBlur={handleBlur} onChange={handleChange} value={values.text} />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default ReviewReplySuggestionForm
