import { Flex, Icon, Text } from '@chakra-ui/react'
import { ElementType } from 'react'
import { ActiveLink } from './ActiveLink'

interface NavLinkProps {
  icon: ElementType
  children: string
  href: string
  shouldMatchExactHref?: boolean
}

export function NavLink({ icon, children, href, ...rest }: NavLinkProps) {
  return (
    <ActiveLink href={href} {...rest}>
      <Flex align="center">
        <Icon as={icon} fontSize="18" />
        <Text ml="2" fontWeight="medium">
          {children}
        </Text>
      </Flex>
    </ActiveLink>
  )
}
