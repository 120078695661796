import { Box } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import SuggestionReasonApi from 'src/api/suggestionReason'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ISuggestionReason } from 'src/types/SuggestionReason'
import { VisibilityTableToolbar } from '../../components/CustomDataTable/VisibilityTableToolbar'
import tableColumns from './tableColumns'

const SuggestionReason = () => {
  const navigate = useNavigate()
  const [reasonList, setReasonList] = React.useState<ISuggestionReason[]>([])
  const { dispatch } = React.useContext(AppContext)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    SuggestionReasonApi.get()
      .then((data: ISuggestionReason[]) => {
        setReasonList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setReasonList])

  const handleChangeStatus = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    SuggestionReasonApi.changeStatus({ idList }, activate)
      .then(() => {
        setReasonList((prev: ISuggestionReason[]) =>
          prev.map((reason: ISuggestionReason) => {
            if (idList.indexOf(reason.id) > -1) reason.isActive = activate
            return reason
          })
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleClick = (rowIndex: number) => navigate(reasonList[rowIndex].id || reasonList[rowIndex]._id)
  const handleAdd = () => navigate('new')

  const customToolbar = (selectedRows: number[]) => {
    return <VisibilityTableToolbar data={reasonList} handleChangeStatus={handleChangeStatus} selectedRows={selectedRows} />
  }

  return (
    <Page title="Suggestion Reasons">
      <Toolbar title="Suggestion Reasons" buttons={<PrimaryButton onClick={handleAdd}>Add Reason</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable showCheckbox onEditClick={handleClick} data={reasonList || []} tableColumns={tableColumns} customToolbar={customToolbar} />
      </Box>
    </Page>
  )
}

export default SuggestionReason
