export interface IVoucherAmountSuggestion {
  _id?: string
  amount: string
  isActive: boolean

  [key: string]: any
}

export const voucherAmountSuggestionInitialValue: IVoucherAmountSuggestion = {
  amount: '',
  isActive: false,
}
