import { IStore } from './User'

export interface IVoucher {
  _id?: string
  id?: string
  amount: number
  code: string
  isActive?: boolean
  grocers?: string[]
  usedAt?: string
  usedOn?: Date
  grocerUsed?: IStore
}

export const voucherInitialValue: IVoucher = {
  amount: null,
  code: null,
  grocers: [],
}
