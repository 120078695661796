import { Button, Flex, GridItem, SimpleGrid } from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import AddressApi from 'src/api/address'
import charity from 'src/api/charity'
import { AlertError } from 'src/components/Alert'
import { AutoCompleteInput } from 'src/components/Form/AutoCompleteInput'
import { Checkbox } from 'src/components/Form/Checkbox'
import ImageUpload from 'src/components/Form/ImageUpload'
import { Input } from 'src/components/Form/Input'
import { Textarea } from 'src/components/Form/Textarea'
import { FormCard } from 'src/components/Layout/FormCard'
import { useAppContext } from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ICharity } from 'src/types/Charity'
import { Formik } from 'src/types/Formik'
import { IUploadFile } from 'src/types/UploadFile'
import { debounce } from 'throttle-debounce'

const CharityForm: React.FC<{ formik: Formik<ICharity> }> = ({ formik }) => {
  const [addressList, setAddressList] = useState<{ label: string; value: string }[]>([])
  const { errors, values, handleBlur, handleChange, setFieldValue } = formik
  const { state, dispatch } = useAppContext()

  const handleSearchAddress = useMemo(() =>
    debounce(800, (address?: string) => {
      if (!address || address.length <= 3) {
        setAddressList([{ label: 'Remove location', value: null }])
        return false
      }
      dispatch({ type: Types.Loading, payload: { loading: true } })
      AddressApi.getByAddress(address, false)
        .then((addressesResult) => {
          setAddressList(addressesResult.map((address) => ({ label: address.description, value: address.placeId })))
        })
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    }),
    [dispatch]
  )

  const handleAddressSelect = useCallback(
    (option: { value: string; label: string }) => {
      setFieldValue('addressPlaceId', option.value)
      setFieldValue('locationDescription', option.label)
    },
    [setFieldValue]
  )

  const onUpload = useCallback(
    (name: string, _base64File: IUploadFile, _previewUrl: string, file: File) => {
      setFieldValue(name, file)
    },
    [setFieldValue]
  )

  const handleChangeABN = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    const { value } = e.target
    try {
      const abnData = await charity.validateABN(value)
      if (abnData?.legalName) {
        setFieldValue('legalName', abnData.legalName)
        handleChange(e)
      }
    } catch (error) {
      dispatch({ type: Types.Loading, payload: { loading: false } })
    }
    dispatch({ type: Types.Loading, payload: { loading: false } })
  },
    [handleChange, dispatch, setFieldValue]
  )

  const handleGenerateBankToken = useCallback(async () => {
    if (!values.bsb || !values.accountNumber || !values.legalName) {
      AlertError('Please fill in the BSB, Account Number and ABN')
      return
    }

    dispatch({ type: Types.Loading, payload: { loading: true } })
    try {
      const bankToken = await state.stripe.createToken('bank_account', {
        country: 'AU',
        currency: 'AUD',
        routing_number: values.bsb,
        account_number: values.accountNumber,
        account_holder_type: 'company',
        account_holder_name: values.legalName,
      })

      if (bankToken.error) {
        AlertError(bankToken.error.message)
        dispatch({ type: Types.Loading, payload: { loading: false } })
        return
      }

      setFieldValue('stripeBankAccountToken', bankToken.token?.id)
    } catch (error) {
      console.log(error)
    }

    dispatch({ type: Types.Loading, payload: { loading: false } })
  }, [dispatch, setFieldValue, state.stripe, values.accountNumber, values.bsb, values.legalName])

  return (
    <Flex flexDirection="column" gap="2">
      <FormCard title="Beneficiary Details">
        <SimpleGrid columns={[1, 1, 4]} spacing={2}>
          <GridItem>
            <ImageUpload onUpload={onUpload} name="pictureFile" showPreview={true} currentPicture={values.picture} label="Logo or Picture" />
          </GridItem>
          <GridItem colSpan={[1, 1, 3]}>
            <SimpleGrid columns={[1, 1, 2]} spacing={4}>
              <GridItem>
                <Input error={errors.name} label="Name" name="name" onBlur={handleBlur} onChange={handleChange} value={values.name} />
              </GridItem>
              <GridItem>
                <Input error={errors.websiteUrl} label="Website" name="websiteUrl" onBlur={handleBlur} onChange={handleChange} value={values.websiteUrl} />
              </GridItem>
              <GridItem>
                <Input error={errors.abn} label="ABN" name="abn" onBlur={handleBlur} onChange={handleChangeABN} value={values.abn} />
              </GridItem>
              <GridItem>
                <Input error={errors.legalName} label="Legal name" name="legalName" onBlur={handleBlur} onChange={handleChangeABN} value={values.legalName} disabled />
              </GridItem>
              <GridItem>
                <Input error={errors.contactName} label="Contact Name" name="contactName" onBlur={handleBlur} onChange={handleChange} value={values.contactName} />
              </GridItem>
              <GridItem>
                <Input error={errors.phoneNumber} label="Phone Number" name="phoneNumber" onBlur={handleBlur} onChange={handleChange} value={values.phoneNumber} />
              </GridItem>
              <GridItem>
                <Input error={errors.email} label="Email" name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} />
              </GridItem>
              <GridItem gap={2} colSpan={[1, 1, 2]}>
                <AutoCompleteInput
                  label="Address"
                  name="locationDescription"
                  error={errors.locationDescription}
                  options={addressList}
                  getData={handleSearchAddress}
                  onValueSelected={handleAddressSelect}
                  onChange={handleChange}
                  value={values.locationDescription}
                />
              </GridItem>
              <GridItem gap={2} colSpan={[1, 1, 2]}>
                <Textarea
                  error={errors.description}
                  label="Description"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  rows={5}
                />
              </GridItem>
              <GridItem colSpan={[1, 1, 2]}>
                <Checkbox
                  error={errors.isFoodyBagCharity}
                  label="Is the default beneficiary?"
                  name="isFoodyBagCharity"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultChecked={values.isFoodyBagCharity}
                  checked={values.isFoodyBagCharity}
                  isChecked={values.isFoodyBagCharity}
                />
              </GridItem>
              <GridItem colSpan={[1, 1, 2]}>
                <Checkbox
                  error={errors.isAllowedToReceiveDonations}
                  label="Is allowed to receive money?"
                  name="isAllowedToReceiveDonations"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultChecked={values.isAllowedToReceiveDonations}
                  checked={values.isAllowedToReceiveDonations}
                  isChecked={values.isAllowedToReceiveDonations}
                />
              </GridItem>
              <GridItem colSpan={[1, 1, 2]}>
                <Checkbox
                  error={errors.isAllowedToReceiveBags}
                  label="Is allowed to receive bags?"
                  name="isAllowedToReceiveBags"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  defaultChecked={values.isAllowedToReceiveBags}
                  checked={values.isAllowedToReceiveBags}
                  isChecked={values.isAllowedToReceiveBags}
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
      </FormCard>
      <FormCard title="Bank Account Details">
        <GridItem colSpan={[1, 1, 3]}>
          <SimpleGrid columns={[1, 1, 2]} spacing={4}>
            <GridItem>
              <Input error={errors.bsb} label="BSB" name="bsb" onBlur={handleBlur} onChange={handleChange} value={values.bsb} max={6} />
            </GridItem>
            <GridItem display="flex" gap={2} alignItems="end">
              <Input error={errors.accountNumber} label="Bank Account Number" name="accountNumber" onBlur={handleBlur} onChange={handleChange} value={values.accountNumber} />
              <Button colorScheme="primary" onClick={handleGenerateBankToken}>Verify</Button>
            </GridItem>
            <GridItem>
              <Input error={errors.stripeBankAccountToken} label="Stripe bank account token" name="stripeBankAccountToken" onBlur={handleBlur} onChange={handleChange} value={values.stripeBankAccountToken} disabled />
            </GridItem>
            <GridItem>
              <Input error={errors.stripeAccountId} label="Stripe account ID" name="stripeAccountId" onBlur={handleBlur} onChange={handleChange} value={values.stripeAccountId} disabled />
            </GridItem>
          </SimpleGrid>
        </GridItem>
      </FormCard>
    </Flex>
  )
}

export default CharityForm
