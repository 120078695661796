import { Flex, FlexProps } from '@chakra-ui/react'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'

interface BooleanColumnProps extends FlexProps {
  data: boolean
}

export function BooleanColumn({ data, ...rest }: BooleanColumnProps) {
  return (
    <Flex color={data ? 'green.500' : 'red.500'} justify="center" textAlign="center" fontSize="xl" w="100%" {...rest}>
      {data ? <AiOutlineCheckCircle /> : <AiOutlineCloseCircle />}
    </Flex>
  )
}
