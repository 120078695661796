import { IBroadcasting } from 'src/types/Broadcasting'
import { api } from '.'

type IBroadcastingApi = {
  get(): Promise<IBroadcasting[]>
  getById(id: string): Promise<IBroadcasting>
  create(broadcasting: IBroadcasting, authenticationCode: string): Promise<{ success: boolean }>
}

const BroadcastingApi = (): IBroadcastingApi => {
  const broadcastingApi = '/admin/broadcasting'

  const get = (): Promise<IBroadcasting[]> => api.get(broadcastingApi)
  const create = (broadcasting: IBroadcasting, authenticationCode: string): Promise<{ success: boolean }> =>
    api.post(broadcastingApi, { ...broadcasting, authenticationCode })
  const getById = (id: string): Promise<IBroadcasting> => api.get(`${broadcastingApi}/${id}`)

  return {
    get,
    getById,
    create,
  }
}

export default BroadcastingApi()
