import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CharityApi from 'src/api/charity'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ICharity, charityInitialValue } from 'src/types/Charity'
import validationSchema from 'src/views/Charity/validationSchema'
import CharityForm from './CharityForm'

const EditCharity = () => {
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()

  const formatData = (values: ICharity) => {
    return {
      id: params.id as string,
      name: values.name,
      description: values.description,
      pictureFile: values.pictureFile,
      websiteUrl: values.websiteUrl,
      abn: values.abn,
      legalName: values.legalName,
      stripeBankAccountToken: values.stripeBankAccountToken,
      addressPlaceId: values.addressPlaceId,
      contactName: values.contactName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      isFoodyBagCharity: values.isFoodyBagCharity,
      isAllowedToReceiveDonations: values.isAllowedToReceiveDonations,
      isAllowedToReceiveBags: values.isAllowedToReceiveBags,
      isPendingApproval: values.isPendingApproval
    }
  }

  const onSubmit = (values: ICharity) => {
    if ((values.bsb || values.accountNumber) && !values.stripeBankAccountToken) {
      AlertError('Please click the button to verify the bank account details')
      return
    }

    dispatch({ type: Types.Loading, payload: { loading: true } })
    CharityApi.update(formatData(values))
      .then((result) => {
        if (result.success) {
          AlertSuccess('Beneficiary saved successfully!')
          navigate('/app/beneficiaries')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleChangeStatus = (values: ICharity) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    CharityApi.update({ isActive: !values?.isActive, ...formatData(values) })
      .then(() => {
        AlertSuccess('Beneficiary deleted successfully!')
        navigate('/app/beneficiaries')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleApprove = (values: ICharity) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    CharityApi.update({ ...formatData(values), isPendingApproval: false, isActive: true })
      .then(() => {
        AlertSuccess('Beneficiary approved successfully!')
        navigate('/app/beneficiaries')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({
        type: Types.Loading, payload: { loading: false }
      }))
  }

  const formik = useFormik({
    initialValues: charityInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues, handleSubmit } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    CharityApi.getById(params.id as string)
      .then((res: ICharity) => {
        setValues({ ...res, locationDescription: res.address?.description })
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title="Edit Beneficiary">
      <form onSubmit={handleSubmit}>
        <Toolbar
          title="Edit Beneficiary"
          showBack
          buttons={
            <>
              <PrimaryButton variant="solid" type="submit">
                Save
              </PrimaryButton>
              {
                formik.values?.isPendingApproval ? (
                  <PrimaryButton variant="outline" onClick={() => handleApprove(formik.values)}>
                    Approve
                  </PrimaryButton>) : (
                  <PrimaryButton variant="outline" isDangerous={formik.values?.isActive} onClick={() => handleChangeStatus(formik.values)}>
                    {formik.values?.isActive ? 'Deactivate' : "Activate"}
                  </PrimaryButton>
                )
              }
            </>
          }
        />
        <CharityForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditCharity
