export const fileToBase64 = (file: File) => {
  return new Promise(resolve => {
    var reader = new FileReader();    // Read file content on file loaded event
    reader.onload = function (event: ProgressEvent<FileReader>) {
      if (event && event.target) {
        const result = event.target.result
        if (result) resolve(result.toString().split(',')[1])
      }
    };

    // Convert data to base64 
    reader.readAsDataURL(file);
  });
};