import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import UserApi from 'src/api/user'
import { Sidebar } from 'src/components/Disclosure/Sidebar'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import IUser from 'src/types/User'
import TopBar from './TopBar'

const DashboardLayout = () => {
  const { state, dispatch } = React.useContext(AppContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!state.isAuthenticated) {
      if (!localStorage.getItem('fbaToken') || !localStorage.getItem('fbaUser')) {
        navigate(window.location.href.indexOf('dashboard') > -1 ? '/login' : `/login?url=${window.location.href}`)
      }

      dispatch({
        type: Types.RestoreLogin,
        payload: {},
      })
    }
  }, [state.isAuthenticated, navigate, dispatch])

  React.useEffect(() => {
    if (state.user && state.user.id) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      UserApi.getById(state.user.id)
        .then((data: IUser) => {
          if (!data) navigate('/login')
          dispatch({ type: Types.SetUser, payload: { user: data } })
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    }
  }, [state.user, dispatch, navigate])

  if (!state.user) return null

  return (
    <Box w="100%" h="100%" maxH="calc(100% - 4rem)">
      <TopBar />
      <Flex w="100%" h="100%">
        <Box
          p={[0, 0, 4]}
          minW="fit-content"
          w="3rem"
          maxWidth={[0, 0, '3rem']}
          borderRight="1px solid"
          borderColor="gray.200"
          bg="gray.100"
          overflowY="scroll"
        >
          <Sidebar />
        </Box>
        <Box w="100%" p="4" pb="0" overflowY="scroll">
          <Outlet />
        </Box>
      </Flex>
    </Box>
  )
}

export default DashboardLayout
