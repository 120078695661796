import { Box, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BusinessOwnerApi from 'src/api/businessOwner'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableState } from 'src/components/CustomDataTable'
import { VisibilityTableToolbar } from 'src/components/CustomDataTable/VisibilityTableToolbar'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { PaginationResponse } from 'src/types/Pagination'
import IUser, { IBusinessOwnerFilter } from 'src/types/User'
import BusinessOwnerFilter from './BusinessOwnerFilter'
import tableColumns from './tableColumns'

const BusinessOwner = () => {
  const pageName = 'Business Owners'
  const navigate = useNavigate()
  const [businessOwnerList, setBusinessOwnerList] = useState<PaginationResponse<IUser>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const filterDisclosure = useDisclosure()
  const [hasFilterSet, setHasFilterSet] = useState<boolean>(false)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  const setFilter = (filter: IBusinessOwnerFilter) => {
    setTableState((prev) => ({ ...prev, filter, skip: 0 }))
    setHasFilterSet(!!(filter.state))
  }

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const getData = React.useCallback(() => {
    BusinessOwnerApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IUser>) => {
        setBusinessOwnerList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, tableState])

  React.useEffect(() => {
    getData()
  }, [getData])

  const handleChangeStatus = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessOwnerApi.changeStatus(idList)
      .then(() => {
        setBusinessOwnerList((prev: PaginationResponse<IUser>) =>
        ({
          ...prev, data: prev.data.map((businessOwner: IUser) => {
            if (idList.indexOf(businessOwner._id) > -1) {
              businessOwner.active = activate
            }
            return businessOwner
          })
        })
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleClick = (rowIndex: number) => navigate(businessOwnerList.data[rowIndex].id || businessOwnerList.data[rowIndex]._id)

  const customToolbar = (selectedRows: number[]) => {
    return <VisibilityTableToolbar data={businessOwnerList.data} handleChangeStatus={handleChangeStatus} selectedRows={selectedRows} />
  }

  return (
    <Page title={pageName}>
      <Toolbar title={pageName} onFilterOpen={filterDisclosure.onOpen} hasFilterSet={hasFilterSet} />
      <Box mt={3}>
        <CustomDataTable
          showCheckbox
          singleSelection
          serverSidePagination
          customToolbar={customToolbar}
          onViewClick={handleClick}
          data={businessOwnerList}
          tableColumns={tableColumns}
          onTableChange={onTableChange}
          tableState={tableState}
        />
      </Box>
      <BusinessOwnerFilter isOpen={filterDisclosure.isOpen} onClose={filterDisclosure.onClose} onSubmit={setFilter} />
    </Page>
  )
}

export default BusinessOwner
