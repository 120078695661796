import React from 'react'
import { useNavigate } from 'react-router-dom'
import VoucherAmountSuggestionApi from 'src/api/voucherAmountSuggestion'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import { VisibilityTableToolbar } from 'src/components/CustomDataTable/VisibilityTableToolbar'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { IVoucherAmountSuggestion } from 'src/types/VoucherAmountSuggestion'
import tableColumns from './tableColumns'

const VoucherAmountSuggestion = () => {
  const pageName = 'Voucher Amount Suggestions (Reply Review)'
  const navigate = useNavigate()
  const [voucherAmountSuggestionList, setVoucherAmountSuggestionList] = React.useState<IVoucherAmountSuggestion[]>([])
  const { dispatch } = React.useContext(AppContext)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    VoucherAmountSuggestionApi.get()
      .then((data: IVoucherAmountSuggestion[]) => {
        setVoucherAmountSuggestionList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setVoucherAmountSuggestionList])

  const handleChangeStatus = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    VoucherAmountSuggestionApi.changeStatus({ idList }, activate)
      .then(() => {
        setVoucherAmountSuggestionList((prev: IVoucherAmountSuggestion[]) =>
          prev.map((voucherAmountSuggestion: IVoucherAmountSuggestion) => {
            if (idList.indexOf(voucherAmountSuggestion.id) > -1) voucherAmountSuggestion.isActive = activate
            return voucherAmountSuggestion
          })
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleClick = (rowIndex: number) => navigate(voucherAmountSuggestionList[rowIndex].id || voucherAmountSuggestionList[rowIndex]._id)
  const handleAdd = () => navigate('new')

  const customToolbar = (selectedRows: number[]) => {
    return <VisibilityTableToolbar data={voucherAmountSuggestionList} handleChangeStatus={handleChangeStatus} selectedRows={selectedRows} />
  }

  return (
    <Page title={pageName}>
      <Toolbar title={pageName} buttons={<PrimaryButton onClick={handleAdd}>Add Voucher Suggestion</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable
          showCheckbox
          onEditClick={handleClick}
          data={voucherAmountSuggestionList || []}
          tableColumns={tableColumns}
          customToolbar={customToolbar}
        />
      </Box>
    </Page>
  )
}

export default VoucherAmountSuggestion
