import { fileSchema } from 'src/helpers/schemaHelper'
import * as Yup from 'yup'

export default Yup.object().shape({
  slug: Yup.string().required('Slug is required'),
  iconFile: fileSchema.optional(),
  title: Yup.string().required(),
  subtitle: Yup.string().optional(),
  summary: Yup.string().required(),
  detailsTitle: Yup.string().optional(),
  details: Yup.array(Yup.string().required()).required(),
  colors: Yup.array(Yup.string()).min(2).max(2),
  action: Yup.string(),
  placeId: Yup.string().optional().nullable(),
  isForEmail: Yup.boolean().required(),
})
