import { Box } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PromotionApi from 'src/api/promotion'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableState } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { PaginationResponse } from 'src/types/Pagination'
import { IPromotion } from '../../types/Promotion'
import tableColumns from './tableColumns'

const Promotion = () => {
  const navigate = useNavigate()
  const [promotionList, setPromotionList] = React.useState<PaginationResponse<IPromotion>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IPromotion>) => {
        setPromotionList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setPromotionList, tableState])

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const handleClick = (rowIndex: number) => navigate(promotionList.data[rowIndex].id || promotionList.data[rowIndex]._id)
  const handleAdd = () => navigate('new')

  return (
    <Page title="Promotion">
      <Toolbar title="Promotion" buttons={<PrimaryButton onClick={handleAdd}>Add Promotion</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable
          serverSidePagination
          onEditClick={handleClick}
          data={promotionList || []}
          tableColumns={tableColumns}
          tableState={tableState}
          onTableChange={onTableChange}
        />
      </Box>
    </Page>
  )
}

export default Promotion
