import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReviewReplySuggestionApi from 'src/api/reviewReplySuggestion'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import { VisibilityTableToolbar } from 'src/components/CustomDataTable/VisibilityTableToolbar'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { IReviewReplySuggestion } from 'src/types/ReviewReplySuggestion'
import tableColumns from './tableColumns'

const ReviewReplySuggestion = () => {
  const navigate = useNavigate()
  const [reviewReplySuggestionList, setReviewReplySuggestionList] = React.useState<IReviewReplySuggestion[]>([])
  const { dispatch } = React.useContext(AppContext)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ReviewReplySuggestionApi.get()
      .then((data: IReviewReplySuggestion[]) => {
        setReviewReplySuggestionList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setReviewReplySuggestionList])

  const handleChangeStatus = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ReviewReplySuggestionApi.changeStatus({ idList }, activate)
      .then(() => {
        setReviewReplySuggestionList((prev: IReviewReplySuggestion[]) =>
          prev.map((reviewReplySuggestion: IReviewReplySuggestion) => {
            if (idList.indexOf(reviewReplySuggestion.id) > -1) reviewReplySuggestion.isActive = activate
            return reviewReplySuggestion
          })
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleClick = (rowIndex: number) => navigate(reviewReplySuggestionList[rowIndex].id || reviewReplySuggestionList[rowIndex]._id)
  const handleAdd = () => navigate('new')

  const customToolbar = (selectedRows: number[]) => {
    return <VisibilityTableToolbar data={reviewReplySuggestionList} handleChangeStatus={handleChangeStatus} selectedRows={selectedRows} />
  }

  return (
    <Page title="Review Reply Suggestions">
      <Toolbar title="Review Reply Suggestions" buttons={<PrimaryButton onClick={handleAdd}>Add Suggestion</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable
          showCheckbox
          customToolbar={customToolbar}
          onEditClick={handleClick}
          data={reviewReplySuggestionList || []}
          tableColumns={tableColumns}
        />
      </Box>
    </Page>
  )
}

export default ReviewReplySuggestion
