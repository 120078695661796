import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Input } from 'src/components/Form/Input'
import { FormCard } from 'src/components/Layout/FormCard'
import { Formik } from 'src/types/Formik'
import { ISuggestionReason } from 'src/types/SuggestionReason'

const SuggestionReasonForm: React.FC<{ formik: Formik<ISuggestionReason> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="Reason Details">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <Input
            error={errors.description}
            label="Description"
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
          />
        </GridItem>
        <GridItem>
          <Input
            error={errors.emailDescription}
            label="Email Description"
            name="emailDescription"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.emailDescription}
          />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default SuggestionReasonForm
