import { TableColumn } from 'src/components/CustomDataTable'
import { snakeToCamel } from 'src/helpers/stringHelper'
import { IAnalyticsQuickTip } from 'src/types/AnalyticsQuickTip'

const tableColumns: TableColumn<IAnalyticsQuickTip>[] = [
  {
    label: 'Message',
    name: 'message',
  },
  {
    label: 'Trigger',
    name: 'trigger',
    options: {
      customRender: (value) => snakeToCamel(value?.trigger)
    }
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true
  }
]

export default tableColumns
