import { Flex } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import StoreApi from 'src/api/store'
import VoucherApi from 'src/api/voucher'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { PaginationResponse } from 'src/types/Pagination'
import { IStore } from 'src/types/User'
import { IVoucher, voucherInitialValue } from 'src/types/Voucher'
import validationSchema from 'src/views/Voucher/validationSchema'
import VoucherForm from './VoucherForm'

const EditVoucher = () => {
  const pageName = 'Edit Voucher'
  const { dispatch } = React.useContext(AppContext)
  const [grocers, setGrocers] = React.useState<IStore[]>([])
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = (values: IVoucher) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    VoucherApi.update({ ...values, _id: values.id, id: undefined })
      .then(() => {
        AlertSuccess('Voucher saved successfully!')
        navigate('/app/voucher')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: voucherInitialValue,
    onSubmit,
    validationSchema,
  })

  const { values, setValues } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.get({ limit: 1000, skip: 0 })
      .then((res: PaginationResponse<IStore>) => {
        setGrocers(res.data)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  const changeStatus = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    VoucherApi.changeStatus(params.id as string)
      .then((res: { success: boolean }) => {
        if (res) setValues((prev) => ({ ...prev, isActive: !prev.isActive }))
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    VoucherApi.getById(params.id as string)
      .then((res: IVoucher) => {
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar
          title={pageName}
          showBack={true}
          buttons={
            <Flex gap={2}>
              <PrimaryButton type="submit">Save</PrimaryButton>
              <PrimaryButton variant="outline" onClick={changeStatus}>
                {values.isActive ? 'Deactivate' : 'Activate'}
              </PrimaryButton>
            </Flex>
          }
        />
        <VoucherForm formik={formik} grocers={grocers} />
      </form>
    </Page>
  )
}

export default EditVoucher
