import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React from 'react'
import { PrimaryButton } from '../Button/PrimaryButton'
import { ChakraSpan } from '../Form/ChakraSpan'

interface ConfirmationModalProps {
  open: boolean
  handleClose(): void
  handleConfirm(): void
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, handleClose, handleConfirm }) => {
  const onSubmit = () => {
    handleConfirm()
    handleClose()
  }

  return (
    <Modal isOpen={open} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="3">
            Are you sure you want to continue? <br />
            <ChakraSpan fontWeight="bold" color="red.500">
              This process cannot be undone!
            </ChakraSpan>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <PrimaryButton onClick={handleClose} variant="outline">
              Cancel
            </PrimaryButton>
            <PrimaryButton onClick={onSubmit} autoFocus>
              Confirm
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmationModal
