import { toast } from 'react-toastify'

const spanStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const toastOptions = {
  position: 'top-center',
}

const warning = {
  color: '#2b2b2b',
}

export const AlertSuccess = (message: string, options?: any) => {
  toast.success(<span style={spanStyle}> {message} </span>, { ...toastOptions, ...(options ?? {}) })
}

export const AlertWarning = (message: string, options?: any) => {
  toast.warning(<span style={{ ...spanStyle, ...warning }}> {message} </span>, { ...toastOptions, ...(options ?? {}) })
}

export const AlertError = (message: string, options?: any) => {
  toast.error(<span style={spanStyle}> {message} </span>, options ? { ...options, autoClose: false, ...toastOptions } : { autoClose: false, ...toastOptions })
}

export const dismissAllAlerts = () => {
  toast.dismiss()
}
