import { icon } from 'leaflet'
import React from 'react'
import { LayerGroup, LayersControl, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import StoreApi from 'src/api/store'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IAddress } from 'src/types/Address'
import { IStore } from 'src/types/User'

const BusinessesLocation: React.FC = () => {
  const { dispatch } = React.useContext(AppContext)
  const [businesses, setBusinesses] = React.useState<IStore[]>([])

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.list()
      .then((result: IStore[]) => {
        if (result) setBusinesses(result)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch])

  const businessIcon = icon({
    iconUrl: '/static/location.png',
    iconSize: [45, 45],
    iconAnchor: [22.5, 45],
    popupAnchor: [0, -45],
  })

  return (
    <MapContainer center={[-26.849104, 133.803599]} zoom={4} scrollWheelZoom={false} style={{ height: '400px', borderRadius: '10px' }}>
      <div>
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Humanitarian Style">
            <TileLayer url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Standard">
            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Watercolor">
            <TileLayer url="http://{s}.tile.stamen.com/watercolor/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay checked name="Businesses Location">
            <LayerGroup>
              {businesses
                ? businesses.map((item) =>
                    item.address ? (
                      <Marker
                        key={item._id}
                        position={[(item.address as IAddress).location.coordinates[1], (item.address as IAddress).location.coordinates[0]]}
                        icon={businessIcon}
                      >
                        <Popup>{item.businessName}</Popup>
                      </Marker>
                    ) : null
                  )
                : null}
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </div>
    </MapContainer>
  )
}

export default BusinessesLocation
