import { Badge } from '@chakra-ui/react'

interface ProductDataBadgeProps {
  data: string
}

export function ProductDataBadge({ data }: ProductDataBadgeProps) {
  return (
    <Badge variant="outline" colorScheme="gray">
      {data}
    </Badge>
  )
}
