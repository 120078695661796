import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PromotionalBannerApi from 'src/api/promotionalBanner'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IPromotionalBanner, promotionalBannerInitialValue } from 'src/types/PromotionalBanner'
import validationSchema from 'src/views/PromotionalBanner/validationSchema'
import PromotionalBannerForm from './PromotionalBannerForm'

const EditPromotionalBanner = () => {
  const pageName = 'Edit Banner'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = (values: IPromotionalBanner) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionalBannerApi.update({
      title: values.title,
      subtitle: values.subtitle,
      description: values.description,
      pictureFile: values.pictureFile,
      placeId: values.placeId,
      id: undefined,
      _id: values.id,
    })
      .then((response) => {
        if (response.success) {
          AlertSuccess('Banner saved successfully!')
          navigate('/app/promotional-banner')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: promotionalBannerInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionalBannerApi.getById(params.id as string)
      .then((res: IPromotionalBanner) => {
        setValues({ ...res, placeId: res.location?.place_id, locationDescription: res.location?.description ?? '' })
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <PromotionalBannerForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditPromotionalBanner
