import { generateMultipartBodyForm } from 'src/helpers/ApiHelper'
import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { IMerchandise } from 'src/types/Merchandise'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { api } from '.'

interface IUpdateStockQuantityProps {
  merchandiseId: string
  grocerId: string
  quantityAvailable: number
}

type IMerchandiseApi = {
  get(params: PaginationData<IMerchandise>): Promise<PaginationResponse<IMerchandise>>
  getById(id: string): Promise<IMerchandise>
  listByStore(storeId: string): Promise<IMerchandise[]>
  listActive(): Promise<IMerchandise[]>
  assignToStore(storeId: string, merchandiseId: string): Promise<{ success: boolean }>
  unassignToStore(storeId: string, merchandiseId: string): Promise<{ success: boolean }>
  create(merchandise: IMerchandise): Promise<{ success: boolean }>
  update(merchandise: IMerchandise): Promise<{ success: boolean }>
  updateStockQuantity(payload: IUpdateStockQuantityProps): Promise<{ success: boolean }>
}

const MerchandiseApi = (): IMerchandiseApi => {
  const merchandiseApi = '/admin/merchandise'

  const get = (params: PaginationData<IMerchandise>): Promise<PaginationResponse<IMerchandise>> => api.get(`${merchandiseApi}${generateParamsFromPagination(params)}`)
  const listByStore = (storeId: string): Promise<IMerchandise[]> => api.get(`${merchandiseApi}/store/${storeId}`)
  const listActive = (): Promise<IMerchandise[]> => api.get(`${merchandiseApi}/active`)
  const assignToStore = (storeId: string, merchandiseId: string): Promise<{ success: boolean }> => api.post(`${merchandiseApi}/store/${storeId}/assign`, { merchandiseId })
  const unassignToStore = (storeId: string, merchandiseId: string): Promise<{ success: boolean }> => api.post(`${merchandiseApi}/store/${storeId}/unassign`, { merchandiseId })
  const create = (merchandise: IMerchandise): Promise<{ success: boolean }> => api.post(merchandiseApi, generateMultipartBodyForm(merchandise), { headers: { 'Content-Type': 'multipart/form-data' } })
  const update = (merchandise: IMerchandise): Promise<{ success: boolean }> => {
    const { id, ...rest } = merchandise
    return api.patch(`${merchandiseApi}/${id}`, generateMultipartBodyForm(rest), { headers: { 'Content-Type': 'multipart/form-data' } })
  }
  const getById = (id: string): Promise<IMerchandise> => api.get(`${merchandiseApi}/${id}`)
  const updateStockQuantity = (payload: IUpdateStockQuantityProps): Promise<{ success: boolean }> => api.post(`${merchandiseApi}/store/${payload.grocerId}/set-quantity`, { merchandiseId: payload.merchandiseId, quantityAvailable: payload.quantityAvailable })

  return {
    get,
    getById,
    listByStore,
    listActive,
    assignToStore,
    unassignToStore,
    create,
    update,
    updateStockQuantity
  }
}

export default MerchandiseApi()
