import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BusinessOwnerApi from 'src/api/businessOwner'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import IUser, { userInitialValue } from 'src/types/User'
import BusinessOwnerForm from './BusinessOwnerForm'

const ViewBusinessOwner = () => {
  const pageName = 'View Business Owner'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()
  const [businessOwner, setBusinessOwner] = React.useState<IUser>(userInitialValue)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessOwnerApi.getById(params.id as string)
      .then((res) => setBusinessOwner(res))
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id])

  const handleChangeStatus = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessOwnerApi.changeStatus([params.id as string])
      .then(() => {
        AlertSuccess('Status changed successfully')
        navigate('/app/business-owner')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, navigate])

  return (
    <Page title={pageName}>
      <Toolbar
        title={pageName}
        showBack={true}
        buttons={
          <PrimaryButton variant="outline" onClick={handleChangeStatus}>
            {businessOwner.active ? 'Deactivate' : 'Activate'}
          </PrimaryButton>
        }
      />
      <BusinessOwnerForm businessOwner={businessOwner} />
    </Page>
  )
}

export default ViewBusinessOwner
