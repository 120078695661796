import { TableColumn } from 'src/components/CustomDataTable'
import { IFaq } from 'src/types/Faq'

const tableColumns: TableColumn<IFaq>[] = [
  {
    label: 'Title',
    name: 'title',
  },
  {
    label: 'Order',
    name: 'order',
  },
]

export default tableColumns
