export interface IReviewReplySuggestion {
  _id?: string
  text: string
  isActive: boolean

  [key: string]: any
}

export const reviewReplySuggestionInitialValue: IReviewReplySuggestion = {
  text: '',
  isActive: false,
}
