enum AnalyticsQuickTipTriggerEnum {
  REVENUE_TREND_DECREASING = 'REVENUE_TREND_DECREASING',
  REVENUE_TREND_INCREASING = 'REVENUE_TREND_INCREASING',
  REVENUE_TREND_STABLE = 'REVENUE_TREND_STABLE',
  PRODUCT_TREND_DECREASING = 'PRODUCT_TREND_DECREASING',
  PRODUCT_TREND_INCREASING = 'PRODUCT_TREND_INCREASING',
  PRODUCT_TREND_STABLE = 'PRODUCT_TREND_STABLE',
  PRODUCT_MOST_POPULAR = 'PRODUCT_MOST_POPULAR',
  PRODUCT_LESS_POPULAR = 'PRODUCT_LESS_POPULAR',
  CUSTOMER_TREND_DECREASING = 'CUSTOMER_TREND_DECREASING',
  CUSTOMER_TREND_INCREASING = 'CUSTOMER_TREND_INCREASING',
  CUSTOMER_TREND_STABLE = 'CUSTOMER_TREND_STABLE',
}

export default AnalyticsQuickTipTriggerEnum
