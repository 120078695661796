import { HelmetProvider } from 'react-helmet-async'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useRoutes } from 'react-router-dom'
import routes from 'src/routes'
import { AppProvider } from './contexts/AppContext'

function App() {
  const routing = useRoutes(routes)

  return (
    <AppProvider>
      <HelmetProvider>{routing}</HelmetProvider>
    </AppProvider>
  )
}

export default App
