import { Avatar, Flex, GridItem, Link, SimpleGrid } from '@chakra-ui/react'
import UserApi from 'src/api/user'
import { useAppContext } from 'src/contexts/AppContext'
import { formatDateToString } from 'src/helpers/stringHelper'
import { Types } from 'src/reducers/AppReducer'
import IUser from 'src/types/User'
import { AlertSuccess } from '../Alert'
import { Checkbox } from '../Form/Checkbox'
import { DataTextField } from '../Form/DataTextField'
import { MultiSelect, MultiSelectOptionProps } from '../Form/MultiSelect'
import { FormCard } from './FormCard'

interface UserDetailsCardProps {
  user: IUser
  charities: MultiSelectOptionProps[]
}

export function UserDetailsCard({ user, charities }: UserDetailsCardProps) {
  const { dispatch } = useAppContext()

  const handleToggleIsCharity = (_event: any, charityId?: string) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    UserApi.updateCustomer(user.id ?? user._id, (charityId ? { charityId: charityId } : { isCharity: !user.isCharity, charityId: null }) as IUser)
      .then((result) => {
        if (result?.success) AlertSuccess('User updated successfully')
        if (!charityId) {
          user.isCharity = !user.isCharity
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleChangeCharity = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    handleToggleIsCharity(e, value)
  }

  return (
    <FormCard title="User Details">
      <SimpleGrid columns={[1, 1, 3]} spacing={4}>
        <GridItem>
          <Flex gap={2} align="center">
            <Avatar src={user.avatar} />
            <DataTextField title="Name">
              {user.firstName} {user.lastName}
            </DataTextField>
          </Flex>
        </GridItem>
        <GridItem>
          <DataTextField title="Email">{user.email ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Phone Number">{user.loginPhoneNumber ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Referral Code">{user.referralCode ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          {
            user.referrer ? (
              <DataTextField title="Referred by">
                <Link href={`/app/customers/${user.referrer._id}`} textDecoration="underline">{user.referrer?.firstName} {user.referrer?.lastName}</Link>
              </DataTextField>
            ) : (
              <DataTextField title="Referred by">-</DataTextField>
            )
          }
        </GridItem>
        <GridItem>
          <DataTextField title="Credits">{user.creditBalance ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Customer Since">{formatDateToString(user.createdAt) ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Stripe Id">{user.stripeCustomerId ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <Checkbox name="isCharity" label="Is Charity" isChecked={user.isCharity} onChange={(e) => handleToggleIsCharity(e)} />
        </GridItem>
        {user.isCharity && (
          <GridItem colSpan={[1, 1, 3]}>
            <MultiSelect
              isMulti={false}
              label="What charity is this user associated with?"
              name="charity"
              onChange={handleChangeCharity}
              value={user.charityId}
              options={charities}
            />
          </GridItem>
        )}
      </SimpleGrid>
    </FormCard>
  )
}
