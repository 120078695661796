import { Flex, GridItem, SimpleGrid } from '@chakra-ui/react'
import { DataTextField } from 'src/components/Form/DataTextField'
import { FormCard } from 'src/components/Layout/FormCard'
import { StoreCard } from 'src/components/Layout/StoreCard'
import IUser, { IStore } from 'src/types/User'

interface BusinessOwnerFormProps {
  businessOwner: IUser
}

const BusinessOwnerForm = ({ businessOwner }: BusinessOwnerFormProps) => {
  return (
    <SimpleGrid columns={1} gap={4}>
      <GridItem>
        <FormCard title="Business Owner Details">
          <SimpleGrid columns={[1, 1, 3]} spacing={4}>
            <GridItem>
              <DataTextField title="Name">
                {businessOwner.firstName} {businessOwner.lastName}
              </DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Phone Number">{businessOwner.phoneNumber}</DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Email">{businessOwner.email}</DataTextField>
            </GridItem>
          </SimpleGrid>
        </FormCard>
      </GridItem>
      <GridItem>
        <FormCard title="Stores Owned">
          <Flex wrap="wrap" gap="4">
            {businessOwner.stores && businessOwner.stores.map((store: IStore) => <StoreCard key={store._id} store={store} />)}
          </Flex>
        </FormCard>
      </GridItem>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Card>
            <CardContent>
              <Typography variant="h4">Stores Owned</Typography>
              <br />
              <Divider />
              <br />
              <Grid container spacing={2}>
                {businessOwner.stores &&
                  businessOwner.stores.map((store: IStore) => (
                    <Grid item xs={12} className={classes.line}>
                      <Grid container spacing={2}>
                        <div className={classes.productPictureContainer}>
                          <img src={store.businessLogo} alt={store.name} className={classes.productPicture} />
                        </div>
                        <Grid item xs={12} lg={10}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3} lg={2}>
                              <Typography variant="h5">Business Name</Typography>
                              <Typography variant="body1">{store.businessName}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3} lg={2}>
                              <Typography variant="h5">Code</Typography>
                              <Typography variant="body1">{store.code}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}
    </SimpleGrid>
  )
}

export default BusinessOwnerForm
