import { FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, VStack } from '@chakra-ui/react'
import { FormikErrors } from 'formik'
import React from 'react'

interface Props {
  options: { label: string; value: string }[]
  name: string
  label: string
  touched: any
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  value: any
  onBlur(e: React.FocusEvent<any>): any
  onChange(e: any): any
}

const CustomRadioButton: React.FC<Props> = ({ options, name, label = '', error, value, onBlur, onChange }) => {
  const handleChange = (nextValue: string) => {
    onChange({ target: { name, value: nextValue } })
  }

  return (
    <FormControl variant="outlined" margin="normal" w="100%">
      <FormLabel>{label}</FormLabel>
      <RadioGroup aria-label={label} color="primary" name={name} value={value} onChange={handleChange} onBlur={onBlur}>
        <VStack align="flex-start">
          {options.map((option) => (
            <Radio value={option.value}>{option.label}</Radio>
          ))}
        </VStack>
      </RadioGroup>
      {!!error && <FormErrorMessage>{typeof error === 'string' ? error : ''}</FormErrorMessage>}
    </FormControl>
  )
}

export default CustomRadioButton
