import OrderStatusEnum from 'src/enum/OrderStatusEnum'
import { getKeyEnum } from 'src/helpers/enumHelper'
import { ChakraSpan } from '../Form/ChakraSpan'

interface OrderStatusColumnProps {
  status: OrderStatusEnum
}

export function OrderStatusColumn({ status }: OrderStatusColumnProps) {
  return (
    <ChakraSpan
      fontWeight="semibold"
      color={
        status === getKeyEnum(OrderStatusEnum.CANCELLED, OrderStatusEnum)
          ? 'red'
          : status === getKeyEnum(OrderStatusEnum.COLLECTED, OrderStatusEnum)
            ? 'green'
            : status === getKeyEnum(OrderStatusEnum.PENDING_REFUND, OrderStatusEnum)
              ? 'orange'
              : 'blue'
      }
    >
      {OrderStatusEnum[status]}
    </ChakraSpan>
  )
}
