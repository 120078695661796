enum ScreenEnum {
  BROWSE = 'BROWSE',
  ORDERS = 'ORDERS',
  FAVOURITES = 'FAVOURITES',
  STORE = 'grocerDetailsRoute',
  CONTACT_US = 'contactUsRoute',
  INVITE_FRIENDS = 'inviteFriendsRoute',
  SUGGEST_BUSINESS = 'suggestBusinessRoute',
}

export default ScreenEnum
