import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { MultiSelectOptionProps } from 'src/components/Form/MultiSelect'
import { UserDetailsCard } from 'src/components/Layout/UserDetailsCard'
import { UserLocationCard } from 'src/components/Layout/UserLocationCard'
import IUser from 'src/types/User'

interface UserFormProps {
  user: IUser
  charities: MultiSelectOptionProps[]
}

export function UserForm({ user, charities }: UserFormProps) {
  return (
    <SimpleGrid columns={[1, 1, 3]} gap="3">
      <GridItem colSpan={2}>
        <SimpleGrid columns={1}>
          <GridItem>
            <UserDetailsCard user={user} charities={charities} />
          </GridItem>
        </SimpleGrid>
      </GridItem>
      <GridItem>
        <SimpleGrid columns={1}>
          <GridItem>
            <UserLocationCard location={user.address} />
          </GridItem>
        </SimpleGrid>
      </GridItem>
    </SimpleGrid>
  )
}
