import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { IPromotion } from 'src/types/Promotion'
import { api } from '.'

type IPromotionApi = {
  get(params: PaginationData<IPromotion>): Promise<PaginationResponse<IPromotion>>
  listActivePromotions(): Promise<{ promotions: IPromotion[] }>
  getCurrentPromotion(): Promise<{ promotion: IPromotion }>
  getById(id: string): Promise<{ promotion: IPromotion }>
  create(pb: IPromotion): Promise<IPromotion>
  update(pb: IPromotion): Promise<IPromotion>
  duplicatePromotionToNew(promotionId: string): Promise<IPromotion>
}

const PromotionApi = (): IPromotionApi => {
  const promotionApi = '/admin/promotion'

  const getCurrentPromotion = (): Promise<{ promotion: IPromotion }> => api.get('/promotion/current')
  const listActivePromotions = (): Promise<{ promotions: IPromotion[] }> => api.get(`${promotionApi}/active`)
  const get = (params: PaginationData<IPromotion>): Promise<PaginationResponse<IPromotion>> =>
    api.get(`${promotionApi}${generateParamsFromPagination(params)}`)
  const create = (pb: IPromotion): Promise<IPromotion> => api.post(promotionApi, pb)
  const update = (pb: IPromotion): Promise<IPromotion> => api.patch(`${promotionApi}/${pb._id}`, { ...pb, _id: undefined })
  const getById = (id: string): Promise<{ promotion: IPromotion }> => api.get(`${promotionApi}/${id}`)
  const duplicatePromotionToNew = (promotionId: string): Promise<IPromotion> => api.post(`${promotionApi}/duplicate/${promotionId}`)

  return {
    getCurrentPromotion,
    get,
    listActivePromotions,
    getById,
    create,
    update,
    duplicatePromotionToNew,
  }
}

export default PromotionApi()
