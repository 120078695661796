import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, GridItem, SimpleGrid } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { Input } from 'src/components/Form/Input'
import { ICustomerFilter, userFilterInitialValue } from 'src/types/User'

interface CustomerFilterProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (filter: ICustomerFilter) => void
}

const CustomerFilter = ({ isOpen, onClose, onSubmit }: CustomerFilterProps) => {
  const { errors, values, handleBlur, handleChange, resetForm, handleSubmit } = useFormik({
    initialValues: userFilterInitialValue,
    onSubmit,
  })

  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">Filter</DrawerHeader>
        <DrawerBody pt="8">
          <form onSubmit={handleSubmit}>
            <SimpleGrid spacing={['6', '8']} w="100%">
              <GridItem>
                <Input name="name" label="Name" error={errors.name} onBlur={handleBlur} onChange={handleChange} value={values.name} />
              </GridItem>
              <GridItem>
                <Input
                  name="familyName"
                  label="Family name"
                  error={errors.familyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.familyName}
                />
              </GridItem>
              <GridItem>
                <Input
                  name="phoneNumber"
                  label="Phone number"
                  error={errors.phoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phoneNumber}
                />
              </GridItem>
              <GridItem>
                <Input name="email" label="Email" error={errors.email} onBlur={handleBlur} onChange={handleChange} value={values.email} />
              </GridItem>
              <GridItem>
                <Flex gap="4" align="flex-end" w="100%">
                  <PrimaryButton variant="outline" onClick={() => resetForm()}>
                    Reset
                  </PrimaryButton>
                  <PrimaryButton type="submit">Apply</PrimaryButton>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default CustomerFilter
