import { TableColumn } from 'src/components/CustomDataTable'
import { IVoucherAmountSuggestion } from 'src/types/VoucherAmountSuggestion'

const tableColumns: TableColumn<IVoucherAmountSuggestion>[] = [
  {
    label: 'Amount',
    name: 'amount',
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true,
  },
]

export default tableColumns
