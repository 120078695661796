import React from 'react'
import { useNavigate } from 'react-router-dom'
import BusinessSuggestionApi from 'src/api/businessSuggestion'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { IBusinessSuggestion } from 'src/types/BusinessSuggestion'
import tableColumns from './tableColumns'

const BusinessSuggestion = () => {
  const navigate = useNavigate()
  const [businessSuggestionList, setBusinessSuggestionList] = React.useState<IBusinessSuggestion[]>([])
  const { dispatch } = React.useContext(AppContext)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessSuggestionApi.get()
      .then((data: IBusinessSuggestion[]) => {
        setBusinessSuggestionList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setBusinessSuggestionList])

  const handleClick = (rowIndex: number) => navigate(businessSuggestionList[rowIndex].id || businessSuggestionList[rowIndex]._id)

  return (
    <Page title="BusinessSuggestion">
      <Toolbar title="BusinessSuggestion" />
      <Box mt={3}>
        <CustomDataTable onEditClick={handleClick} data={businessSuggestionList || []} tableColumns={tableColumns} />
      </Box>
    </Page>
  )
}

export default BusinessSuggestion
