import { TableColumn } from 'src/components/CustomDataTable'
import { IReviewReplySuggestion } from 'src/types/ReviewReplySuggestion'

const tableColumns: TableColumn<IReviewReplySuggestion>[] = [
  {
    label: 'Text',
    name: 'text',
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true,
  },
]

export default tableColumns
