import { Box, Flex, Link, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { DropzoneState, FileWithPath, useDropzone } from 'react-dropzone'
import { getFileNameFromFileUrl } from 'src/helpers/stringHelper'
import { AlertError } from '../Alert'
import { Label } from './Label'

interface FileUploadProps {
  onUpload: (name: string, file: File) => void
  name: string
  defaultUrl?: string
  label?: string
}

const FileUpload: React.FC<FileUploadProps> = ({ onUpload, name, defaultUrl, label }) => {
  const fileDropzone = useDropzone({ accept: { 'application/pdf': [] }, multiple: false })
  const [previewUrl, setPreviewUrl] = React.useState<string>('')

  const compressAndSetPreview = React.useCallback(
    async (acceptedFiles: FileWithPath[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        setPreviewUrl(URL.createObjectURL(file))
        onUpload(name, file)
      }
    },
    [onUpload, name]
  )

  useEffect(() => {
    compressAndSetPreview(fileDropzone.acceptedFiles as FileWithPath[])
  }, [fileDropzone.acceptedFiles, compressAndSetPreview])

  useEffect(() => {
    if (defaultUrl) setPreviewUrl(defaultUrl)
  }, [defaultUrl])

  useEffect(() => {
    if (hasErrorMessage([fileDropzone])) {
      let message = 'Invalid File'
      if (fileDropzone.fileRejections.length > 0) message = getErrorMessage(fileDropzone)
      AlertError(message)
    }
  }, [fileDropzone])

  const getErrorMessage = (dropzoneFile: DropzoneState) => {
    return dropzoneFile.fileRejections[0].errors[0].message
  }

  const hasErrorMessage = (dropzoneFiles: DropzoneState[]) => {
    return dropzoneFiles.map((value) => value.fileRejections.length > 0).indexOf(true) > -1
  }

  return (
    <Flex align="center" direction="column">
      {label && <Label htmlFor={name} label={label} mb="0" />}
      <Box
        {...fileDropzone.getRootProps()}
        mt="4"
        p="1.25rem"
        height="6rem"
        width="12rem"
        borderWidth="2px"
        borderRadius="lg"
        borderColor="primary.500"
        borderStyle="dashed"
        backgroundColor="gray.200"
        textAlign="center"
      >
        <input {...fileDropzone.getInputProps()} />
        <Text variant="text-14-reg" color="primary.500">
          Drag 'n' drop a file here <br /> or click to select files
        </Text>
      </Box>

      {fileDropzone.fileRejections.length > 0 && (
        <Text variant="text-14-reg" color="red.500" mt="2">
          {getErrorMessage(fileDropzone)}
        </Text>
      )}

      {(fileDropzone.acceptedFiles.length > 0 || previewUrl) && (
        <Text variant="text-14-reg" mt="2">
          <b>File</b>:{' '}
          <Link href={previewUrl} target="_blank" color="primary.500">
            {fileDropzone.acceptedFiles[0]?.name ?? getFileNameFromFileUrl(previewUrl)}
          </Link>
        </Text>
      )}
    </Flex>
  )
}

export default FileUpload
