import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { IStore } from 'src/types/User'
import { DataTextField } from '../Form/DataTextField'
import { FormCard } from './FormCard'

interface StoreContactDetailsCardProps {
  store: IStore
}

export function StoreContactDetailsCard({ store }: StoreContactDetailsCardProps) {
  return (
    <FormCard title="Contact Details">
      <SimpleGrid columns={1} spacing={2}>
        <GridItem>
          <DataTextField title="Name">{store.contactName || '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Email">{store.contactEmail || '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Phone Number">{store.businessPhoneNumber}</DataTextField>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}
