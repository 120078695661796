import {
  Flex,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { PrimaryButton } from '../Button/PrimaryButton'
import { ChakraSpan } from '../Form/ChakraSpan'
import { Input } from '../Form/Input'

interface IConfirmationReason { reason: string }

interface ConfirmationWithReasonModalProps {
  title: string
  open: boolean
  handleClose(): void
  handleConfirm(reason: string): void
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().required('Reason is required'),
})


const ConfirmationWithReasonModal: React.FC<ConfirmationWithReasonModalProps> = ({ title, open, handleClose, handleConfirm }) => {
  const onSubmit = (values: IConfirmationReason) => {
    handleConfirm(values.reason)
    handleClose()
  }

  const { errors, values, handleBlur, handleChange, handleSubmit } = useFormik<IConfirmationReason>({
    initialValues: { reason: null },
    onSubmit,
    validationSchema,
  })

  return (
    <Modal isOpen={open} onClose={handleClose} size="xl">
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="3">
              Do you want to cancel this order? <br />
              <ChakraSpan fontWeight="bold" color="red.500">
                This process cannot be undone!
              </ChakraSpan>
            </Text>
            <SimpleGrid spacing={4}>
              <GridItem>
                <Input
                  error={errors.reason}
                  label="Reason"
                  name="reason"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reason}
                />
              </GridItem>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <PrimaryButton onClick={handleClose} variant="outline">
                Cancel
              </PrimaryButton>
              <PrimaryButton type="submit" autoFocus>
                Confirm
              </PrimaryButton>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default ConfirmationWithReasonModal
