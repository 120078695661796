import { Box, Center } from '@chakra-ui/react'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AppContext from 'src/contexts/AppContext'
import TopBar from './TopBar'

const MainLayout: React.FC<{}> = () => {
  const { state } = React.useContext(AppContext)
  const navigate = useNavigate()

  if (state.isAuthenticated) navigate('/app/dashboard', { replace: true })

  return (
    <Box minH="100vh">
      <TopBar />
      <Center minH="calc(100vh - 6.625rem)">
        <Center maxW="25rem" minH="100%" w="100%" p="4">
          <Outlet />
        </Center>
      </Center>
    </Box>
  )
}

export default MainLayout
