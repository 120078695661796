import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BroadcastingApi from 'src/api/broadcasting'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import { BroadcastDetailsModal } from 'src/components/Overlay/BroadcastDetailsModal'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IBroadcasting } from 'src/types/Broadcasting'
import tableColumns from './tableColumns'

const Broadcasting = () => {
  const navigate = useNavigate()
  const detailsDisclosure = useDisclosure()
  const [broadcastingList, setBroadcastingList] = React.useState<IBroadcasting[]>([])
  const [broadcastSelected, setBroadcastSelected] = React.useState<IBroadcasting | null>(null)
  const { dispatch } = React.useContext(AppContext)

  const getBroadcastingList = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BroadcastingApi.get()
      .then((data: IBroadcasting[]) => {
        setBroadcastingList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setBroadcastingList])

  React.useEffect(() => {
    getBroadcastingList()
  }, [getBroadcastingList])

  const handleAdd = () => navigate('new')

  const handleOnRowClick = (rowIndex: number) => {
    setBroadcastSelected(broadcastingList[rowIndex])
    detailsDisclosure.onOpen()
  }

  return (
    <Page title="Broadcast - Push Notifications">
      <Toolbar
        title="Broadcast - Push Notifications"
        buttons={
          <Flex gap={2}>
            <PrimaryButton onClick={handleAdd}>Broadcast Message</PrimaryButton>
          </Flex>
        }
      />
      <Box mt={3}>
        <CustomDataTable data={broadcastingList || []} tableColumns={tableColumns} onRowClick={handleOnRowClick} />
      </Box>
      <BroadcastDetailsModal isOpen={detailsDisclosure.isOpen} onClose={detailsDisclosure.onClose} broadcast={broadcastSelected} />
    </Page>
  )
}

export default Broadcasting
