import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import IUser from 'src/types/User'
import { api } from '.'

type IUserApi = {
  get(params: PaginationData<IUser>): Promise<PaginationResponse<IUser>>
  getById(id: string): Promise<IUser>
  getUserWithOrdersListForCSV(): Promise<Partial<IUser>[]>
  updateCustomer(id: string, user: IUser): Promise<{ success: boolean }>
}

const UserApi = (): IUserApi => {
  const userApi = '/admin/user'

  const get = (params: PaginationData<IUser>): Promise<PaginationResponse<IUser>> => api.get(`${userApi}${generateParamsFromPagination(params)}`)
  const getById = (id: string): Promise<IUser> => api.get(`${userApi}/${id}`)
  const getUserWithOrdersListForCSV = (): Promise<Partial<IUser>[]> => api.get(`${userApi}/orders/csv`)
  const updateCustomer = (id: string, user: IUser): Promise<{ success: boolean }> => api.patch(`${userApi}/${id}`, user)

  return {
    get,
    getById,
    getUserWithOrdersListForCSV,
    updateCustomer
  }
}

export default UserApi()
