import { Center, GridItem, SimpleGrid } from '@chakra-ui/react'
import imageCompression from 'browser-image-compression'
import React from 'react'
import CardGrocerBannerPreview from 'src/components/CardGrocerBannerPreview'
import CardGrocerLogoPreview from 'src/components/CardGrocerLogoPreview'
import CropImageDialog from 'src/components/CropImageDialog'
import ImageUpload from 'src/components/Form/ImageUpload'
import { FormCard } from 'src/components/Layout/FormCard'
import { IUploadFile } from 'src/types/UploadFile'
import { IStore } from 'src/types/User'

interface StoreImagesPreviewProps {
  store: IStore
  setStore: React.Dispatch<React.SetStateAction<IStore>>
}

const StoreImagesPreview = ({ store, setStore }: StoreImagesPreviewProps) => {
  const [fieldNameBeingUploaded, setFieldNameBeingUploaded] = React.useState<string>()
  const [imagePreview, setImagePreview] = React.useState<string>('')
  const [open, setOpen] = React.useState<boolean>(false)
  const [aspect, setAspect] = React.useState<number>(1)

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [])

  const onUpload = React.useCallback((name: string, _file: IUploadFile, preview: string) => {
    setAspect(name === 'businessCover' ? 448 / 200 : 1)
    setFieldNameBeingUploaded(name)
    setImagePreview(preview)
    setOpen(true)
  }, [])

  const onCropComplete = async ({ url, file }: { url: string; file: File }) => {
    const options = {
      maxSizeMB: 100,
      useWebWorker: true,
    }
    const fileCompressed = await imageCompression(file, options)
    if (fieldNameBeingUploaded) {
      setStore((prev) => ({
        ...prev,
        [fieldNameBeingUploaded]: url,
        [`${fieldNameBeingUploaded}File`]: fileCompressed,
      }))
    }
  }

  return (
    <SimpleGrid columns={[1, 1, 2]} spacing={4}>
      <GridItem>
        <FormCard title="List Screen">
          <SimpleGrid columns={1} spacing={4}>
            <GridItem>
              <Center flexDirection="column">
                <CardGrocerLogoPreview store={store} />
                <ImageUpload onUpload={onUpload} name="businessLogo" showPreview={false} />
              </Center>
            </GridItem>
          </SimpleGrid>
        </FormCard>
      </GridItem>
      <GridItem>
        <FormCard title="Store Detail Screen">
          <SimpleGrid columns={1} spacing={4}>
            <GridItem>
              <Center flexDirection="column">
                <CardGrocerBannerPreview store={store} />
                <ImageUpload onUpload={onUpload} name="businessCover" showPreview={false} />
              </Center>
            </GridItem>
          </SimpleGrid>
        </FormCard>
      </GridItem>
      <CropImageDialog image={imagePreview} open={open} handleClose={handleClose} aspect={aspect} onComplete={onCropComplete} />
    </SimpleGrid>
  )
}

export default StoreImagesPreview
