import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AccountManagerApi from 'src/api/accountManager'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IAccountManager, accountManagerInitialValue } from 'src/types/AccountManager'
import validationSchema from 'src/views/AccountManager/validationSchema'
import AccountManagerForm from './AccountManagerForm'

const CreateAccountManager: React.FC = () => {
  const pageName: string = 'Create Partner'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IAccountManager) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AccountManagerApi.create({
      code: values.code,
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      email: values.email,
      phoneNumber: String(values.phoneNumber),
      trialDurationInDays: values.trialDurationInDays,
      pictureFile: values.pictureFile,
      termsAndConditionsFile: values.termsAndConditionsFile,
    } as IAccountManager)
      .then((result) => {
        if (result?.success) {
          AlertSuccess('Partner saved successfully!')
          navigate('/app/partners')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: accountManagerInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <AccountManagerForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreateAccountManager
