import { Flex } from '@chakra-ui/react'
import { IoFastFoodOutline } from 'react-icons/io5'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { TableColumn } from 'src/components/CustomDataTable'
import { ChakraSpan } from 'src/components/Form/ChakraSpan'
import { ICharity } from 'src/types/Charity'

const tableColumns: TableColumn<ICharity>[] = [
  {
    label: "Active",
    name: "isActive",
    isBoolean: true,
  },
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'Amount Pending',
    name: 'amountPending',
    options: {
      customRender: (value: ICharity) => <span>${(value.amountPending ?? 0).toFixed(2)}</span>,
    }
  },
  {
    label: 'Amount Received',
    name: 'amountTransferred',
    options: {
      customRender: (value: ICharity) => <span>${(value.amountTransferred ?? 0).toFixed(2)}</span>,
    }
  },
  {
    label: "Has Bank Account",
    name: "stripeAccountId",
    isBoolean: true
  },
  {
    label: "Is Pending Approval",
    name: "isPendingApproval",
    isBoolean: true
  },
  {
    label: "Permissions",
    options: {
      customRender: (value: ICharity) => (
        <Flex gap={2} fontSize="2xl">
          {value.isAllowedToReceiveDonations && <ChakraSpan color="orange.400"><RiMoneyDollarCircleLine /></ChakraSpan>}
          {value.isAllowedToReceiveBags && <ChakraSpan color="primary.500"><IoFastFoodOutline /></ChakraSpan>}
        </Flex>
      )
    }
  }
]

export default tableColumns
