import { IVoucherAmountSuggestion } from 'src/types/VoucherAmountSuggestion'
import { api } from '.'

type IVoucherAmountSuggestionApi = {
  get(): Promise<IVoucherAmountSuggestion[]>
  getById(id: string): Promise<IVoucherAmountSuggestion>
  changeStatus(idList: { idList: string[] }, activate: boolean): Promise<{ success: boolean }>
  create(voucherAmountSuggestion: IVoucherAmountSuggestion): Promise<{ success: boolean }>
  update(voucherAmountSuggestion: IVoucherAmountSuggestion): Promise<{ success: boolean }>
}

const VoucherAmountSuggestionApi = (): IVoucherAmountSuggestionApi => {
  const voucherAmountSuggestionApi = '/admin/voucher-amount-suggestion'

  const get = (): Promise<IVoucherAmountSuggestion[]> => api.get(voucherAmountSuggestionApi)
  const create = (voucherAmountSuggestion: IVoucherAmountSuggestion): Promise<{ success: boolean }> =>
    api.post(voucherAmountSuggestionApi, voucherAmountSuggestion)
  const update = (voucherAmountSuggestion: IVoucherAmountSuggestion): Promise<{ success: boolean }> =>
    api.patch(`${voucherAmountSuggestionApi}/${voucherAmountSuggestion._id}`, voucherAmountSuggestion)
  const getById = (id: string): Promise<IVoucherAmountSuggestion> => api.get(`${voucherAmountSuggestionApi}/${id}`)
  const changeStatus = (idList: { idList: string[] }, activate: boolean): Promise<{ success: boolean }> =>
    api.patch(`${voucherAmountSuggestionApi}/${activate ? 'activate' : 'deactivate'}`, idList)

  return {
    get,
    getById,
    changeStatus,
    create,
    update,
  }
}

export default VoucherAmountSuggestionApi()
