import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Input } from 'src/components/Form/Input'
import { FormCard } from 'src/components/Layout/FormCard'
import { Formik } from 'src/types/Formik'
import { IVoucherAmountSuggestion } from 'src/types/VoucherAmountSuggestion'

const VoucherAmountSuggestionForm: React.FC<{ formik: Formik<IVoucherAmountSuggestion> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="Voucher Amount Suggestion">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <Input
            error={errors.amount}
            type="number"
            isCurrency
            label="Amount"
            name="amount"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.amount}
          />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default VoucherAmountSuggestionForm
