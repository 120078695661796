import { Button, Center, Flex, GridItem, SimpleGrid } from '@chakra-ui/react'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import MerchandiseApi from 'src/api/merchandise'
import { AlertSuccess } from 'src/components/Alert'
import { MultiSelect, MultiSelectOptionProps } from 'src/components/Form/MultiSelect'
import { FormCard } from 'src/components/Layout/FormCard'
import { MerchandiseCard } from 'src/components/Layout/StoreMerchandiseCard'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IMerchandise } from 'src/types/Merchandise'
import { IStore } from 'src/types/User'

interface StoreMerchandiseStockProps { store: IStore, activeMerchandises: IMerchandise[] }

const StoreMerchandiseStock = ({ store, activeMerchandises }: StoreMerchandiseStockProps) => {
  const { dispatch } = React.useContext(AppContext)
  const [merchandise, setMerchandise] = useState<string>()
  const [merchandises, setMerchandises] = useState<IMerchandise[]>([])
  const [merchandiseOptions, setMerchandiseOptions] = useState<MultiSelectOptionProps[]>([])

  const fetchMerchandises = useCallback(() => {
    if (!store._id) return
    dispatch({ type: Types.Loading, payload: { loading: true } })
    MerchandiseApi.listByStore(store._id as string)
      .then((res) => {
        setMerchandises(res)
        setMerchandiseOptions(
          activeMerchandises
            .filter((activeMerchandise) => !res.find((merchandise) => merchandise.id === activeMerchandise.id))
            .map((merchandise) => ({ label: merchandise.name, value: merchandise.id }))
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, store._id, activeMerchandises])

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setMerchandise(e.target.value)
  }

  useEffect(() => {
    fetchMerchandises()
  }, [fetchMerchandises])

  const handleAddMerchandise = () => {
    if (merchandise) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      MerchandiseApi.assignToStore(store._id as string, merchandise)
        .then(() => {
          AlertSuccess('Merchandise assigned successfully!')
          setMerchandise('')
          fetchMerchandises()
        })
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    }
  }

  return (
    <>
      <FormCard title="">
        <Flex alignItems="end" justifyContent="space-between" gap={2}>
          <MultiSelect
            isMulti={false}
            label="Select one merchandise to assign to this store"
            name="merchandise"
            onChange={handleChange}
            value={merchandise}
            options={merchandiseOptions}
          />
          <Button colorScheme="primary" size="sm" height="40px" onClick={handleAddMerchandise}>
            Add
          </Button>
        </Flex>
      </FormCard>
      <FormCard title="">
        <SimpleGrid columns={1} spacing={3}>
          {
            merchandises?.length > 0 ? merchandises.map((merchandise) => (
              <GridItem key={merchandise.id}>
                <MerchandiseCard
                  merchandise={merchandise}
                  grocerId={store._id as string}
                />
              </GridItem>
            ))
              : (
                <GridItem>
                  <Center>
                    <p>No merchandise assigned to this store</p>
                  </Center>
                </GridItem>
              )
          }
          <GridItem>

          </GridItem>
        </SimpleGrid>
      </FormCard>
    </>
  )
}

export default StoreMerchandiseStock
