import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { BsBasket, BsFillPersonFill, BsFillPersonLinesFill } from 'react-icons/bs'
import { FiShoppingBag } from 'react-icons/fi'
import { MdOutlineGroup, MdOutlineGroupAdd } from 'react-icons/md'
import DashboardApi, { IDashboardUserFilter } from 'src/api/dashboard'
import BusinessesLocation from 'src/components/BusinessesLocation'
import DashboardCard from 'src/components/DashboardCard'
import GrocerListWithFavourites from 'src/components/GrocerListWithFavourites'
import Page from 'src/components/Page'
import { DASHBOARD_FILTER_OPTIONS } from 'src/constants'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IDashboard } from 'src/types/Dashboard'

const Dashboard = () => {
  const { dispatch } = React.useContext(AppContext)
  const [data, setData] = React.useState<any>([])
  const [filter, setFilter] = React.useState<IDashboardUserFilter>({ state: '' })

  const handleSetFilter = (value: string) => {
    setFilter({ state: value })
  }

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    DashboardApi.get(filter)
      .then((dashboardData: IDashboard) => {
        if (dashboardData) {
          const filterTextToAppend = ` ${filter?.state ? ` in ${filter.state}` : ''}`
          setData([
            {
              value: dashboardData.customersTotal,
              icon: <MdOutlineGroup />,
              description: `Customers Registered${filterTextToAppend}`,
              filterOptions: DASHBOARD_FILTER_OPTIONS,
            },
            {
              value: dashboardData.customersToday,
              icon: <MdOutlineGroupAdd />,
              description: `${dashboardData.customersComparison.toFixed(2)}% more users than yesterday${filterTextToAppend}`,
            },
            {
              value: dashboardData.ordersTotal,
              icon: <AiOutlineShoppingCart />,
              description: `Orders Made${filterTextToAppend}`,
            },
            {
              value: dashboardData.ordersToday,
              icon: <BsBasket />,
              description: `Orders Today${filterTextToAppend}`,
            },
            {
              value: dashboardData.totalBagItemsSold,
              icon: <FiShoppingBag />,
              description: `Foody Bag items sold${filterTextToAppend} \n(Approximately)`,
            },
            {
              value: dashboardData.totalBagsSold,
              icon: <FiShoppingBag />,
              description: `Foody Bags sold${filterTextToAppend}`,
            },
            {
              value: dashboardData.uniqueCustomersToday,
              icon: <BsFillPersonFill />,
              description: `Unique customers today${filterTextToAppend}`,
            },
            {
              value: dashboardData.uniqueCustomersWeek,
              icon: <BsFillPersonLinesFill />,
              description: `Unique customers this week${filterTextToAppend}`,
            },
            {
              value: dashboardData.uniqueCustomersMonth,
              icon: <BsFillPersonLinesFill />,
              description: `Unique customers this month${filterTextToAppend}`,
            },
          ])
        }
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setData, filter])

  if (!data) return null

  return (
    <Page title="Dashboard">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem colSpan={[2]}>
          <SimpleGrid columns={[1, 1, 3]} spacing={4} mb="4">
            {data.map((item: any, i: number) => (
              <GridItem key={item.description}>
                <DashboardCard
                  icon={item.icon}
                  value={item.value}
                  description={item.description}
                  iteration={i}
                  filter={filter?.state}
                  filterOptions={item.filterOptions}
                  onFilterChange={handleSetFilter}
                />
              </GridItem>
            ))}
          </SimpleGrid>
        </GridItem>
        <GridItem>
          <BusinessesLocation />
        </GridItem>
        <GridItem>
          <GrocerListWithFavourites />
        </GridItem>
      </SimpleGrid>
    </Page>
  )
}

export default Dashboard
