import { Box, Center, Divider, Flex, Image, Text, VStack } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import MerchandiseApi from 'src/api/merchandise'
import { useAppContext } from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IMerchandise, IMerchandiseAvailability, merchandiseAvailabilityInitialValue } from 'src/types/Merchandise'
import merchandiseStoreValidationSchema from 'src/views/Store/merchandiseStoreValidationSchema'
import { AlertSuccess } from '../Alert'
import { PrimaryButton } from '../Button/PrimaryButton'
import { Input } from '../Form/Input'

interface MerchandiseCardProps {
  merchandise: IMerchandise
  grocerId: string
}

export function MerchandiseCard({ merchandise, grocerId }: MerchandiseCardProps) {
  const { dispatch } = useAppContext()

  const onSubmit = async (values: IMerchandiseAvailability) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    MerchandiseApi.updateStockQuantity({
      merchandiseId: merchandise.id,
      quantityAvailable: values.quantityAvailable,
      grocerId
    })
      .then((result) => {
        if (result.success) AlertSuccess('Merchandise updated successfully!')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const { errors, handleBlur, handleChange, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: merchandiseAvailabilityInitialValue,
    onSubmit,
    validationSchema: merchandiseStoreValidationSchema,
  }) as unknown as Formik<IMerchandiseAvailability>

  useEffect(() => {
    if (merchandise) {
      const merchandiseAvailable = merchandise.availableForGrocers.find((availability) => availability.grocerId === grocerId)
      setFieldValue('quantityAvailable', merchandiseAvailable?.quantityAvailable || 0)
    }
  }, [merchandise, setFieldValue, grocerId])

  return (
    <Flex
      my="2"
      w="100%"
      maxW="21rem"
      minH="6.25rem"
      borderRadius="xl"
      overflow="hidden"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)"
      align="center"
      direction="column"
      bg="gray.100"
    >
      <Center objectFit="cover" w="100%" h="10rem" overflow="hidden">
        <Image src={merchandise.picture} alt={merchandise.name} minW="100%" minH="100%" />
      </Center>
      <Flex w="100%" direction="column" justify="space-between" align="space-between" p=".5rem .75rem" flexGrow="1">
        <VStack align="flex-start" mb="4" justify="space-between" h="100%">
          <Box w="100%">
            <Text variant="text-20-bold">{merchandise.name}</Text>
            <Text variant="text-16-reg" noOfLines={3} minH="4rem">
              {merchandise.description}
            </Text>
          </Box>
          <Box w="100%">
            <Divider my="2" borderColor="gray.300" />
            <Text variant="text-16-reg" color="gray.500">
              Store Price: ${merchandise.price.toFixed(2)}
            </Text>
            <Flex w="100%" alignItems="center">
              <Text variant="text-16-reg" color="gray.500" w="15rem">
                Quantity Available:
              </Text>
              <Input
                w="6rem"
                error={errors.quantityAvailable}
                name="quantityAvailable"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.quantityAvailable}
                type="number"
              />
            </Flex>
          </Box>
        </VStack>
        <Flex flexDirection="column" gap={2}>
          <PrimaryButton onClick={() => handleSubmit()} variant="solid">
            Save
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  )
}
