import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import ImageUpload from 'src/components/Form/ImageUpload'
import { Input } from 'src/components/Form/Input'
import { FormCard } from 'src/components/Layout/FormCard'
import { Formik } from 'src/types/Formik'
import { IReviewHighlight } from 'src/types/ReviewHighlight'
import { IUploadFile } from 'src/types/UploadFile'

const ReviewHighlightForm: React.FC<{ formik: Formik<IReviewHighlight> }> = ({ formik }) => {
  const { errors, values, setFieldValue, handleBlur, handleChange } = formik

  const onUpload = React.useCallback(
    (name: string, file: IUploadFile) => {
      setFieldValue(name, file)
    },
    [setFieldValue]
  )

  return (
    <FormCard title="Highlight Details">
      <SimpleGrid columns={[1, 1, 4]} spacing={4}>
        <GridItem>
          <ImageUpload onUpload={onUpload} name="pictureFile" showPreview={true} currentPicture={values.picture} />
        </GridItem>
        <GridItem colSpan={3}>
          <Input error={errors.name} label="Name" name="name" onBlur={handleBlur} onChange={handleChange} value={values.name} />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default ReviewHighlightForm
