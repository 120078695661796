import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AnalyticsQuickTipApi from 'src/api/analyticsQuickTip'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IAnalyticsQuickTip, analyticsQuickTipInitialValue } from 'src/types/AnalyticsQuickTip'
import validationSchema from 'src/views/AnalyticsQuickTip/validationSchema'
import AnalyticsQuickTipForm from './AnalyticsQuickTipForm'

const EditAnalyticsQuickTip = () => {
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = (values: IAnalyticsQuickTip) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AnalyticsQuickTipApi.update(values)
      .then((result: IAnalyticsQuickTip) => {
        if (result.id) {
          AlertSuccess('Quick Tip saved successfully!')
          navigate('/app/analytics-quick-tip')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }
  const formik = useFormik({
    initialValues: analyticsQuickTipInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues, values, handleSubmit } = formik


  const handleChangeIsActive = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AnalyticsQuickTipApi.update({ id: values.id, isActive: !values.isActive } as IAnalyticsQuickTip)
      .then((result: IAnalyticsQuickTip) => {
        if (result.id) {
          AlertSuccess('Quick Tip saved successfully!')
          navigate('/app/analytics-quick-tip')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }


  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AnalyticsQuickTipApi.getById(params.id as string)
      .then((res: IAnalyticsQuickTip) => {
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title="Edit Quick Tip">
      <form onSubmit={handleSubmit}>
        <Toolbar
          title="Edit Quick Tip"
          showBack
          buttons={
            <>
              <PrimaryButton variant="solid" type="submit">
                Save
              </PrimaryButton>
              <PrimaryButton variant="outline" type="button" onClick={handleChangeIsActive}>
                {values.isActive ? 'Deactivate' : 'Activate'}
              </PrimaryButton>
            </>
          }
        />
        <AnalyticsQuickTipForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditAnalyticsQuickTip
