import { TableColumn } from 'src/components/CustomDataTable'
import { formatDateToString } from 'src/helpers/stringHelper'
import IUser from 'src/types/User'

const tableColumns: TableColumn<IUser>[] = [
  {
    label: 'Name',
    options: {
      customRender: (user) => (user ? `${user.firstName} ${user.lastName}` : '-'),
    },
  },
  {
    label: 'Phone Number',
    name: 'loginPhoneNumber',
  },
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'Customer Since',
    name: 'createdAt',
    options: {
      customRender: (user) => (user ? formatDateToString(user.createdAt) : '-'),
    },
  },
]

export default tableColumns
