import { IAddress } from './Address'
import { IUploadFile } from './UploadFile'

export interface IPromotion {
  id: string
  slug: string
  icon?: string
  iconFile?: IUploadFile
  title: string
  subtitle?: string
  summary: string
  colors: [string, string]
  detailsTitle?: string
  details?: string[]
  isActive: boolean
  placeId?: string
  isForEmail?: boolean
  location?: IAddress

  link?: string

  [key: string]: any
}

export const promotionInitialValue: IPromotion = {
  id: '',
  slug: '',
  icon: '',
  iconFile: undefined,
  title: '',
  subtitle: '',
  summary: '',
  colors: ['', ''],
  detailsTitle: '',
  details: [],
  isActive: true,
  isForEmail: false
}
