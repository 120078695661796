import { GridItem, SimpleGrid } from '@chakra-ui/react'
import IOrder from 'src/types/Order'
import { DataTextField } from '../Form/DataTextField'
import { FormCard } from './FormCard'

interface OrderPaymentCardProps {
  order: IOrder
}

export function OrderPaymentCard({ order }: OrderPaymentCardProps) {

  const calculateProfit = () => {
    return ((order?.orderTotal ?? 0) - (order?.amountTransferredToAmbassador ?? 0) - (order?.amountTransferredToBusiness ?? 0) - (order?.providerFees ?? 0)).toFixed(2)
  }

  return (
    <FormCard title="Order Payments">
      <SimpleGrid columns={[1, 1, 3]} spacing={4}>
        <GridItem>
          <DataTextField title="Total paid">{`$ ${order.orderTotal ? order.orderTotal.toFixed(2) : '0'}`}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Voucher used">{`$ ${order.voucherUsed ? order.voucherUsed.toFixed(2) : '0'}`}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Credits used">{`$ ${order.creditsUsed ? order.creditsUsed.toFixed(2) : '0'}`}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Paid to charity">{`$ ${order.amountRoundedUp ? order.amountRoundedUp.toFixed(2) : '0'}`}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Stripe fees">{`$ ${order.providerFees ? order.providerFees.toFixed(2) : '0'}`}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="GST">{`$ ${order.gst ? order.gst.toFixed(2) : '0'}`}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Paid to store">{`$ ${order.amountTransferredToBusiness ? order.amountTransferredToBusiness.toFixed(2) : '0'}`}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Paid to ambassador">{`$ ${order.amountTransferredToAmbassador ? order.amountTransferredToAmbassador.toFixed(2) : '0'}`}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Profit">{`$ ${calculateProfit()}`}</DataTextField>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}
