import { IGoogleAddressResponse } from 'src/types/Address'
import { api } from '.'

type IAddressApi = {
  getByAddress(address: string, ignoreFullAddress?: boolean): Promise<IGoogleAddressResponse[]>
}

const AddressApi = (): IAddressApi => {
  const adminApi = '/address'

  const getByAddress = (address: string, ignoreFullAddress?: boolean): Promise<IGoogleAddressResponse[]> =>
    api.get(`${adminApi}/${encodeURIComponent(address)}?ignoreFullAddress=${ignoreFullAddress ?? false}`)

  return {
    getByAddress,
  }
}

export default AddressApi()
