import { TableColumn } from 'src/components/CustomDataTable'
import { IPromotion } from 'src/types/Promotion'

const tableColumns: TableColumn<IPromotion>[] = [
  {
    label: 'Slug',
    name: 'slug',
  },
  {
    label: 'Title',
    name: 'title',
  },
  {
    label: 'Visible in Email',
    name: 'isForEmail',
    isBoolean: true,
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true,
  },
]

export default tableColumns
