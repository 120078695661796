import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OrderApi from 'src/api/order'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box, useDisclosure } from '@chakra-ui/react'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableProps, CustomDataTableState } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { IOrder, IOrderFilter } from 'src/types/Order'
import { PaginationResponse } from 'src/types/Pagination'
import OrderFilter from './OrderFilter'
import tableColumns from './tableColumns'

const Order = () => {
  const pageName = 'Orders'
  const navigate = useNavigate()
  const [orderList, setOrderList] = React.useState<PaginationResponse<IOrder>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const filterDisclosure = useDisclosure()
  const [hasFilterSet, setHasFilterSet] = useState<boolean>(false)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const setFilter = (filter: IOrderFilter) => {
    setTableState((prev) => ({ ...prev, filter, skip: 0 }))
    setHasFilterSet(!!(filter.code || filter.customer || filter.dateFrom || filter.dateTo || filter.grocerList?.length > 0))
  }

  const dataTableProps: CustomDataTableProps<IOrder> = {
    tableColumns,
    data: { count: orderList.count ?? 0, data: orderList.data ?? [] },
    onViewClick: (rowIndex: number) => navigate(orderList.data[rowIndex].id || orderList.data[rowIndex]._id),
    onTableChange,
    tableState,
  }

  const getData = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    OrderApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IOrder>) => {
        if (data) setOrderList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, tableState])

  React.useEffect(() => {
    getData()
  }, [getData])

  return (
    <Page title={pageName}>
      <Toolbar title={pageName} onFilterOpen={filterDisclosure.onOpen} hasFilterSet={hasFilterSet} />
      <Box mt={3}>
        <CustomDataTable serverSidePagination {...dataTableProps} />
      </Box>
      <OrderFilter isOpen={filterDisclosure.isOpen} onClose={filterDisclosure.onClose} onSubmit={setFilter} />
    </Page>
  )
}

export default Order
