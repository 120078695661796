import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { OrderCancellationCard } from 'src/components/Layout/OrderCancelledCard'
import { OrderDetailsCard } from 'src/components/Layout/OrderDetailsCard'
import { OrderItemsCard } from 'src/components/Layout/OrderItemsCard'
import { OrderPaymentCard } from 'src/components/Layout/OrderPaymentCard'
import { OrderTimelineCard } from 'src/components/Layout/OrderTimelineCard'
import OrderStatusEnum from 'src/enum/OrderStatusEnum'
import IOrder from 'src/types/Order'

interface OrderFormProps {
  order: IOrder
}

export function OrderForm({ order }: OrderFormProps) {
  return (
    <SimpleGrid columns={[1, 1, 3]} gap="3">
      <GridItem colSpan={2}>
        <SimpleGrid columns={1}>
          <GridItem>
            <OrderDetailsCard order={order} />
          </GridItem>
          <GridItem>
            <OrderPaymentCard order={order} />
          </GridItem>
          <GridItem>
            <OrderItemsCard items={order.items} />
          </GridItem>
        </SimpleGrid>
      </GridItem>
      <GridItem>
        <SimpleGrid columns={1}>
          <GridItem>
            <OrderTimelineCard history={order.history} />
          </GridItem>
          {order?.status === OrderStatusEnum.CANCELLED && (
            <GridItem>
              <OrderCancellationCard order={order} />
            </GridItem>
          )}
        </SimpleGrid>
      </GridItem>
    </SimpleGrid>
  )
}
