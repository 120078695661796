import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import VoucherAmountSuggestionApi from 'src/api/voucherAmountSuggestion'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IVoucherAmountSuggestion, voucherAmountSuggestionInitialValue } from 'src/types/VoucherAmountSuggestion'
import validationSchema from 'src/views/VoucherAmountSuggestion/validationSchema'
import VoucherAmountSuggestionForm from './VoucherAmountSuggestionForm'

const CreateVoucherAmountSuggestion: React.FC = () => {
  const pageName: string = 'Create Voucher Amount Suggestion'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IVoucherAmountSuggestion) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    VoucherAmountSuggestionApi.create({ ...values } as IVoucherAmountSuggestion)
      .then(() => {
        AlertSuccess('Suggestion saved successfully!')
        navigate('/app/voucher-amount-suggestion')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: voucherAmountSuggestionInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <VoucherAmountSuggestionForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreateVoucherAmountSuggestion
