import { TableColumn } from 'src/components/CustomDataTable'
import { IAppVersion } from 'src/types/AppVersion'

const tableColumns: TableColumn<IAppVersion>[] = [
  {
    label: 'Os',
    name: 'os',
  },
  {
    label: 'Version',
    name: 'version',
  },
  {
    label: 'Is Stores',
    name: 'isForBusinessApp',
    isBoolean: true,
  },
  {
    label: 'Is Mandatory',
    name: 'isRequired',
    isBoolean: true,
  },
]

export default tableColumns
