import { Avatar, Box, Button, Card, CardBody, Flex, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import CharityApi from 'src/api/charity'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import { useAppContext } from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ICharity } from 'src/types/Charity'

export function CharityPayments() {
  const [charities, setCharities] = useState<ICharity[]>([])
  const { dispatch } = useAppContext()

  const getCharities = useCallback(async () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    CharityApi.getForPayment()
      .then((data: ICharity[]) => {
        setCharities(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setCharities])

  useEffect(() => {
    getCharities()
  }, [getCharities])

  const payCharity = async (charityId?: string[]) => {
    let listOfCharities = charityId ? charityId : charities.map((charity) => charity.id)
    if (!listOfCharities) {
      AlertError('No charities to pay')
      return
    }

    try {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      const result = await CharityApi.payCharities(listOfCharities)
      if (result) {
        await getCharities()
        dispatch({ type: Types.Loading, payload: { loading: false } })
        AlertSuccess('Charities paid successfully')
        return
      }
    } catch (error) {
      console.log(error)
    }

    dispatch({ type: Types.Loading, payload: { loading: false } })
  }

  return (
    <Box mt={3} w="100%" maxW={"50rem"}>
      {/* <SimpleGrid spacing={2} columns={[1, 1, 3]}> */}
      {charities.map((charity) => (
        <Card>
          <CardBody>
            <Flex gap={4} justify="start" alignItems="center">
              <Avatar src={charity.picture} />
              <Text variant="text-16-reg"><b>{charity.name}</b> - ${charity.amountPending.toFixed(2)}</Text>
              <Button ml="auto" onClick={() => payCharity([charity.id])}>Pay</Button>
            </Flex>
          </CardBody>
        </Card>
        // <GridItem cursor="pointer" key={storeGroup.businessOwnerId} w="100%" onClick={() => handleSelectStoreGroup(storeGroup)}>
        // </GridItem>
      ))}
      {/* </SimpleGrid> */}
    </Box>
  )
}