import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReviewHighlightApi from 'src/api/reviewHighlight'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import { VisibilityTableToolbar } from 'src/components/CustomDataTable/VisibilityTableToolbar'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { IReviewHighlight } from 'src/types/ReviewHighlight'
import tableColumns from './tableColumns'

const ReviewHighlight = () => {
  const navigate = useNavigate()
  const [reviewHighlightList, setReviewHighlightList] = React.useState<IReviewHighlight[]>([])
  const { dispatch } = React.useContext(AppContext)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ReviewHighlightApi.get()
      .then((data: IReviewHighlight[]) => {
        setReviewHighlightList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setReviewHighlightList])

  const handleChangeStatus = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ReviewHighlightApi.changeStatus({ idList }, activate)
      .then(() => {
        setReviewHighlightList((prev: IReviewHighlight[]) =>
          prev.map((reviewHighlight: IReviewHighlight) => {
            if (idList.indexOf(reviewHighlight.id) > -1) reviewHighlight.isActive = activate
            return reviewHighlight
          })
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleClick = (rowIndex: number) => navigate(reviewHighlightList[rowIndex].id || reviewHighlightList[rowIndex]._id)
  const handleAdd = () => navigate('new')

  const customToolbar = (selectedRows: number[]) => {
    return <VisibilityTableToolbar data={reviewHighlightList} handleChangeStatus={handleChangeStatus} selectedRows={selectedRows} />
  }

  return (
    <Page title="Review Highlights">
      <Toolbar title="Review Highlights" buttons={<PrimaryButton onClick={handleAdd}>Add Highlight</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable
          showCheckbox
          customToolbar={customToolbar}
          onEditClick={handleClick}
          data={reviewHighlightList || []}
          tableColumns={tableColumns}
        />
      </Box>
    </Page>
  )
}

export default ReviewHighlight
