enum BroadcastActionEnum {
  STORE = 'Open Store',
  BANNER = 'Open Banner',
  MAP = 'Open Map',
  PROMOTION = 'Open Promotion',
  FAVOURITES = 'Open Favourites',
  INVITE_FRIENDS = 'Open Invite Friends',
}

export default BroadcastActionEnum
