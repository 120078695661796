import { Badge, Text } from '@chakra-ui/react'
import { TableColumn } from 'src/components/CustomDataTable'
import BroadcastActionEnum from 'src/enum/BroadcastAction.enum'
import BroadcastStatusEnum from 'src/enum/BroadcastStatus.enum'
import { getKeyEnum } from 'src/helpers/enumHelper'
import { formatDateToString } from 'src/helpers/stringHelper'
import { IBroadcasting } from 'src/types/Broadcasting'

const tableColumns: TableColumn<IBroadcasting>[] = [
  {
    label: 'Title',
    name: 'title',
  },
  {
    label: 'Location',
    name: 'location',
    options: {
      customRender: (value: IBroadcasting) => <Text>{value.location ? value.location : '-'}</Text>,
    },
  },
  {
    label: 'Action',
    name: 'action',
    options: {
      customRender: (value: IBroadcasting) => <Text>{value.action ? BroadcastActionEnum[value.action] : '-'}</Text>,
    },
  },
  {
    label: 'Success Count',
    name: 'successCount',
    options: {
      customRender: (value: IBroadcasting) => (
        <Text>{value.successCount ? `${value.successCount} user${value.successCount > 1 ? 's' : ''}` : '0 users'}</Text>
      ),
    },
  },
  {
    label: 'Failure Count',
    name: 'failureCount',
    options: {
      customRender: (value: IBroadcasting) => (
        <Text>{value.failureCount ? `${value.failureCount} user${value.failureCount > 1 ? 's' : ''}` : '0 users'}</Text>
      ),
    },
  },
  {
    label: 'Clicks Count',
    name: 'clickCount',
    options: {
      customRender: (value: IBroadcasting) => (
        <Text>{value.clickCount ? `${value.clickCount} user${value.clickCount > 1 ? 's' : ''}` : '0 users'}</Text>
      ),
    },
  },
  {
    label: 'Broadcasted At',
    name: 'createdAt',
    options: {
      customRender: (value: IBroadcasting) => (
        <Text>{value.createdAt ? formatDateToString(value.createdAt, { hour: '2-digit', minute: '2-digit' }) : '-'}</Text>
      ),
    },
  },
  {
    label: 'Status',
    name: 'status',
    options: {
      customRender: (value: IBroadcasting) => (
        <Badge colorScheme={getBadgeColor(value.status)} textTransform="none">
          {value?.status ? BroadcastStatusEnum[value.status] : '-'}
        </Badge>
      ),
    },
  },
]

const getBadgeColor = (status: BroadcastStatusEnum) => {
  switch (status) {
    case getKeyEnum(BroadcastStatusEnum.SENDING, BroadcastStatusEnum):
      return 'yellow'
    case getKeyEnum(BroadcastStatusEnum.COMPLETED, BroadcastStatusEnum):
      return 'green'
    case getKeyEnum(BroadcastStatusEnum.ERROR, BroadcastStatusEnum):
      return 'red'
    default:
      return 'gray'
  }
}

export default tableColumns
