import { Box, Stack, Text } from '@chakra-ui/react'
import { PaginationItem } from './PaginationItem'

interface PaginationProps {
  totalItems: number
  limit: number
  skip: number
  currentPage: number
  handleChangePage: (pageDestiny: number) => void
}

const siblingCount = 1

const generatePagesArray = (from: number, to: number) => {
  return [...new Array(to - from)].map((_, index) => from + index + 1).filter((page) => page > 0)
}

export function Pagination({ totalItems, limit, skip, currentPage, handleChangePage }: PaginationProps) {
  const lastPage = Math.ceil(totalItems / limit)
  const previousPages = currentPage > 1 ? generatePagesArray(currentPage - 1 - siblingCount, currentPage - 1) : []
  const nextPages = currentPage < lastPage ? generatePagesArray(currentPage, Math.min(currentPage + 1, lastPage)) : []

  return (
    <Stack direction={['column', 'row']} justify="space-between" align="center" spacing="6">
      <Box>
        <strong>{skip + 1}</strong> - <strong>{(skip + limit <= totalItems ? skip + limit : totalItems) ?? 1}</strong> of{' '}
        <strong>{totalItems ?? 1}</strong>
      </Box>
      <Stack direction="row" spacing="2">
        {currentPage > 1 + siblingCount && (
          <>
            <PaginationItem pageNumber={1} onClick={() => handleChangePage(1)} />
            {currentPage > 2 + siblingCount && (
              <Text color="gray.300" width="8" align="center">
                ...
              </Text>
            )}
          </>
        )}

        {previousPages.length > 0 &&
          previousPages.map((page) => <PaginationItem pageNumber={page} key={page} onClick={() => handleChangePage(page)} />)}

        <PaginationItem pageNumber={currentPage} isCurrent onClick={() => handleChangePage(currentPage + 1)} />

        {nextPages.length > 0 && nextPages.map((page) => <PaginationItem pageNumber={page} key={page} onClick={() => handleChangePage(page)} />)}

        {currentPage + siblingCount < lastPage && (
          <>
            {currentPage + 1 + siblingCount < lastPage && (
              <Text color="gray.300" width="8" align="center">
                ...
              </Text>
            )}
            <PaginationItem pageNumber={lastPage} onClick={() => handleChangePage(lastPage)} />
          </>
        )}
      </Stack>
    </Stack>
  )
}
