import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { ChangeEvent, ForwardRefRenderFunction, forwardRef, useEffect, useState } from 'react'
import { Input, InputProps } from './Input'

interface AutoCompleteInputProps extends InputProps {
  getData?: (value: string) => Promise<{ value: string; label: string }[]> | void
  onValueSelected: (value: { value: string; label: string }) => void
  options?: { value: string; label: string }[]
}

export const AutoCompleteInputBase: ForwardRefRenderFunction<HTMLInputElement, AutoCompleteInputProps> = (
  { getData, onValueSelected, ...rest }: AutoCompleteInputProps,
  ref
) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (rest.onChange) rest.onChange(event)

    if (getData) getData(event.target.value)
  }

  const handleSelection = (option: { value: string; label: string }) => {
    onValueSelected(option)
    if (rest.onChange) {
      rest.onChange({ target: { name: rest.name, value: option.label } } as ChangeEvent<HTMLInputElement>)
      onClose()
    }
  }

  useEffect(() => {
    if (rest.options) {
      setOptions(rest.options)
      onOpen()
    }
  }, [rest.options, onOpen])

  return (
    <Flex position="relative">
      <Input {...rest} onChange={handleChange} ref={ref} name={rest.name} />
      {isOpen && options.length > 0 && (
        <Flex
          direction="column"
          gap={2}
          position="absolute"
          top="4rem"
          backgroundColor="white"
          border="1px solid"
          borderRadius="lg"
          padding={2}
          maxH="10rem"
          overflowY="scroll"
          zIndex={9999999}
        >
          {options.map((option) => (
            <Box
              _hover={{ backgroundColor: 'gray.100' }}
              padding={1}
              paddingX={4}
              cursor="pointer"
              key={option.value}
              onClick={() => handleSelection(option)}
            >
              {option.label}
            </Box>
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export const AutoCompleteInput = forwardRef(AutoCompleteInputBase)
