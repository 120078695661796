import React from 'react'
import ReviewApi from 'src/api/review'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box, useDisclosure } from '@chakra-ui/react'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import { ReviewDetailsModal } from 'src/components/Overlay/ReviewDetailsModal'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { IReview, reviewInitialValue } from 'src/types/Review'
import tableColumns from './tableColumns'

const ReviewReported = () => {
  const disclosure = useDisclosure()
  const [reviewList, setReviewList] = React.useState<IReview[]>([])
  const [review, setReview] = React.useState<IReview>(reviewInitialValue)
  const { dispatch } = React.useContext(AppContext)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ReviewApi.get()
      .then((data: IReview[]) => {
        setReviewList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setReviewList])

  const handleClick = (rowIndex: number) => {
    const id = reviewList[rowIndex].id
    const reviewFromList = reviewList.find((r) => r.id === id)
    if (reviewFromList) {
      setReview(reviewFromList)
      disclosure.onOpen()
    }
  }

  return (
    <Page title="Reviews Reported">
      <Toolbar title="Reviews Reported" />
      <Box mt={3}>
        <CustomDataTable onViewClick={handleClick} data={reviewList || []} tableColumns={tableColumns} />
      </Box>
      <ReviewDetailsModal isOpen={disclosure.isOpen} onClose={disclosure.onClose} review={review} />
    </Page>
  )
}

export default ReviewReported
