import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { IAmbassador } from 'src/types/Ambassador'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { api } from '.'

type IAmbassadorApi = {
  get(params: PaginationData<IAmbassador>): Promise<PaginationResponse<IAmbassador>>
  getById(id: string): Promise<IAmbassador>
  approve(ambassadorId: string): Promise<{ success: boolean }>
  ban(ambassadorId: string, reason: string): Promise<{ success: boolean }>
}

const AmbassadorApi = (): IAmbassadorApi => {
  const ambassadorApi = '/admin/ambassador'

  const get = (params: PaginationData<IAmbassador>): Promise<PaginationResponse<IAmbassador>> => api.get(`${ambassadorApi}${generateParamsFromPagination(params)}`)
  const getById = (id: string): Promise<IAmbassador> => api.get(`${ambassadorApi}/${id}`)
  const approve = (id: string): Promise<{ success: boolean }> => api.post(`${ambassadorApi}/${id}/approve`)
  const ban = (id: string, reason: string): Promise<{ success: boolean }> => api.post(`${ambassadorApi}/${id}/ban`, { reason })

  return {
    get,
    getById,
    approve,
    ban
  }
}

export default AmbassadorApi()
