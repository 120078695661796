import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import SuggestionReasonApi from 'src/api/suggestionReason'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ISuggestionReason, suggestionReasonInitialValue } from 'src/types/SuggestionReason'
import validationSchema from 'src/views/SuggestionReason/validationSchema'
import SuggestionReasonForm from './SuggestionReasonForm'

const CreateSuggestionReason: React.FC = () => {
  const pageName: string = 'Create Suggestion Reason'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: ISuggestionReason) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    SuggestionReasonApi.create({ description: values.description, emailDescription: values.emailDescription } as ISuggestionReason)
      .then(() => {
        AlertSuccess('Reason saved successfully!')
        navigate('/app/suggestion-reason')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: suggestionReasonInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <SuggestionReasonForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreateSuggestionReason
