import { TableColumn } from 'src/components/CustomDataTable'
import { snakeToCamel } from 'src/helpers/stringHelper'
import { ISurvey } from 'src/types/Survey'

const tableColumns: TableColumn<ISurvey>[] = [
  {
    label: 'Title',
    name: 'title',
  },
  {
    label: 'Question',
    name: 'question',
  },
  {
    label: 'Trigger',
    name: 'trigger',
    options: {
      customRender: (value) => snakeToCamel(value?.trigger)
    }
  },
  {
    label: 'Answer Type',
    name: 'answerType',
    options: {
      customRender: (value) => snakeToCamel(value?.answerType)
    }
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true
  }
]

export default tableColumns
