import { Box, Center, Flex, Image, Text } from '@chakra-ui/react'
import imageCompression from 'browser-image-compression'
import React from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'
import { BsCardImage } from 'react-icons/bs'
import AppContext from 'src/contexts/AppContext'
import { fileToBase64 } from 'src/helpers/base64Helper'
import { Types } from 'src/reducers/AppReducer'
import { IUploadFile } from 'src/types/UploadFile'
import { AlertError } from '../Alert'
import { ChakraSpan } from './ChakraSpan'
import { Label } from './Label'

interface ImageUploadProps {
  onUpload: (name: string, uploadFile: IUploadFile, previewUrl: string, file?: File) => void
  name: string
  showPreview?: boolean
  currentPicture?: string
  label?: string
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onUpload, name, showPreview = true, currentPicture, label }) => {
  const { dispatch } = React.useContext(AppContext)
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({ accept: { 'image/*': [] }, multiple: false })
  const [imagesPreview, setImagesPreview] = React.useState<string>('')

  const compressAndSetPreview = React.useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles) {
        dispatch({ type: Types.Loading, payload: { loading: true } })
        const file = acceptedFiles[0]
        const options = {
          maxSizeMB: 300,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        }

        if (file) {
          imageCompression(file, options).then(async (image) => {
            onUpload(
              name,
              {
                data: (await fileToBase64(image)) as string,
                filetype: file.type,
              },
              URL.createObjectURL(image),
              image
            )

            setImagesPreview(URL.createObjectURL(image))
            dispatch({ type: Types.Loading, payload: { loading: false } })
          })
        } else {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        }
      }
    },
    [dispatch, onUpload, name]
  )

  React.useEffect(() => {
    compressAndSetPreview(acceptedFiles as FileWithPath[])
    // FIXME: check here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  React.useEffect(() => {
    if (fileRejections.length > 0) {
      let message = 'Invalid File'
      if (fileRejections.length > 0) message = fileRejections[0].errors[0].message
      AlertError(message)
    }
  }, [fileRejections])

  React.useEffect(() => {
    if (showPreview && currentPicture) setImagesPreview(currentPicture)
  }, [showPreview, currentPicture])

  return (
    <Flex align="center" direction="column">
      {label && <Label htmlFor={name} label={label} mb="0" />}
      {showPreview ? (
        imagesPreview ? (
          <Center w="10rem" h="10rem" borderRadius="lg" overflow="hidden">
            <Image src={imagesPreview} alt={`${name}-image`} minW="100%" minH="100%" objectFit="cover" />
          </Center>
        ) : (
          <Center backgroundColor="gray.300" w="10rem" h="10rem" borderRadius="20">
            <ChakraSpan fontSize="4rem" color="gray.100">
              <BsCardImage />
            </ChakraSpan>
          </Center>
        )
      ) : null}
      <Box
        {...getRootProps()}
        mt="4"
        p="1.25rem"
        height="6rem"
        width="12rem"
        borderWidth="2px"
        borderRadius="lg"
        borderColor="primary.500"
        borderStyle="dashed"
        backgroundColor="gray.200"
        textAlign="center"
      >
        <input {...getInputProps()} />
        <Text variant="text-14-reg" color="primary.500">
          Drag 'n' drop a file here <br /> or click to select files
        </Text>
      </Box>
    </Flex>
  )
}

export default ImageUpload
