import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import addressApi from 'src/api/address'
import promotionApi from 'src/api/promotion'
import promotionalBannerApi from 'src/api/promotionalBanner'
import storeApi from 'src/api/store'
import { AutoCompleteInput } from 'src/components/Form/AutoCompleteInput'
import { Input } from 'src/components/Form/Input'
import { MultiSelect } from 'src/components/Form/MultiSelect'
import { Textarea } from 'src/components/Form/Textarea'
import { FormCard } from 'src/components/Layout/FormCard'
import { useAppContext } from 'src/contexts/AppContext'
import BroadcastActionEnum from 'src/enum/BroadcastAction.enum'
import { enumKeys, getEnumValue, getKeyEnum } from 'src/helpers/enumHelper'
import { Types } from 'src/reducers/AppReducer'
import { IAddress } from 'src/types/Address'
import { IBroadcasting } from 'src/types/Broadcasting'
import { Formik } from 'src/types/Formik'
import { debounce } from 'throttle-debounce'

const BroadcastingForm: React.FC<{ formik: Formik<IBroadcasting> }> = ({ formik }) => {
  const { errors, values, handleBlur, setFieldValue, handleChange } = formik
  const [addressList, setAddressList] = useState<{ label: string; value: string }[]>([])
  const [grocers, setGrocers] = useState<{ label: string; value: string }[]>([])
  const [banners, setBanners] = useState<{ label: string; value: string }[]>([])
  const [promotions, setPromotions] = useState<{ label: string; value: string }[]>([])
  const [ignoreRequest, setIgnoreRequest] = useState<string[]>([])

  const { dispatch } = useAppContext()

  const handleSearchAddress = useMemo(
    () =>
      debounce(800, (address?: string) => {
        if (!address) return false
        if (address.length <= 3) return false
        dispatch({ type: Types.Loading, payload: { loading: true } })
        addressApi
          .getByAddress(address, true)
          .then((addressesResult) => {
            setAddressList(addressesResult.map((address) => ({ label: address.description, value: address.placeId })))
          })
          .catch((err: Error) => console.log(err))
          .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
      }),
    [dispatch]
  )

  const handleAddressSelect = useCallback(
    (option: { value: string; label: string }) => {
      setFieldValue('placeId', option.value)
      setFieldValue('location', option.label)
    },
    [setFieldValue]
  )

  useEffect(() => {
    if (values.action === getKeyEnum(BroadcastActionEnum.STORE, BroadcastActionEnum) && !ignoreRequest.includes('grocer')) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      storeApi
        .listActive()
        .then((stores) => {
          setIgnoreRequest((prev) => [...prev, 'grocer'])
          setGrocers(stores.map((store) => ({ label: `${store.businessName} - ${(store.address as IAddress)?.suburb ?? ''}`, value: store._id })))
        })
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    } else if (values.action === getKeyEnum(BroadcastActionEnum.BANNER, BroadcastActionEnum) && !ignoreRequest.includes('banner')) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      promotionalBannerApi
        .listActive()
        .then((banners) => {
          setIgnoreRequest((prev) => [...prev, 'banner'])
          setBanners(banners.map((banner) => ({ label: banner.title, value: banner.id })))
        })
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    } else if (values.action === getKeyEnum(BroadcastActionEnum.PROMOTION, BroadcastActionEnum) && !ignoreRequest.includes('promotion')) {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      promotionApi
        .listActivePromotions()
        .then(({ promotions }) => {
          setIgnoreRequest((prev) => [...prev, 'promotion'])
          setPromotions(promotions.map((promotion) => ({ label: promotion.title, value: promotion.id })))
        })
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    }
  }, [values, dispatch, setGrocers, setBanners, setPromotions, ignoreRequest])

  return (
    <SimpleGrid columns={2} spacing={4}>
      <GridItem>
        <FormCard title="Notification details">
          <SimpleGrid columns={1} spacing={4}>
            <GridItem>
              <Input
                error={errors.title}
                label="Title"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                maxLength={100}
              />
            </GridItem>
            <GridItem>
              <Textarea
                error={errors.content}
                label="Content"
                name="content"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.content}
                rows={5}
                maxLength={1000}
              />
            </GridItem>
          </SimpleGrid>
        </FormCard>
      </GridItem>
      <GridItem>
        <FormCard title="Target details">
          <SimpleGrid columns={1} spacing={4}>
            <GridItem>
              <AutoCompleteInput
                label="Location (optional) - Australia only"
                name="location"
                error={errors.placeId}
                options={addressList}
                getData={handleSearchAddress}
                onValueSelected={handleAddressSelect}
                onChange={handleChange}
                value={values.location}
              />
            </GridItem>
          </SimpleGrid>
        </FormCard>
        <FormCard title="Action details">
          <SimpleGrid columns={1} spacing={4}>
            <GridItem>
              <MultiSelect
                isMulti={false}
                error={errors.action}
                label="Action"
                name="action"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.action}
                options={enumKeys(BroadcastActionEnum).map((key: string) => ({ label: getEnumValue(key, BroadcastActionEnum), value: key }))}
              />
            </GridItem>
            {values.action === getKeyEnum(BroadcastActionEnum.STORE, BroadcastActionEnum) && (
              <GridItem>
                <MultiSelect
                  isMulti={false}
                  error={errors.grocerId}
                  label="Store"
                  name="grocerId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.grocerId}
                  options={grocers}
                />
              </GridItem>
            )}
            {values.action === getKeyEnum(BroadcastActionEnum.BANNER, BroadcastActionEnum) && (
              <GridItem>
                <MultiSelect
                  isMulti={false}
                  error={errors.bannerId}
                  label="Banner (the banner must be active)"
                  name="bannerId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bannerId}
                  options={banners}
                />
              </GridItem>
            )}
            {values.action === getKeyEnum(BroadcastActionEnum.PROMOTION, BroadcastActionEnum) && (
              <GridItem>
                <MultiSelect
                  isMulti={false}
                  error={errors.promotionId}
                  label="Promotion (the promotion must be active)"
                  name="promotionId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.promotionId}
                  options={promotions}
                />
              </GridItem>
            )}
          </SimpleGrid>
        </FormCard>
      </GridItem>
    </SimpleGrid>
  )
}

export default BroadcastingForm
