import { GridItem, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import { BooleanColumn } from 'src/components/CustomDataTable/BooleanColumn'
import { DataTextField } from 'src/components/Form/DataTextField'
import { Input } from 'src/components/Form/Input'
import { FormCard } from 'src/components/Layout/FormCard'
import { IBusinessSuggestion } from 'src/types/BusinessSuggestion'
import { Formik } from 'src/types/Formik'

const BusinessSuggestionForm: React.FC<{ formik: Formik<IBusinessSuggestion> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange } = formik

  if (!values) return null

  return (
    <SimpleGrid columns={[1, 1, values.referrer ? 2 : 1]} spacing={4}>
      <GridItem>
        <FormCard title="Suggestion Details">
          <SimpleGrid columns={[1, 1, 2]} spacing={4}>
            <GridItem>
              <Input
                error={errors.businessName}
                label="Business Name"
                name="businessName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.businessName}
              />
            </GridItem>
            <GridItem>
              <Input
                error={errors.managerName}
                label="Manager Name"
                name="managerName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.managerName}
              />
            </GridItem>
            <DataTextField title="Manager Email">{values.managerEmail}</DataTextField>
            <GridItem>
              <DataTextField title="Is Email Sent?">
                <BooleanColumn data={values.isEmailSent} justify="flex-start" />
              </DataTextField>
            </GridItem>
            <GridItem colSpan={[1, 1, 2]}>
              <DataTextField title="Reasons">{values.reasons && values.reasons.map((reason) => <Text> - {reason}</Text>)}</DataTextField>
            </GridItem>
          </SimpleGrid>
        </FormCard>
      </GridItem>
      <GridItem>
        <FormCard title="Customer Details">
          <SimpleGrid columns={[1, 1, 2]} spacing={4}>
            <GridItem>
              <DataTextField title="Customer Name">{`${values.referrer?.firstName ?? ''} ${values.referrer?.lastName ?? ''}`}</DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Customer Email">{values.referrer?.email || '-'}</DataTextField>
            </GridItem>
            <GridItem>
              <DataTextField title="Customer Phone">{values.referrer?.loginPhoneNumber || '-'}</DataTextField>
            </GridItem>
          </SimpleGrid>
        </FormCard>
      </GridItem>
    </SimpleGrid>
  )
}

export default BusinessSuggestionForm
