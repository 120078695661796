import { Flex, Image, Link } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SOCIAL_MEDIA_IMAGES } from 'src/constants'
import { Formik } from 'src/types/Formik'
import { IStore } from 'src/types/User'
import { Input } from '../Form/Input'
import { FormCard } from './FormCard'

interface StoreSocialMediaCardProps {
  store: IStore
  formik: Partial<Formik<IStore>>
}

export function StoreSocialMediaCard({ store, formik }: StoreSocialMediaCardProps) {
  const [socialMedias, setSocialMedias] = useState(store.socialMedias)

  const { errors, values, handleBlur, setFieldValue } = formik

  useEffect(() => {
    const formattedSocialMedias = Object.keys(SOCIAL_MEDIA_IMAGES).map((socialMediaType) => {
      const socialMedia = store.socialMedias?.find((sm) => sm.type === socialMediaType)
      if (!socialMedia) {
        return {
          type: socialMediaType,
          link: ''
        }
      }
      return socialMedia
    })

    setSocialMedias(formattedSocialMedias)
    setFieldValue('socialMedias', formattedSocialMedias)
  }, [store.socialMedias, setFieldValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const [key, type, subKey] = name.split('.')

    if (subKey) {
      const index = values[key].findIndex((v) => v.type === type)

      if (index === -1) {
        setFieldValue(key, [
          ...values[key],
          {
            type,
            [subKey]: value
          }
        ])
      } else {
        setFieldValue(`${key}.${index}.${subKey}`, value)
      }
    }
  }

  return (
    <FormCard title="Store Social Media">
      <Flex gap="4" flexDirection="column">
        {socialMedias?.map((socialMedia, index) => {
          return (
            <Flex border="1px solid #eee" borderRadius="10px" padding={4} alignItems="end" gap={4} key={socialMedia.type}>
              <Link href={socialMedia?.link} target="_blank">
                <Image src={SOCIAL_MEDIA_IMAGES[socialMedia.type]} alt={socialMedia.type} width={'40px'} height={'40px'} />
              </Link>
              <Input
                error={errors.socialMedias}
                label="Link"
                name={`socialMedias.${socialMedia.type}.link`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.socialMedias && values.socialMedias[index]?.link}
              />
            </Flex>
          )
        })}
      </Flex>
    </FormCard>
  )
}
