import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserApi from 'src/api/user'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box, useDisclosure } from '@chakra-ui/react'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableProps, CustomDataTableState } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { PaginationResponse } from 'src/types/Pagination'
import { ICustomerFilter, IUser } from 'src/types/User'
import CustomerFilter from './CustomerFilter'
import tableColumns from './tableColumns'

const Customer = () => {
  const pageName = 'Customers'
  const navigate = useNavigate()
  const [userList, setUserList] = React.useState<PaginationResponse<IUser>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const filterDisclosure = useDisclosure()
  const [hasFilterSet, setHasFilterSet] = useState<boolean>(false)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const dataTableProps: CustomDataTableProps<IUser> = {
    tableColumns,
    data: userList || { count: 0, data: [] },
    onViewClick: (rowIndex: number) => navigate(userList.data[rowIndex].id || userList.data[rowIndex]._id),
    onTableChange,
    tableState,
  }

  const getData = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    UserApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IUser>) => {
        if (data) setUserList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, tableState])

  React.useEffect(() => {
    getData()
  }, [getData])

  const setFilter = (filter: ICustomerFilter) => {
    setTableState((prev) => ({ ...prev, filter, skip: 0 }))
    setHasFilterSet(!!(filter.name || filter.email || filter.name || filter.familyName))
  }

  return (
    <Page title={pageName}>
      <Toolbar title={pageName} onFilterOpen={filterDisclosure.onOpen} hasFilterSet={hasFilterSet} />
      <Box mt={3}>
        <CustomDataTable serverSidePagination {...dataTableProps} />
      </Box>
      <CustomerFilter isOpen={filterDisclosure.isOpen} onClose={filterDisclosure.onClose} onSubmit={setFilter} />
    </Page>
  )
}

export default Customer
