import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import ImageUpload from 'src/components/Form/ImageUpload'
import { Input } from 'src/components/Form/Input'
import { Textarea } from 'src/components/Form/Textarea'
import { FormCard } from 'src/components/Layout/FormCard'
import { Formik } from 'src/types/Formik'
import { IMerchandise } from 'src/types/Merchandise'
import { IUploadFile } from 'src/types/UploadFile'

const MerchandiseForm: React.FC<{ formik: Formik<IMerchandise> }> = ({ formik }) => {
  const { errors, values, handleBlur, setFieldValue, handleChange } = formik


  const onUpload = (_name: string, _uploadFile: IUploadFile, _preview: string, file: File) => {
    setFieldValue('pictureFile', file)
  }

  return (
    <FormCard title="Merchandise Details">
      <SimpleGrid columns={[1, 1, 12]} spacing={4}>
        <GridItem colSpan={2}>
          <ImageUpload onUpload={onUpload} name="picture" showPreview={true} currentPicture={values.picture} />
        </GridItem>
        <GridItem colSpan={10}>
          <SimpleGrid columns={[1, 1, 2]} spacing={4}>
            <GridItem>
              <Input error={errors.name} label="Name" name="name" onBlur={handleBlur} onChange={handleChange} value={values.name} />
            </GridItem>
            <GridItem>
              <Input type="number" error={errors.price} label="Price" name="price" onBlur={handleBlur} onChange={handleChange} value={values.price} />
            </GridItem>
            <GridItem colSpan={2}>
              <Textarea
                error={errors.description}
                label="Description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                rows={5}
              />
            </GridItem>
          </SimpleGrid>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default MerchandiseForm
