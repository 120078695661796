import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AnalyticsQuickTipApi from 'src/api/analyticsQuickTip'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IAnalyticsQuickTip, analyticsQuickTipInitialValue } from 'src/types/AnalyticsQuickTip'
import validationSchema from 'src/views/AnalyticsQuickTip/validationSchema'
import AnalyticsQuickTipForm from './AnalyticsQuickTipForm'

const CreateAnalyticsQuickTip: React.FC = () => {
  const pageName: string = 'Create Quick Tip'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IAnalyticsQuickTip) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AnalyticsQuickTipApi.create({ ...values } as IAnalyticsQuickTip)
      .then((result: IAnalyticsQuickTip) => {
        if (result.id) {
          AlertSuccess('Analytics Quick Tip saved successfully!')
          navigate('/app/analytics-quick-tip')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: analyticsQuickTipInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <AnalyticsQuickTipForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreateAnalyticsQuickTip
