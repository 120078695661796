import { GridItem, SimpleGrid, Text } from '@chakra-ui/react'
import { formatDateToString } from 'src/helpers/stringHelper'
import { IAddress } from 'src/types/Address'
import IOrder from 'src/types/Order'
import { DataTextField } from '../Form/DataTextField'
import { FormCard } from './FormCard'
import { OrderStatusColumn } from './OrderStatusColumn'

interface OrderDetailsCardProps {
  order: IOrder
}

export function OrderDetailsCard({ order }: OrderDetailsCardProps) {
  return (
    <FormCard title="Order Details">
      <SimpleGrid columns={[1, 1, 3]} spacing={4}>
        <GridItem>
          <DataTextField title="Code">{order.code}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Customer">{order.completeName}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Store">
            {order.grocer ? `${order.grocer.businessName} - ${(order.grocer.address as IAddress).suburb}` : '-'}
          </DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Order Date">{order.createdAt ? formatDateToString(order.createdAt) : '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Status">
            <Text>
              <OrderStatusColumn status={order?.status} />
            </Text>
          </DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Pickup Date">
            {order.pickupTime ? (
              <>
                From: {formatDateToString(order.pickupTime.from, { month: '2-digit', hour: '2-digit', minute: '2-digit' })}
                <br />
                To: {formatDateToString(order.pickupTime.to, { month: '2-digit', hour: '2-digit', minute: '2-digit' })}
              </>
            ) : (
              '-'
            )}
          </DataTextField>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}
