import { Box } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import SettingsApi from 'src/api/settings'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ISettings, settingsInitialValue } from 'src/types/Settings'
import SettingsForm from './SettingsForm'
import validationSchema from './validationSchema'

const Settings = () => {
  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: ISettings) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    SettingsApi.update({ isAutoPaymentOn: values.isAutoPaymentOn, isAutoPaymentCharityOn: values.isAutoPaymentCharityOn })
      .then(() => {
        AlertSuccess('Settings saved successfully!')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: settingsInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    SettingsApi.get()
      .then((data: ISettings) => {
        setValues(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setValues])

  return (
    <Page title="Settings">
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title="Settings" buttons={
          <PrimaryButton type="submit">Save</PrimaryButton>
        } />
        <Box mt={3}>
          <SettingsForm formik={formik} />
        </Box>
      </form>
    </Page>
  )
}

export default Settings
