import { TableColumn } from 'src/components/CustomDataTable'
import IUser from 'src/types/User'

const tableColumns: TableColumn<IUser>[] = [
  {
    label: 'First Name',
    name: 'firstName',
  },
  {
    label: 'Last Name',
    name: 'lastName',
  },
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
  },
  {
    label: 'Location',
    name: 'address',
    options: {
      customRender: (user: IUser) => {
        console.log(user)
        return `${user['address.suburb']}, ${user['address.state']}`
      }
    }
  },
  {
    label: 'Active',
    name: 'active',
    isBoolean: true,
  },
]

export default tableColumns
