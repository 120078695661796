export interface IAppVersion {
  _id?: string
  os: string
  version: number
  isForBusinessApp?: boolean
  isRequired: boolean

  [key: string]: any
}

export const appVersionInitialValue: IAppVersion = {
  os: '',
  version: 0,
  isForBusinessApp: false,
  isRequired: false,
}
