import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { IAppVersion } from 'src/types/AppVersion'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { api } from '.'

type IAppVersionApi = {
  get(params: PaginationData<IAppVersion>): Promise<PaginationResponse<IAppVersion>>
  getById(id: string): Promise<IAppVersion>
  create(pb: IAppVersion): Promise<{ success: boolean }>
  update(pb: IAppVersion): Promise<{ success: boolean }>
}

const AppVersionApi = (): IAppVersionApi => {
  const appVersionApi = '/admin/app-version'

  const get = (params: PaginationData<IAppVersion>): Promise<PaginationResponse<IAppVersion>> =>
    api.get(`${appVersionApi}${generateParamsFromPagination(params)}`)
  const getById = (id: string): Promise<IAppVersion> => api.get(`${appVersionApi}/${id}`)
  const create = (pb: IAppVersion): Promise<{ success: boolean }> => api.post(appVersionApi, pb)
  const update = ({ id, ...rest }: IAppVersion): Promise<{ success: boolean }> => api.patch(`${appVersionApi}/${id}`, rest)

  return {
    get,
    getById,
    create,
    update,
  }
}

export default AppVersionApi()
