import { Box, Checkbox, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { IModelId } from 'src/types/Model'
import { CustomDataTableProps, TableColumn } from '.'
import { BooleanColumn } from './BooleanColumn'
import { CustomDataTableActionButtons } from './CustomDataTableActionButtons'

interface CustomDataTableBodyProps<T extends IModelId>
  extends Pick<CustomDataTableProps<T>, 'onRowClick' | 'showCheckbox' | 'onEditClick' | 'onViewClick' | 'serverSidePagination' | 'data'> {
  tableColumns: TableColumn<T>[]
  tableData: T[]
  handleToggleCheckbox: (index: number) => void
  singleSelection?: boolean
  selectedRows: { index: number; selected: boolean }[]
}

export function CustomDataTableBody<T extends IModelId>(props: CustomDataTableBodyProps<T>) {
  const hasRowSelected = props.selectedRows.some((row) => row.selected)

  return (
    <Tbody>
      {props.tableData.map((item, index: number) => (
        <Tr
          key={item.id || index}
          onClick={() => props.onRowClick && props.onRowClick(index)}
          cursor={props.onRowClick ? 'pointer' : 'normal'}
          _hover={{ bgColor: 'gray.100' }}
          bgColor="transparent"
        >
          {props.showCheckbox && (
            <Td px={['4', '4', '6']}>
              <Checkbox
                borderColor="black"
                onChange={() => props.handleToggleCheckbox(index)}
                isChecked={props.selectedRows[index]?.selected}
                checked={props.selectedRows[index]?.selected}
                defaultChecked={props.selectedRows[index]?.selected}
                disabled={props.singleSelection && !props.selectedRows[index]?.selected && hasRowSelected}
              />
            </Td>
          )}
          {props.tableData.length > 0 &&
            props.tableColumns.map((column) => (
              <Td key={`${column.name}${item.id ?? item._id}`}>
                <Box>
                  {column.isBoolean ? (
                    <BooleanColumn data={item[column.name] as boolean} />
                  ) : column.options?.customRender ? (
                    <Text>{column.options.customRender(props.tableData[index])}</Text>
                  ) : (
                    <Text>{item[column.name] && item[column.name].length > 25 ? `${item[column.name].substring(0, 25)}...` : item[column.name]}</Text>
                  )}
                </Box>
              </Td>
            ))}
          <CustomDataTableActionButtons rowIndex={index} onEditClick={props.onEditClick} onViewClick={props.onViewClick} />
        </Tr>
      ))}
    </Tbody>
  )
}
