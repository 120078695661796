import BroadcastActionEnum from 'src/enum/BroadcastAction.enum'
import BroadcastStatusEnum from 'src/enum/BroadcastStatus.enum'

export interface IBroadcasting {
  id?: string
  title: string
  content: string
  status?: BroadcastStatusEnum
  location?: string
  placeId?: string
  action?: BroadcastActionEnum
  successCount?: number
  failureCount?: number
  clickCount?: number
  grocerId?: string
  bannerId?: string
  promotionId?: string
  createdAt?: Date

  [key: string]: any
}

export const broadcastingInitialValue: IBroadcasting = {
  title: '',
  content: '',
}
