import { Box, Text, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CharityApi from 'src/api/charity'
import EmailLogApi from 'src/api/emailLog'
import OrderApi from 'src/api/order'
import UserApi from 'src/api/user'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableProps, CustomDataTableState } from 'src/components/CustomDataTable'
import { MultiSelectOptionProps } from 'src/components/Form/MultiSelect'
import Toolbar from 'src/components/Layout/Toolbar'
import CreditsModal from 'src/components/Overlay/CreditsModal'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import IEmailLog from 'src/types/EmailLog'
import IOrder from 'src/types/Order'
import { PaginationResponse } from 'src/types/Pagination'
import IUser, { userInitialValue } from 'src/types/User'
import { UserForm } from './UserForm'
import customerEmailsTableColumns from './customerEmailsTableColumns'
import customerInviteesTableColumns from './customerInviteesTableColumns'
import customerOrdersTableColumns from './customerOrdersTableColumns'

const ViewCustomer = () => {
  const pageName = 'View Customer'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()
  const creditsDisclosure = useDisclosure()
  const [user, setUser] = React.useState<IUser>(userInitialValue)
  const [charities, setCharities] = React.useState<MultiSelectOptionProps[]>([])
  const [orders, setOrders] = React.useState<PaginationResponse<IOrder>>({ count: 0, data: [] })
  const [orderTableState, setOrderTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })
  const [emailLogs, setEmailLogs] = React.useState<PaginationResponse<IEmailLog>>({ count: 0, data: [] })
  const [emailLogTableState, setEmailLogTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    UserApi.getById(params.id as string)
      .then((res) => setUser(res))
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id])

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    OrderApi.listByCustomer(params.id as string, generatePaginationData(orderTableState))
      .then((res: PaginationResponse<IOrder>) => res && setOrders(res))
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, orderTableState])

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    EmailLogApi.listByCustomer(params.id as string, generatePaginationData(orderTableState))
      .then((res: PaginationResponse<IEmailLog>) => res && setEmailLogs(res))
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, orderTableState])

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    CharityApi.getAllowedToReceiveBags({ limit: 100, skip: 0 })
      .then((res) => {
        let charityList = res.data?.map((charity) => ({ label: charity.name, value: charity.id })) ?? []
        charityList.unshift({ label: "Select one", value: undefined })
        setCharities(charityList)

      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch])

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setOrderTableState(newTableState)
  }

  const onEmailLogTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setEmailLogTableState(newTableState)
  }

  const dataTableProps: CustomDataTableProps<IOrder> = {
    tableColumns: customerOrdersTableColumns,
    data: orders,
    onViewClick: (rowIndex: number) => navigate(`/app/orders/${orders.data[rowIndex]._id}`),
    onTableChange,
    tableState: orderTableState,
  }

  const dataTableInvitedUsersProps: CustomDataTableProps<IUser> = {
    tableColumns: customerInviteesTableColumns,
    data: user?.invitedUsers || [],
    onRowClick: (rowIndex: number) => navigate(`/app/customers/${user.invitedUsers[rowIndex]._id}`),
    onTableChange,
    tableState: orderTableState,
  }

  const dataTableEmailsReceivedProps: CustomDataTableProps<IEmailLog> = {
    tableColumns: customerEmailsTableColumns,
    data: emailLogs,
    onTableChange: onEmailLogTableChange,
    tableState: emailLogTableState,
  }

  const handleSaveCredits = (credits: string) => {
    const formattedCredits = parseFloat(credits)

    dispatch({ type: Types.Loading, payload: { loading: true } })
    UserApi.updateCustomer(user.id ?? user._id, { creditBalance: formattedCredits } as IUser)
      .then((result) => {
        if (result?.success) AlertSuccess('Credits added successfully')
        setUser({ ...user, creditBalance: (user.creditBalance ?? 0) + formattedCredits })
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  return (
    <Page title={pageName}>
      <Toolbar title={pageName} showBack={true} buttons={
        <PrimaryButton type="button" onClick={creditsDisclosure.onOpen}>Add Credits</PrimaryButton>
      } />
      <UserForm user={user} charities={charities} />
      <Box mt={3}>
        <Text fontSize="xl" fontWeight="bold">
          Invited Users
        </Text>
        <CustomDataTable {...dataTableInvitedUsersProps} />
      </Box>
      <Box mt={3}>
        <Text fontSize="xl" fontWeight="bold">
          Orders
        </Text>
        <CustomDataTable serverSidePagination {...dataTableProps} />
      </Box>
      <Box mt={3}>
        <Text fontSize="xl" fontWeight="bold">
          Emails received
        </Text>
        <CustomDataTable serverSidePagination {...dataTableEmailsReceivedProps} />
      </Box>
      <CreditsModal title="Add Credits" open={creditsDisclosure.isOpen} handleClose={creditsDisclosure.onClose} handleConfirm={handleSaveCredits} />
    </Page>
  )
}

export default ViewCustomer
