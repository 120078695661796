import { IAddress } from './Address'

export interface IPromotionalBanner {
  _id?: string
  title: string
  subtitle?: string
  description: string
  picture: string
  placeId?: string
  location?: IAddress
  locationDescription?: string
  pictureFile?: File

  [key: string]: any
}

export const promotionalBannerInitialValue: IPromotionalBanner = {
  title: '',
  subtitle: '',
  description: '',
  picture: '',
  placeId: '',
}
