import * as Yup from 'yup'

export default Yup.object().shape({
  title: Yup.string().required('Title is required').max(100),
  content: Yup.string().required('Content is required').max(1000),
  location: Yup.string().optional(),
  placeId: Yup.string().when('location', {
    is: (location?: string) => !!location,
    then: (schema) => schema.required('Please type in the address and select from the dropdown list.'),
  }),
  action: Yup.string().required('Action is required'),
  grocerId: Yup.string().when('action', {
    is: 'STORE',
    then: (schema) => schema.required('Store is required'),
  }),
  bannerId: Yup.string().when('action', {
    is: 'BANNER',
    then: (schema) => schema.required('Banner is required'),
  }),
})
