import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Input } from 'src/components/Form/Input'
import { MultiSelect } from 'src/components/Form/MultiSelect'
import { FormCard } from 'src/components/Layout/FormCard'
import { ANALYTICS_TRIGGER_FOR_SELECT } from 'src/constants'
import { IAnalyticsQuickTip } from 'src/types/AnalyticsQuickTip'
import { Formik } from 'src/types/Formik'

const AnalyticsQuickTipForm: React.FC<{ formik: Formik<IAnalyticsQuickTip> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="AnalyticsQuickTip Details">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <Input error={errors.message} label="Message" name="message" onBlur={handleBlur} onChange={handleChange} value={values.message} />
        </GridItem>
        <GridItem>
          <MultiSelect
            label="Trigger"
            name="trigger"
            isMulti={false}
            error={errors.trigger}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.trigger || []}
            options={ANALYTICS_TRIGGER_FOR_SELECT}
          />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default AnalyticsQuickTipForm
