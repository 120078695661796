import { Box, Center, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { IStore } from 'src/types/User'
import { PrimaryButton } from '../Button/PrimaryButton'

interface StorePaymentCardProps {
  store: IStore
  onTransferClick: (store: IStore, displayConfirmation: boolean) => void
}

export function StorePaymentCard({ store, onTransferClick }: StorePaymentCardProps) {
  return (
    <Flex w="100%" maxW="20.9375rem" direction="column" borderRadius="xl" overflow="hidden" boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)">
      <Center objectFit="cover" w="100%" h="9.375rem" overflow="hidden">
        <Image src={store.businessLogo} alt={store.name} minW="100%" minH="100%" />
      </Center>
      <Box p=".5rem .75rem">
        <Text variant="text-20-bold">{store.businessName}</Text>
        <Text color="gray.600">{store.suburb}</Text>
        <Text color="gray.600">Code: {store.code}</Text>
        <Divider my="2" />
        <Flex justify="space-between">
          <Box>
            <Text color="gray.600">Number of orders: {store.ordersQuantity}</Text>
            <Text color="gray.600">Total: ${(store.total / 100).toFixed(2)}</Text>
          </Box>
          <PrimaryButton name={store._id} onClick={() => onTransferClick(store, true)} isDisabled={store.total / 100 <= 0}>
            Pay Store
          </PrimaryButton>
        </Flex>
      </Box>
    </Flex>
  )
}
