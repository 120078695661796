import { generateMultipartBodyForm } from 'src/helpers/ApiHelper'
import { IAccountManager } from 'src/types/AccountManager'
import { api } from '.'

type IAccountManagerApi = {
  get(): Promise<IAccountManager[]>
  getById(id: string): Promise<IAccountManager>
  create(pb: IAccountManager): Promise<{ success: boolean }>
  update(pb: IAccountManager): Promise<{ success: boolean }>
}

const AccountManagerApi = (): IAccountManagerApi => {
  const accountManagerApi = '/admin/account-manager'

  const get = (): Promise<IAccountManager[]> => api.get(accountManagerApi)
  const create = (pb: IAccountManager): Promise<{ success: boolean }> =>
    api.post(accountManagerApi, generateMultipartBodyForm(pb), { headers: { 'Content-Type': 'multipart/form-data' } })
  const update = (pb: IAccountManager): Promise<{ success: boolean }> =>
    api.patch(`${accountManagerApi}/${pb.id}`, generateMultipartBodyForm({ ...pb, id: undefined }), { headers: { 'Content-Type': 'multipart/form-data' } })
  const getById = (id: string): Promise<IAccountManager> => api.get(`${accountManagerApi}/${id}`)

  return {
    get,
    getById,
    create,
    update,
  }
}

export default AccountManagerApi()
