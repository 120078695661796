import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import AmbassadorApi from 'src/api/ambassador'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import ConfirmationWithReasonModal from 'src/components/Overlay/ConfirmationWithReasonModal'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IAmbassador } from 'src/types/Ambassador'
import { AmbassadorForm } from './AmbassadorForm'

const ViewAmbassador = () => {
  const pageName = 'View Ambassador'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const [ambassador, setAmbassador] = React.useState<IAmbassador>()
  const disclosure = useDisclosure()

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AmbassadorApi.getById(params.id as string)
      .then((res) => setAmbassador(res))
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id])

  const handleApprove = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AmbassadorApi.approve(params.id as string)
      .then((res) => {
        if (res?.success) {
          setAmbassador({ ...ambassador, isApproved: true })
          AlertSuccess('Ambassador approved successfully')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleBan = (reason: string) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AmbassadorApi.ban(params.id as string, reason)
      .then((res) => {
        if (res?.success) {
          setAmbassador({ ...ambassador, isBlocked: true })
          AlertSuccess('Ambassador banned successfully')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  return (
    <Page title={pageName}>
      <Toolbar
        title={pageName}
        showBack={true}
        buttons={
          <Flex gap={2}>
            {
              !ambassador?.isApproved && (
                <Button variant="solid" colorScheme="green" fontSize="sm" onClick={handleApprove}>
                  Approve
                </Button>
              )
            }
            {
              !ambassador?.isBlocked && (
                <PrimaryButton variant="solid" onClick={disclosure.onOpen}>
                  Ban
                </PrimaryButton>
              )
            }
          </Flex>
        }
      />
      <AmbassadorForm ambassador={ambassador} />
      <ConfirmationWithReasonModal
        title="Ban Ambassador"
        open={disclosure.isOpen}
        handleClose={disclosure.onClose}
        handleConfirm={handleBan}
      />
    </Page>
  )
}

export default ViewAmbassador
