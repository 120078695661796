import { Navigate } from 'react-router-dom'
import DashboardLayout from './layouts/DashboardLayout'
import MainLayout from './layouts/MainLayout'
import AccountManager from './views/AccountManager'
import CreateAccountManager from './views/AccountManager/CreateAccountManager'
import EditAccountManager from './views/AccountManager/EditAccountManager'
import Ambassador from './views/Ambassador'
import ViewAmbassador from './views/Ambassador/ViewAmbassador'
import AnalyticsQuickTip from './views/AnalyticsQuickTip'
import CreateAnalyticsQuickTip from './views/AnalyticsQuickTip/CreateAnalyticsQuickTip'
import EditAnalyticsQuickTip from './views/AnalyticsQuickTip/EditAnalyticsQuickTip'
import AppLink from './views/AppLink'
import AppVersion from './views/AppVersion'
import CreateAppVersion from './views/AppVersion/CreateAppVersion'
import EditAppVersion from './views/AppVersion/EditAppVersion'
import Broadcasting from './views/Broadcasting'
import CreateBroadcasting from './views/Broadcasting/CreateBroadcasting'
import BusinessFaq from './views/BusinessFaq'
import CreateBusinessFaq from './views/BusinessFaq/CreateBusinessFaq'
import EditBusinessFaq from './views/BusinessFaq/EditBusinessFaq'
import BusinessOwner from './views/BusinessOwner'
import ViewBusinessOwner from './views/BusinessOwner/ViewBusinessOwner'
import BusinessSuggestion from './views/BusinessSuggestion'
import EditBusinessSuggestion from './views/BusinessSuggestion/EditBusinessSuggestion'
import Charity from './views/Charity'
import CreateCharity from './views/Charity/CreateCharity'
import EditCharity from './views/Charity/EditCharity'
import Customer from './views/Customer'
import ViewCustomer from './views/Customer/ViewCustomer'
import Dashboard from './views/Dashboard'
import Faq from './views/Faq'
import CreateFaq from './views/Faq/CreateFaq'
import EditFaq from './views/Faq/EditFaq'
import ForgotPassword from './views/ForgotPassword'
import Login from './views/Login'
import Merchandise from './views/Merchandise'
import CreateMerchandise from './views/Merchandise/CreateMerchandise'
import EditMerchandise from './views/Merchandise/EditMerchandise'
import Mfa from './views/Mfa'
import NotFound from './views/NotFound'
import Order from './views/Order'
import ViewOrder from './views/Order/ViewOrder'
import PendingPayments from './views/PendingPayments'
import Promotion from './views/Promotion'
import CreatePromotion from './views/Promotion/CreatePromotion'
import EditPromotion from './views/Promotion/EditPromotion'
import PromotionalBanner from './views/PromotionalBanner'
import CreatePromotionalBanner from './views/PromotionalBanner/CreateBanner'
import EditPromotionalBanner from './views/PromotionalBanner/EditPromotionalBanner'
import UserReports from './views/Reports/CustomerReports'
import StoreReports from './views/Reports/StoreReports'
import ResetPassword from './views/ResetPassword'
import ReviewHighlight from './views/ReviewHighlight'
import CreateReviewHighlight from './views/ReviewHighlight/CreateReviewHighlight'
import EditReviewHighlight from './views/ReviewHighlight/EditReviewHighlight'
import ReviewReplySuggestion from './views/ReviewReplySuggestion'
import CreateReviewReplySuggestion from './views/ReviewReplySuggestion/CreateReviewReplySuggestion'
import EditReviewReplySuggestion from './views/ReviewReplySuggestion/EditReviewReplySuggestion'
import ReviewReported from './views/ReviewReported'
import Settings from './views/Settings'
import Store from './views/Store'
import ViewStore from './views/Store/ViewStore'
import SuggestionReason from './views/SuggestionReason'
import CreateSuggestionReason from './views/SuggestionReason/CreateSuggestionReason'
import EditSuggestionReason from './views/SuggestionReason/EditSuggestionReason'
import Survey from './views/Survey'
import CreateSurvey from './views/Survey/CreateSurvey'
import EditSurvey from './views/Survey/EditSurvey'
import Voucher from './views/Voucher'
import CreateVoucher from './views/Voucher/CreateVoucher'
import EditVoucher from './views/Voucher/EditVoucher'
import VoucherAmountSuggestion from './views/VoucherAmountSuggestion'
import CreateVoucherAmountSuggestion from './views/VoucherAmountSuggestion/CreateVoucherAmountSuggestion'
import EditVoucherAmountSuggestion from './views/VoucherAmountSuggestion/EditVoucherAmountSuggestion'

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },

      { path: 'users/:id', element: <ViewStore /> },

      { path: 'broadcasting', element: <Broadcasting /> },
      { path: 'broadcasting/new', element: <CreateBroadcasting /> },

      // ------------- Customers -------------
      { path: 'customers', element: <Customer /> },
      { path: 'customers/:id', element: <ViewCustomer /> },

      { path: 'orders', element: <Order /> },
      { path: 'orders/:id', element: <ViewOrder /> },

      // ------------- STORES -------------

      { path: 'partners', element: <AccountManager /> },
      { path: 'partners/new', element: <CreateAccountManager /> },
      { path: 'partners/:id', element: <EditAccountManager /> },

      { path: 'business-suggestion', element: <BusinessSuggestion /> },
      { path: 'business-suggestion/:id', element: <EditBusinessSuggestion /> },

      { path: 'stores', element: <Store /> },
      { path: 'stores/:id', element: <ViewStore /> },

      { path: 'pending-payments', element: <PendingPayments /> },

      { path: 'business-owner', element: <BusinessOwner /> },
      { path: 'business-owner/:id', element: <ViewBusinessOwner /> },

      { path: 'ambassadors', element: <Ambassador /> },
      { path: 'ambassadors/:id', element: <ViewAmbassador /> },

      { path: 'beneficiaries', element: <Charity /> },
      { path: 'beneficiaries/new', element: <CreateCharity /> },
      { path: 'beneficiaries/:id', element: <EditCharity /> },

      // ------------- GENERAL SETTINGS -------------
      { path: 'faq', element: <Faq /> },
      { path: 'faq/new', element: <CreateFaq /> },
      { path: 'faq/:id', element: <EditFaq /> },

      { path: 'surveys', element: <Survey /> },
      { path: 'surveys/new', element: <CreateSurvey /> },
      { path: 'surveys/:id', element: <EditSurvey /> },

      { path: 'analytics-quick-tip', element: <AnalyticsQuickTip /> },
      { path: 'analytics-quick-tip/new', element: <CreateAnalyticsQuickTip /> },
      { path: 'analytics-quick-tip/:id', element: <EditAnalyticsQuickTip /> },

      { path: 'business-faq', element: <BusinessFaq /> },
      { path: 'business-faq/new', element: <CreateBusinessFaq /> },
      { path: 'business-faq/:id', element: <EditBusinessFaq /> },

      { path: 'promotional-banner', element: <PromotionalBanner /> },
      { path: 'promotional-banner/new', element: <CreatePromotionalBanner /> },
      { path: 'promotional-banner/:id', element: <EditPromotionalBanner /> },

      { path: 'promotions', element: <Promotion /> },
      { path: 'promotions/new', element: <CreatePromotion /> },
      { path: 'promotions/:id', element: <EditPromotion /> },

      { path: 'app-version', element: <AppVersion /> },
      { path: 'app-version/new', element: <CreateAppVersion /> },
      { path: 'app-version/:id', element: <EditAppVersion /> },

      { path: 'suggestion-reason', element: <SuggestionReason /> },
      { path: 'suggestion-reason/new', element: <CreateSuggestionReason /> },
      { path: 'suggestion-reason/:id', element: <EditSuggestionReason /> },

      { path: 'merchandise', element: <Merchandise /> },
      { path: 'merchandise/new', element: <CreateMerchandise /> },
      { path: 'merchandise/:id', element: <EditMerchandise /> },

      { path: 'app-links', element: <AppLink /> },

      // ------------- REVIEW -------------

      { path: 'review-highlight', element: <ReviewHighlight /> },
      { path: 'review-highlight/new', element: <CreateReviewHighlight /> },
      { path: 'review-highlight/:id', element: <EditReviewHighlight /> },

      { path: 'review-reply-suggestion', element: <ReviewReplySuggestion /> },
      { path: 'review-reply-suggestion/new', element: <CreateReviewReplySuggestion /> },
      { path: 'review-reply-suggestion/:id', element: <EditReviewReplySuggestion /> },

      { path: 'review-reported', element: <ReviewReported /> },

      // ------------- VOUCHER -------------
      { path: 'voucher', element: <Voucher /> },
      { path: 'voucher/new', element: <CreateVoucher /> },
      { path: 'voucher/:id', element: <EditVoucher /> },

      { path: 'voucher-amount-suggestion', element: <VoucherAmountSuggestion /> },
      { path: 'voucher-amount-suggestion/new', element: <CreateVoucherAmountSuggestion /> },
      { path: 'voucher-amount-suggestion/:id', element: <EditVoucherAmountSuggestion /> },

      // ------------- REPORTS -------------
      { path: 'store-report', element: <StoreReports /> },
      { path: 'user-report', element: <UserReports /> },

      // ------------- SETTINGS -------------
      { path: 'settings', element: <Settings /> },

      { path: '*', element: <NotFound /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'mfa', element: <Mfa /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      { path: '404', element: <NotFound /> },
      // { path: '/', element: <Navigate to="/login" /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
]

export default routes
