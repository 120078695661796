import { IUploadFile } from './UploadFile'

export interface IAccountManager {
  id?: string
  code?: string
  firstName: string
  lastName: string
  companyName?: string
  email: string
  phoneNumber: string
  picture?: string
  pictureFile?: IUploadFile
  termsAndConditions?: string
  termsAndConditionsFile?: IUploadFile
  trialDurationInDays?: number
  isActive?: boolean

  [key: string]: any
}

export const accountManagerInitialValue: IAccountManager = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}
