import * as Yup from 'yup'

export default Yup.object().shape({
  name: Yup.string().required('Name is required'),
  websiteUrl: Yup.string().required('Website is required'),
  abn: Yup.string().required('ABN is required'),
  legalName: Yup.string().required('Please, type a valid ABN'),
  locationDescription: Yup.string().required('Address is required'),
  pictureFile: Yup.mixed().optional(),
  description: Yup.string().required('Description is required'),
})
