import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AccountManagerApi from 'src/api/accountManager'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IAccountManager, accountManagerInitialValue } from 'src/types/AccountManager'
import validationSchema from 'src/views/AccountManager/validationSchema'
import AccountManagerForm from './AccountManagerForm'

const EditAccountManager = () => {
  const pageName: string = 'Edit Partner'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = (values: IAccountManager) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AccountManagerApi.update({
      id: values._id ?? params.id,
      code: values.code,
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      email: values.email,
      phoneNumber: String(values.phoneNumber),
      trialDurationInDays: values.trialDurationInDays,
      pictureFile: values.pictureFile,
      termsAndConditionsFile: values.termsAndConditionsFile,
    })
      .then((response) => {
        if (response?.success) {
          AlertSuccess('Partner saved successfully!')
          navigate('/app/partners')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: accountManagerInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AccountManagerApi.getById(params.id as string)
      .then((res: IAccountManager) => {
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <AccountManagerForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditAccountManager
