import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SuggestionReasonApi from 'src/api/suggestionReason'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ISuggestionReason, suggestionReasonInitialValue } from 'src/types/SuggestionReason'
import validationSchema from 'src/views/SuggestionReason/validationSchema'
import SuggestionReasonForm from './SuggestionReasonForm'

const EditSuggestionReason = () => {
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = (values: ISuggestionReason) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    SuggestionReasonApi.update({ ...values, id: undefined, _id: values.id })
      .then(() => {
        AlertSuccess('Reason saved successfully!')
        navigate('/app/suggestion-reason')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: suggestionReasonInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues, handleSubmit } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    SuggestionReasonApi.getById(params.id as string)
      .then((res: ISuggestionReason) => {
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title="Edit Suggestion Reason">
      <form onSubmit={handleSubmit}>
        <Toolbar
          title="Edit Suggestion Reason"
          showBack
          buttons={
            <PrimaryButton variant="solid" type="submit">
              Save
            </PrimaryButton>
          }
        />
        <SuggestionReasonForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditSuggestionReason
