import IUser from './User'

export type IAuthMFA = {
  passcode: string
  email: string
}

export type IAuth = {
  code?: string
  email?: string
  password: string
  confirmPassword?: string
  resetPasswordToken?: string
}

export type IAuthResponse = {
  qrCode?: string
  success?: boolean
  token: string
  refreshToken: string
  user: IUser
}

export const AuthMfaInitialValue: IAuthMFA = {
  email: '',
  passcode: '',
}

export const AuthInitialValue: IAuth = {
  email: '',
  confirmPassword: '',
  password: '',
  resetPasswordToken: '',
}
