enum OrderStatusEnum {
  PREPARING = 'Preparing',
  PENDING_REFUND = 'Pending Refund',
  READY = 'Ready',
  COLLECTED = 'Collected',
  CANCELLED = 'Cancelled',
  MISSED_COLLECTION = 'Missed Collection',
}

export default OrderStatusEnum
