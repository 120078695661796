import IUser from './User'

export interface IBusinessSuggestion {
  _id?: string
  id?: string
  businessName: string
  managerName: string
  managerEmail?: string
  reasons?: string[]
  referrerId?: string
  isPaid?: boolean
  isEmailSent?: boolean

  referrer?: IUser
}

export const businessSuggestionInitialValue: IBusinessSuggestion = {
  businessName: '',
  managerName: '',
}
