import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PromotionApi from 'src/api/promotion'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IPromotion, promotionInitialValue } from 'src/types/Promotion'
import validationSchema from 'src/views/Promotion/validationSchema'
import PromotionForm from './PromotionForm'

const CreatePromotion: React.FC = () => {
  const pageName: string = 'Create Promotion'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IPromotion) => {
    if (!values.iconFile) {
      AlertError('Icon is required')
      return
    }
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionApi.create({
      slug: values.slug,
      iconFile: values.iconFile,
      title: values.title,
      subtitle: values.subtitle,
      summary: values.summary,
      colors: values.colors,
      detailsTitle: values.detailsTitle,
      details: values.details,
      placeId: values.placeId,
      isForEmail: values.isForEmail
    } as IPromotion)
      .then((result) => {
        if (result.id) {
          AlertSuccess('Promotion saved successfully!')
          navigate('/app/promotions')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: promotionInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <PromotionForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreatePromotion
