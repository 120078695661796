import { Box, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import { Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AuthApi from 'src/api/auth'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { Input } from 'src/components/Form/Input'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { AuthInitialValue } from 'src/types/Auth'
import validationSchema from './validationSchema'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const { dispatch } = React.useContext(AppContext)
  const [success, setSuccess] = React.useState<boolean>(false)

  const onSubmit = React.useCallback(
    (data: any) => {
      dispatch({ type: Types.Loading, payload: { loading: true } })

      AuthApi.forgotPassword({ email: data.email })
        .then(() => {
          setSuccess(true)
        })
        .catch((err: Error) => {
          console.log(err)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    },
    [dispatch]
  )

  const goToLogin = () => {
    navigate('/login')
  }

  return (
    <Page title="Forgot Password">
      <Flex flexDirection="column" height="100%" justifyContent="center">
        {!success ? (
          <Formik initialValues={AuthInitialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, handleBlur, handleChange, handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <VStack gap={3} mb={3}>
                  <Heading variant="h3">Forgot Password</Heading>
                  <Text>Please enter your email. A link will then be sent to you.</Text>
                </VStack>

                <Input error={errors.email} label="Email" name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} />

                <Box mt={3}>
                  <PrimaryButton type="submit">Request password</PrimaryButton>
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <>
            <Box mt={3} mb={1}>
              <Heading variant="h3">Forgot Password</Heading>
              <Text>Please check your email and click on the provided link to reset your password </Text>
            </Box>

            <Box my={2}>
              <PrimaryButton onClick={goToLogin}>Go to Login</PrimaryButton>
            </Box>
          </>
        )}
      </Flex>
    </Page>
  )
}

export default ForgotPassword
