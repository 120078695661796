import React from 'react'
import { useNavigate } from 'react-router-dom'
import AccountManagerApi from 'src/api/accountManager'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { IAccountManager } from 'src/types/AccountManager'
import tableColumns from './tableColumns'

const AccountManager = () => {
  const navigate = useNavigate()
  const [reasonList, setAccountManagerList] = React.useState<IAccountManager[]>([])
  const { dispatch } = React.useContext(AppContext)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AccountManagerApi.get()
      .then((data: IAccountManager[]) => {
        setAccountManagerList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setAccountManagerList])

  const handleClick = (rowIndex: number) => navigate(reasonList[rowIndex].id || reasonList[rowIndex]._id)
  const handleAdd = () => navigate('new')

  return (
    <Page title="Partners">
      <Toolbar title="Partners" buttons={<PrimaryButton onClick={handleAdd}>Add Partner</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable onEditClick={handleClick} data={reasonList || []} tableColumns={tableColumns} />
      </Box>
    </Page>
  )
}

export default AccountManager
