import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReviewHighlightApi from 'src/api/reviewHighlight'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IReviewHighlight, reviewHighlightInitialValue } from 'src/types/ReviewHighlight'
import validationSchema from 'src/views/ReviewHighlight/validationSchema'
import ReviewHighlightForm from './ReviewHighlightForm'

const CreateReviewHighlight: React.FC = () => {
  const pageName: string = 'Create Review Highlight'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IReviewHighlight) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ReviewHighlightApi.create({ name: values.name, pictureFile: values.pictureFile })
      .then(() => {
        AlertSuccess('Highlight saved successfully!')
        navigate('/app/review-highlight')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: reviewHighlightInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar
          title={pageName}
          showBack
          buttons={
            <PrimaryButton variant="solid" type="submit">
              Save
            </PrimaryButton>
          }
        />
        <ReviewHighlightForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreateReviewHighlight
