import { IReviewReplySuggestion } from 'src/types/ReviewReplySuggestion'
import { api } from '.'

type IReviewReplySuggestionApi = {
  get(): Promise<IReviewReplySuggestion[]>
  getById(id: string): Promise<IReviewReplySuggestion>
  changeStatus(idList: { idList: string[] }, activate: boolean): Promise<{ success: boolean }>
  create(suggestion: IReviewReplySuggestion): Promise<{ success: boolean }>
  update(suggestion: IReviewReplySuggestion): Promise<{ success: boolean }>
}

const ReviewReplySuggestionApi = (): IReviewReplySuggestionApi => {
  const suggestionApi = '/admin/review-reply-suggestion'

  const get = (): Promise<IReviewReplySuggestion[]> => api.get(suggestionApi)
  const create = (suggestion: IReviewReplySuggestion): Promise<{ success: boolean }> => api.post(suggestionApi, suggestion)
  const update = (suggestion: IReviewReplySuggestion): Promise<{ success: boolean }> => api.patch(`${suggestionApi}/${suggestion._id}`, suggestion)
  const getById = (id: string): Promise<IReviewReplySuggestion> => api.get(`${suggestionApi}/${id}`)
  const changeStatus = (idList: { idList: string[] }, activate: boolean): Promise<{ success: boolean }> =>
    api.patch(`${suggestionApi}/${activate ? 'activate' : 'deactivate'}`, idList)

  return {
    get,
    getById,
    changeStatus,
    create,
    update,
  }
}

export default ReviewReplySuggestionApi()
