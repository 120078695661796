import SurveyAnswerTypeEnum from 'src/enum/SurveyAnswerTypeEnum'
import SurveyTriggerEnum from 'src/enum/SurveyTriggerEnum'
import { enumKeys } from 'src/helpers/enumHelper'
import * as Yup from 'yup'

export default Yup.object().shape({
  title: Yup.string().required('Title is required'),
  question: Yup.string().required('Question is required'),
  storeIdList: Yup.array(Yup.string()),
  trigger: Yup.string().required('Trigger is required').oneOf(enumKeys(SurveyTriggerEnum)),
  answerType: Yup.string().required('Answer Type is required').oneOf(enumKeys(SurveyAnswerTypeEnum)),
  options: Yup.array().when('answerType', (answerType: any, schema: any) => {
    if (answerType === SurveyAnswerTypeEnum.TEXT) return schema.min(0)
    return schema.min(2).required('Options are required')
  })
})
