import { useBreakpointValue } from '@chakra-ui/react'
import { loadStripe } from '@stripe/stripe-js'
import React, { useCallback, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AlertWarning } from 'src/components/Alert'
import Loading from 'src/components/Loading'
import { AppActions, Types, appReducer } from 'src/reducers/AppReducer'
import AppContextInitialState from 'src/types/AppContextInitialState'
import { ReactComponent } from 'src/types/ReactComponent'
import { SidebarDrawerProvider } from './SidebarDrawerContext'

const initialState = {
  state: {
    isAuthenticated: false,
    token: null,
    user: null,
    loading: false,
    stripe: null
  },
  isMobile: false,
  dispatch: () => null,
}

const AppContext = React.createContext<{
  state: AppContextInitialState
  isMobile: boolean
  dispatch: React.Dispatch<AppActions>
}>(initialState)

export const AppProvider: React.FC<ReactComponent> = ({ children }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, 'lg') as boolean
  const [state, dispatch] = React.useReducer(appReducer, initialState.state)
  const navigate = useNavigate()

  const handleOnIdle = () => {
    if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
      dispatch({
        type: Types.Logout,
        payload: {},
      })

      AlertWarning("You've been disconnected due to inactivity", { autoClose: false })
      navigate('/login')
    }
  }

  // Change the last number to set the minutes
  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 min
    onIdle: handleOnIdle,
    debounce: 500,
  })

  const initStripe = useCallback(async () => {
    const stripeLoaded = await loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY || '')
    dispatch({ type: Types.SetStripe, payload: { stripe: stripeLoaded } })
  }, [])

  useEffect(() => {
    if (state.isAuthenticated && !state.stripe) initStripe()
  }, [state.isAuthenticated, state.stripe, initStripe])

  return (
    <AppContext.Provider value={{ state, isMobile, dispatch }}>
      <Loading useLoader={state.loading} />
      <ToastContainer
        position="bottom-left"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        bodyClassName="custom-toast"
        closeOnClick
        rtl={false}
        draggable
        pauseOnFocusLoss
        pauseOnHover
        transition={Zoom}
      />
      <SidebarDrawerProvider>{children}</SidebarDrawerProvider>
    </AppContext.Provider>
  )
}

export const AppConsumer = AppContext.Consumer
export default AppContext

export const useAppContext = () => React.useContext(AppContext)
