import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Input } from 'src/components/Form/Input'
import { MultiSelect } from 'src/components/Form/MultiSelect'
import { FormCard } from 'src/components/Layout/FormCard'
import { Formik } from 'src/types/Formik'
import { IStore } from 'src/types/User'
import { IVoucher } from 'src/types/Voucher'

const VoucherForm: React.FC<{ formik: Formik<IVoucher>; grocers: IStore[] }> = ({ formik, grocers }) => {
  const { errors, values, setFieldValue, handleBlur, handleChange } = formik

  React.useEffect(() => {
    setFieldValue('code', values.code?.toUpperCase() ?? null)
  }, [setFieldValue, values.code])

  return (
    <FormCard title="Voucher Details">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <Input error={errors.code} label="Code" name="code" onBlur={handleBlur} onChange={handleChange} value={values.code} />
        </GridItem>
        <GridItem>
          <Input
            error={errors.amount}
            label="Amount"
            name="amount"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.amount}
            type="number"
            isCurrency
          />
        </GridItem>
        <GridItem colSpan={2}>
          <MultiSelect
            label="Stores"
            name="grocers"
            error={errors.grocers}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.grocers || []}
            options={grocers.map((grocer: IStore) => ({
              value: grocer._id,
              label: `${grocer.businessName} - ${grocer?.suburb}`,
            }))}
          />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default VoucherForm
