import { theme as chakraTheme, extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

export const theme = extendTheme(withDefaultColorScheme({ colorScheme: 'primary' }), {
  initialColorMode: 'dark',
  colors: {
    primary: {
      '600': '#4A3DB4',
      '500': '#5546CF',
    },
    secondary: {
      '500': '#493CB2',
    },
    primaryAlpha: {
      '800': 'rgba(85,70,207,0.8)',
      '500': 'rgba(85,70,207,0.4)',
      '300': 'rgba(85,70,207,0.1)',
    },
  },
  fonts: {
    ...chakraTheme.fonts,
    heading:
      'Roboto,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    body: 'Roboto,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50',
        color: 'gray.900',
        fontSize: 'normal',
      },
    },
  },
  components: {
    Drawer: {
      variants: {
        dark: {
          dialog: {
            bg: 'gray.800',
            color: 'gray.50',
          },
        },
      },
    },
    Alert: {
      variants: {
        'left-accent': {
          container: {
            color: 'gray.100',
          },
        },
      },
    },
    Text: {
      variants: {
        'text-28-bold': {
          fontSize: ['1.75rem'], // 28px
          fontWeight: 'bold',
        },
        'text-20-bold': {
          fontSize: ['xl'], // 20px
          fontWeight: 'bold',
        },
        'text-18-bold': {
          fontSize: ['lg'], // 18px
          fontWeight: 'bold',
        },
        'text-18-semi': {
          fontSize: 'lg', // 18px
          fontWeight: 'semibold',
        },
        'text-18-med': {
          fontSize: ['lg'], // 18px
          fontWeight: 'medium',
        },
        'text-18-reg': {
          fontSize: ['lg'], // 18px
          lineHeight: ['7'], // 1.75rem (28px)
        },
        'text-16-semi': {
          fontSize: ['md'], // 16px
          fontWeight: 'semibold',
          lineHeight: ['1.875rem'], // 19px
        },
        'text-16-med': {
          fontSize: ['md'], // 16px
          fontWeight: 'medium',
          lineHeight: ['6'], // 24px
        },
        'text-16-reg': {
          fontSize: ['md'], // 16px
          lineHeight: ['6'], // 24px
        },
        'text-14-semi': {
          fontSize: ['sm'], // 14px
          fontWeight: 'semibold',
          lineHeight: ['1.875rem'], // 19px
        },
        'text-14-reg': {
          fontSize: ['sm'], // 14px
          lineHeight: ['6'], // 24px
        },
      },
    },
  },
})
