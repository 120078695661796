import { PaginationData } from 'src/types/Paginate'
import { PaginationProps } from 'src/types/Pagination'

export const generateParamsFromPagination = <T>(paginationData: PaginationData<T>) => {
  let params: string = `?`
  params = params.concat(`limit=${paginationData.limit ? paginationData.limit : 0}&`)
  params = params.concat(`skip=${paginationData.skip ? paginationData.skip : 0}`)
  if (paginationData.sort) params = params.concat(`&sort=${paginationData.sort}`)
  if (paginationData.order) params = params.concat(`&order=${paginationData.order}`)

  if (paginationData.filter) {
    const filter = paginationData.filter
    for (const key of Object.keys(filter)) {
      if (filter[key] === undefined || filter[key] === null || filter[key] === '') continue
      if (Array.isArray(filter[key]) && (filter[key] as unknown as any[]).length === 0) continue
      params = params.concat(`&${key}=${String(filter[key])}`)
    }
  }

  return params
}

export const generatePaginationData = (paginationDetails: PaginationProps) => {
  return {
    skip: paginationDetails.skip,
    limit: paginationDetails.limit,
    sort: paginationDetails.sort,
    order: paginationDetails.order,
    lastId: paginationDetails.lastId,
    filter: paginationDetails.filter,
  }
}
