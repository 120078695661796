import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, GridItem, SimpleGrid } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { MultiSelect } from 'src/components/Form/MultiSelect'
import { DASHBOARD_FILTER_OPTIONS } from 'src/constants'
import { businessOwnerFilterInitialValue, IBusinessOwnerFilter } from 'src/types/User'

interface BusinessOwnerFilterProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (filter: IBusinessOwnerFilter) => void
}

const BusinessOwnerFilter = ({ isOpen, onClose, onSubmit }: BusinessOwnerFilterProps) => {
  const { errors, values, handleBlur, handleChange, resetForm, handleSubmit } = useFormik({
    initialValues: businessOwnerFilterInitialValue,
    onSubmit,
  })

  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">Filter</DrawerHeader>
        <DrawerBody pt="8">
          <form onSubmit={handleSubmit}>
            <SimpleGrid spacing={['6', '8']} w="100%">
              <GridItem>
                <MultiSelect
                  label="State"
                  name="state"
                  multiple={false}
                  error={errors.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.state}
                  options={DASHBOARD_FILTER_OPTIONS}
                />
              </GridItem>
              <GridItem>
                <Flex gap="4" align="flex-end" w="100%">
                  <PrimaryButton variant="outline" onClick={() => resetForm()}>
                    Reset
                  </PrimaryButton>
                  <PrimaryButton type="submit">Apply</PrimaryButton>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default BusinessOwnerFilter
