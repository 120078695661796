import { Flex } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PromotionApi from 'src/api/promotion'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IPromotion, promotionInitialValue } from 'src/types/Promotion'
import validationSchema from 'src/views/Promotion/validationSchema'
import PromotionForm from './PromotionForm'

const EditPromotion = () => {
  const pageName = 'Edit Promotion'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = (values: IPromotion) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionApi.update({
      ...values,
      id: undefined,
      _id: values.id,
      location: undefined,
      locationDescription: undefined,
      icon: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    })
      .then((result) => {
        if (result?.id) {
          AlertSuccess('Promotion saved successfully!')
          navigate('/app/promotions')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: promotionInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues, values } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionApi.getById(params.id as string)
      .then(({ promotion }) => {
        setValues({
          ...promotion,
          locationDescription: promotion.location?.description ?? '',
        })
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  const handleTogglePromotionStatus = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionApi.update({
      ...values,
      location: undefined,
      locationDescription: undefined,
      isActive: !values.isActive,
      id: undefined,
      _id: values.id,
      icon: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    })
      .then((res) => {
        if (res?.id) {
          AlertSuccess(`Promotion ${values.isActive ? 'deactivated' : 'activated'} successfully!`)
          setValues({
            ...values,
            isActive: !values.isActive,
          })
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleDuplicatePromotionToNew = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionApi.duplicatePromotionToNew(values.id)
      .then((res) => {
        if (res?.id) {
          AlertSuccess('Promotion duplicated successfully!')
          navigate(`/app/promotions/${res.id}`)
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar
          title={pageName}
          showBack={true}
          buttons={
            <Flex gap={2}>
              <PrimaryButton type="submit">Save</PrimaryButton>
              <PrimaryButton variant="outline" type="button" onClick={handleTogglePromotionStatus}>
                {values.isActive ? 'Deactivate Promotion' : 'Activate Promotion'}
              </PrimaryButton>
              <PrimaryButton variant="outline" onClick={handleDuplicatePromotionToNew}>
                Duplicate to new
              </PrimaryButton>
            </Flex>
          }
        />
        <PromotionForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditPromotion
