import { Box, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { Children, ReactNode } from 'react'
import { RxCaretDown, RxCaretRight } from 'react-icons/rx'

interface NavSectionProps {
  title: string
  children: ReactNode
}

export function NavSection({ title, children }: NavSectionProps) {
  const sectionDisclosure = useDisclosure({ defaultIsOpen: true })
  const maxH = `${(Children.count(children) * 42) / 16}rem`

  return (
    <Box pb={sectionDisclosure.isOpen ? '4' : 0} transition="padding .3s ease">
      <Flex gap={2}>
        {sectionDisclosure.isOpen ? <RxCaretDown /> : <RxCaretRight />}
        <Text fontWeight="bold" color="gray.500" fontSize="small" onClick={sectionDisclosure.onToggle} cursor="pointer">
          {title}
        </Text>
      </Flex>
      <Stack
        spacing="4"
        mt="4"
        align="stretch"
        pl="6"
        minH="0"
        maxH={sectionDisclosure.isOpen ? maxH : 0}
        overflow="hidden"
        transition="max-height .5s ease-in-out"
      >
        {children}
      </Stack>
    </Box>
  )
}
