export interface IBusinessFaq {
  id?: string
  title: string
  description: string

  [key: string]: any
}

export const businessFaqInitialValue: IBusinessFaq = {
  title: '',
  description: '',
}
