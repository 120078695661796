import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  VStack,
} from '@chakra-ui/react'
import { FormikErrors } from 'formik'
import { ForwardRefRenderFunction, forwardRef } from 'react'

interface CheckboxProps extends ChakraCheckboxProps {
  name: string
  label?: string
  helperText?: string
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = ({ name, label, error = null, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error} display="flex" alignItems="center" w="100%" h="100%">
      <VStack alignItems="flex-start">
        <ChakraCheckbox id={name} name={name} focusBorderColor="red.500" variant="filled" size="lg" ref={ref} {...rest}>
          {label}
        </ChakraCheckbox>
        <FormHelperText color="red.700">{rest.helperText}</FormHelperText>
        <FormErrorMessage>{typeof error === 'string' ? error : ''}</FormErrorMessage>
      </VStack>
    </FormControl>
  )
}

export const Checkbox = forwardRef(CheckboxBase)
