import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { FiLogOut } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { CustomIconButton } from 'src/components/CustomIconButton'
import Logo from 'src/components/Logo'
import { useAppContext } from 'src/contexts/AppContext'
import { useSidebarDrawer } from 'src/contexts/SidebarDrawerContext'
import { Types } from 'src/reducers/AppReducer'

const TopBar = () => {
  const { state, dispatch, isMobile } = useAppContext()
  const { onToggle } = useSidebarDrawer()
  const navigate = useNavigate()

  const handleLogout = React.useCallback(() => {
    dispatch({
      type: Types.Logout,
      payload: {},
    })

    navigate('/login')
  }, [dispatch, navigate])

  return (
    <Box w="100%" h="4rem" bg="gray.100" borderBottom="1px solid" borderColor="gray.200">
      <Flex h="100%" justify="space-between" align="center" px="5">
        <Logo />
        <Flex align="center" gap={4}>
          <Text>
            Hello <b>{state.user.firstName}</b>!
          </Text>
          {!isMobile && <CustomIconButton fontSize="xl" icon={<FiLogOut />} aria-label="Logout" onClick={handleLogout} />}
          {isMobile && <CustomIconButton fontSize="xl" icon={<AiOutlineMenu />} aria-label="View Menu" onClick={onToggle} />}
        </Flex>
      </Flex>
    </Box>
  )
}

export default TopBar
