import { IFaq } from 'src/types/Faq'
import { api } from '.'

type IFaqApi = {
  get(): Promise<IFaq[]>
  getById(id: string): Promise<IFaq>
  create(faq: IFaq): Promise<{ success: boolean }>
  update(faq: IFaq): Promise<{ success: boolean }>
  sortFaqs(idList: string[]): Promise<{ success: boolean }>
  destroy(faqId: string): Promise<{ success: boolean }>
}

const FaqApi = (): IFaqApi => {
  const faqApi = '/admin/faq'

  const get = (): Promise<IFaq[]> => api.get('/faq')
  const create = (faq: IFaq): Promise<{ success: boolean }> => api.post(faqApi, faq)
  const update = (faq: IFaq): Promise<{ success: boolean }> => {
    const { id, ...rest } = faq
    return api.patch(`${faqApi}/${id}`, rest)
  }
  const sortFaqs = (idList: string[]): Promise<{ success: boolean }> => api.post(`${faqApi}/sort`, { idList })
  const getById = (id: string): Promise<IFaq> => api.get(`${faqApi}/${id}`)
  const destroy = (faqId: string): Promise<{ success: boolean }> => api.delete(`${faqApi}/${faqId}`)

  return {
    get,
    getById,
    create,
    update,
    destroy,
    sortFaqs,
  }
}

export default FaqApi()
