import { IReviewHighlight } from 'src/types/ReviewHighlight'
import { api } from '.'

type IReviewHighlightApi = {
  get(): Promise<IReviewHighlight[]>
  getById(id: string): Promise<IReviewHighlight>
  changeStatus(values: { idList: string[] }, activate: boolean): Promise<{ success: boolean }>
  create(highlight: Partial<IReviewHighlight>): Promise<{ success: boolean }>
  update(id: string, highlight: Partial<IReviewHighlight>): Promise<{ success: boolean }>
}

const ReviewHighlightApi = (): IReviewHighlightApi => {
  const highlightApi = '/admin/review-highlight'

  const get = (): Promise<IReviewHighlight[]> => api.get(highlightApi)
  const create = (highlight: IReviewHighlight): Promise<{ success: boolean }> => api.post(highlightApi, highlight)
  const update = (id: string, highlight: IReviewHighlight): Promise<{ success: boolean }> => api.patch(`${highlightApi}/${id}`, highlight)
  const getById = (id: string): Promise<IReviewHighlight> => api.get(`${highlightApi}/${id}`)
  const changeStatus = (values: { idList: string[] }, activate: boolean): Promise<{ success: boolean }> =>
    api.patch(`${highlightApi}/toggle`, { ...values, type: activate ? 'activate' : 'deactivate' })

  return {
    get,
    getById,
    changeStatus,
    create,
    update,
  }
}

export default ReviewHighlightApi()
