import { Box, Flex } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import Logo from 'src/components/Logo'

const TopBar = () => {
  return (
    <Box w="100%" h="4rem" bg="gray.100" borderBottom="1px solid" borderColor="gray.200">
      <Flex h="100%" justify="space-between" align="center" px="5">
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Flex>
    </Box>
  )
}

export default TopBar
