export interface IFaq {
  id?: string
  title: string
  description: string

  [key: string]: any
}

export const faqInitialValue: IFaq = {
  title: '',
  description: '',
}
