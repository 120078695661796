import { Box, Center, Divider, Flex, Image, Text } from '@chakra-ui/react'
import { IOrderItem } from 'src/types/Order'

interface OrderItemCardProps {
  product: IOrderItem
}

export function OrderItemCard({ product }: OrderItemCardProps) {
  return (
    <Flex
      my="2"
      w="100%"
      maxW="21rem"
      h="6.25rem"
      borderRadius="xl"
      overflow="hidden"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)"
      align="center"
      bg="gray.100"
    >
      <Center objectFit="cover" w="6.25rem" h="100%" overflow="hidden">
        <Image src={product.picture} alt={product.name} minW="100%" minH="100%" />
      </Center>
      <Box p=".5rem .75rem" flexGrow="1">
        <Text variant="text-20-bold" _groupHover={{ color: 'primary.500' }}>
          {product.name}
        </Text>
        <Divider my="2" borderColor="gray.300" />
        <Text color="gray.600">
          {product.quantity} x ${product.price?.toFixed(2) ?? 0} = ${(product.quantity * product.price).toFixed(2)}
        </Text>
      </Box>
    </Flex>
  )
}
