import { IBusinessFaq } from 'src/types/BusinessFaq'
import { api } from '.'

type IBusinessFaqApi = {
  get(): Promise<IBusinessFaq[]>
  getById(id: string): Promise<IBusinessFaq>
  create(faq: IBusinessFaq): Promise<{ success: boolean }>
  update(faq: IBusinessFaq): Promise<{ success: boolean }>
  sortBusinessFaqs(idList: string[]): Promise<{ success: boolean }>
  destroy(faqId: string): Promise<{ success: boolean }>
}

const BusinessFaqApi = (): IBusinessFaqApi => {
  const faqApi = '/admin/business-faq'

  const get = (): Promise<IBusinessFaq[]> => api.get('/business-faq')
  const create = (faq: IBusinessFaq): Promise<{ success: boolean }> => api.post(faqApi, faq)
  const update = (faq: IBusinessFaq): Promise<{ success: boolean }> => {
    const { id, ...rest } = faq
    return api.patch(`${faqApi}/${id}`, rest)
  }
  const sortBusinessFaqs = (idList: string[]): Promise<{ success: boolean }> => api.post(`${faqApi}/sort`, { idList })
  const getById = (id: string): Promise<IBusinessFaq> => api.get(`${faqApi}/${id}`)
  const destroy = (faqId: string): Promise<{ success: boolean }> => api.delete(`${faqApi}/${faqId}`)

  return {
    get,
    getById,
    create,
    update,
    destroy,
    sortBusinessFaqs,
  }
}

export default BusinessFaqApi()
