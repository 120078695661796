import { Flex } from '@chakra-ui/react'
import CopyLinkButton from 'src/components/CopyLinkButton'
import { FormCard } from 'src/components/Layout/FormCard'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import ScreenEnum from 'src/enum/ScreenEnum'
import { enumKeys, getEnumValue } from 'src/helpers/enumHelper'
import { capitalizeFirstLetter } from 'src/helpers/stringHelper'

const AppLink = () => {
  const pageName = 'Useful App Links'
  const notAllowedItems = [ScreenEnum.STORE]

  return (
    <Page title={pageName}>
      <Toolbar title={pageName} />
      <Flex gap={3} mt={3} wrap="wrap" justify="center">
        {enumKeys(ScreenEnum).map((screen: string) =>
          notAllowedItems.indexOf(getEnumValue(screen, ScreenEnum) as ScreenEnum) === -1 ? (
            <FormCard title={capitalizeFirstLetter(screen)} minW="22.5rem" mt="0">
              <CopyLinkButton screen={getEnumValue(screen, ScreenEnum) as ScreenEnum} />
            </FormCard>
          ) : null
        )}
      </Flex>
    </Page>
  )
}

export default AppLink
