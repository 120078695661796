import { useDisclosure } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BroadcastingApi from 'src/api/broadcasting'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import AuthenticationCodeModal from 'src/components/Overlay/AuthenticationCodeModal'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IBroadcasting, broadcastingInitialValue } from 'src/types/Broadcasting'
import validationSchema from 'src/views/Broadcasting/validationSchema'
import BroadcastingForm from './BroadcastingForm'

const CreateBroadcasting: React.FC = () => {
  const pageName: string = 'Broadcast Message - Push Notification'
  const navigate = useNavigate()
  const disclosure = useDisclosure()
  const [broadcastingData, setBroadcastingData] = React.useState<IBroadcasting>(broadcastingInitialValue)

  const { dispatch } = React.useContext(AppContext)

  const handleSave = (authenticationCode: string) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BroadcastingApi.create(broadcastingData, authenticationCode)
      .then((response) => {
        if (response.success) {
          AlertSuccess('Message broadcasted successfully!')
          navigate('/app/broadcasting')
        }
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const onSubmit = (values: IBroadcasting) => {
    setBroadcastingData(values)
    disclosure.onOpen()
  }

  const formik = useFormik({
    initialValues: broadcastingInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Send</PrimaryButton>} />
        <BroadcastingForm formik={formik} />
      </form>
      <AuthenticationCodeModal open={disclosure.isOpen} handleClose={disclosure.onClose} handleConfirm={handleSave} />
    </Page>
  )
}

export default CreateBroadcasting
