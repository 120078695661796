import { Flex } from '@chakra-ui/react'
import { IOrderItem } from 'src/types/Order'
import { FormCard } from './FormCard'
import { OrderItemCard } from './OrderItemCard'

interface OrderItemsCardProps {
  items: IOrderItem[]
}

export function OrderItemsCard({ items }: OrderItemsCardProps) {
  return (
    <FormCard title="Order Items">
      <Flex wrap="wrap" gap="2">
        {items?.map((item: IOrderItem) => (
          <OrderItemCard product={item} key={item.productId} />
        ))}
      </Flex>
    </FormCard>
  )
}
