

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { CharityPayments } from './CharityPayments'
import { StorePayments } from './StorePayments'

const PendingPayments = () => {
  return (
    <Page title="Pending Payments">
      <Toolbar title="Pending Payments" />
      <Tabs mt={4} variant='enclosed'>
        <TabList>
          <Tab>Stores</Tab>
          <Tab>Charities</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <StorePayments />
          </TabPanel>
          <TabPanel>
            <CharityPayments />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  )
}

export default PendingPayments
