import { TableColumn } from 'src/components/CustomDataTable'
import { ISuggestionReason } from 'src/types/SuggestionReason'

const tableColumns: TableColumn<ISuggestionReason>[] = [
  {
    label: 'Description',
    name: 'description',
  },
  {
    label: 'Email Description',
    name: 'emailDescription',
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true,
  },
]

export default tableColumns
