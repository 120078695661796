import { TableColumn } from 'src/components/CustomDataTable'
import { IAddress } from 'src/types/Address'
import IReview from 'src/types/Review'

const tableColumns: TableColumn<IReview>[] = [
  {
    label: 'Store',
    name: 'business',
    options: {
      customRender: (review) =>
        review.business?.address ? `${review.business.businessName} - ${(review.business.address as IAddress).suburb}` : '-',
    },
  },
  {
    label: 'Customer',
    name: 'user',
    options: {
      customRender: (review) => (review.user ? `${review.user.firstName} ${review.user.lastName}` : '-'),
    },
  },
  {
    label: 'Rate',
    name: 'rate',
  },
  {
    label: 'Order',
    name: 'orderCode',
  },
  {
    label: 'Comments',
    name: 'comments',
  },
]

export default tableColumns
