import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BusinessFaqApi from 'src/api/businessFaq'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IBusinessFaq, businessFaqInitialValue } from 'src/types/BusinessFaq'
import validationSchema from 'src/views/BusinessFaq/validationSchema'
import BusinessFaqForm from './BusinessFaqForm'

const EditBusinessFaq = () => {
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()

  const onSubmit = (values: IBusinessFaq) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessFaqApi.update(values)
      .then(() => {
        AlertSuccess('Business FAQ saved successfully!')
        navigate('/app/business-faq')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const destroy = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessFaqApi.destroy(params.id as string)
      .then(() => {
        AlertSuccess('Business FAQ deleted successfully!')
        navigate('/app/business-faq')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: businessFaqInitialValue,
    onSubmit,
    validationSchema,
  })

  const { setValues, handleSubmit } = formik

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessFaqApi.getById(params.id as string)
      .then((res: IBusinessFaq) => {
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  return (
    <Page title="Edit Business FAQ">
      <form onSubmit={handleSubmit}>
        <Toolbar
          title="Edit Business FAQ"
          showBack
          buttons={
            <>
              <PrimaryButton variant="solid" type="submit">
                Save
              </PrimaryButton>
              <PrimaryButton variant="outline" isDangerous onClick={destroy}>
                Delete
              </PrimaryButton>
            </>
          }
        />
        <BusinessFaqForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditBusinessFaq
