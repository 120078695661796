import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BusinessFaqApi from 'src/api/businessFaq'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import SortFaqModal from 'src/components/Overlay/SortFaqModal'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IBusinessFaq } from 'src/types/BusinessFaq'
import tableColumns from './tableColumns'

const BusinessFaq = () => {
  const navigate = useNavigate()
  const [businessFaqList, setBusinessFaqList] = React.useState<IBusinessFaq[]>([])
  const { dispatch } = React.useContext(AppContext)
  const sortDisclosure = useDisclosure()

  const getBusinessFaqList = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessFaqApi.get()
      .then((data: IBusinessFaq[]) => {
        setBusinessFaqList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setBusinessFaqList])

  React.useEffect(() => {
    getBusinessFaqList()
  }, [getBusinessFaqList])

  const handleClick = (rowIndex: number) => navigate(businessFaqList[rowIndex].id)

  const handleAdd = () => navigate('new')

  const handleSortBusinessFaq = (businessFaqList: IBusinessFaq[]) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessFaqApi.sortBusinessFaqs(businessFaqList.map((businessFaq) => businessFaq.id))
      .then((data: { success: boolean }) => {
        if (data.success) {
          setBusinessFaqList(businessFaqList)
          AlertSuccess('Business FAQs sorted successfully')
        }
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
        getBusinessFaqList()
      })
  }

  return (
    <Page title="Business FAQs">
      <Toolbar
        title="Business FAQs"
        buttons={
          <Flex gap={2}>
            <PrimaryButton variant="outline" onClick={sortDisclosure.onOpen}>
              Sort Business FAQ
            </PrimaryButton>
            <PrimaryButton onClick={handleAdd}>Add Business FAQ</PrimaryButton>
          </Flex>
        }
      />
      <Box mt={3}>
        <CustomDataTable onEditClick={handleClick} data={businessFaqList || []} tableColumns={tableColumns} />
      </Box>

      <SortFaqModal faqs={businessFaqList} open={sortDisclosure.isOpen} handleClose={sortDisclosure.onClose} handleConfirm={handleSortBusinessFaq} />
    </Page>
  )
}

export default BusinessFaq
