import { IAnalyticsQuickTip } from 'src/types/AnalyticsQuickTip'
import { api } from '.'

type IAnalyticsQuickTipApi = {
  get(): Promise<IAnalyticsQuickTip[]>
  getById(id: string): Promise<IAnalyticsQuickTip>
  create(analyticsQuickTip: IAnalyticsQuickTip): Promise<IAnalyticsQuickTip>
  update(analyticsQuickTip: IAnalyticsQuickTip): Promise<IAnalyticsQuickTip>
}

const AnalyticsQuickTipApi = (): IAnalyticsQuickTipApi => {
  const analyticsQuickTipApi = '/admin/analytics-quick-tip'

  const get = (): Promise<IAnalyticsQuickTip[]> => api.get(analyticsQuickTipApi)
  const create = (analyticsQuickTip: IAnalyticsQuickTip): Promise<IAnalyticsQuickTip> => api.post(analyticsQuickTipApi, analyticsQuickTip)
  const update = (analyticsQuickTip: IAnalyticsQuickTip): Promise<IAnalyticsQuickTip> => {
    const { id, ...rest } = analyticsQuickTip
    return api.patch(`${analyticsQuickTipApi}/${id}`, rest)
  }
  const getById = (id: string): Promise<IAnalyticsQuickTip> => api.get(`${analyticsQuickTipApi}/${id}`)

  return {
    get,
    getById,
    create,
    update,
  }
}

export default AnalyticsQuickTipApi()
