import React from 'react'
import { useNavigate } from 'react-router-dom'
import VoucherApi from 'src/api/voucher'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'
import { IVoucher } from 'src/types/Voucher'
import tableColumns from './tableColumns'

const Voucher = () => {
  const navigate = useNavigate()
  const [voucherList, setVoucherList] = React.useState<IVoucher[]>([])
  const { dispatch } = React.useContext(AppContext)

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    VoucherApi.get()
      .then((data: IVoucher[]) => {
        setVoucherList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setVoucherList])

  const handleClick = (rowIndex: number) => navigate(voucherList[rowIndex].id || voucherList[rowIndex]._id)
  const handleAdd = () => navigate('new')

  return (
    <Page title="Vouchers">
      <Toolbar title="Vouchers" buttons={<PrimaryButton onClick={handleAdd}>Add Voucher</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable onEditClick={handleClick} data={voucherList || []} tableColumns={tableColumns} />
      </Box>
    </Page>
  )
}

export default Voucher
