import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import StoreApi from 'src/api/store'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box, useDisclosure } from '@chakra-ui/react'
import { MdAppBlocking, MdAppShortcut } from 'react-icons/md'
import { ToolbarButton } from 'src/components/Button/ToolbarButton'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableState } from 'src/components/CustomDataTable'
import { VisibilityTableToolbar } from 'src/components/CustomDataTable/VisibilityTableToolbar'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { PaginationResponse } from 'src/types/Pagination'
import { IStore, IStoreFilter } from 'src/types/User'
import StoreFilter from './StoreFilter'
import tableColumns from './tableColumns'

const Store = () => {
  const navigate = useNavigate()
  const [storeList, setStoreList] = useState<PaginationResponse<IStore>>({ data: [], count: 0 })
  const { dispatch } = useContext(AppContext)
  const filterDisclosure = useDisclosure()
  const [hasFilterSet, setHasFilterSet] = useState<boolean>(false)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const getData = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IStore>) => {
        setStoreList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, tableState])

  React.useEffect(() => {
    getData()
  }, [getData])

  const handleChangeStatus = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.changeStatus(idList)
      .then(() => {
        setStoreList((stores: PaginationResponse<IStore>) =>
        ({
          ...stores, data: stores.data.map((store: IStore) => {
            if (idList.indexOf(store._id) > -1) store.active = activate
            return store
          })
        })
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleChangeVisibility = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.changeVisibility(idList)
      .then(() => {
        setStoreList((stores: PaginationResponse<IStore>) =>
        ({
          ...stores, data: stores.data.map((store: IStore) => {
            if (idList.indexOf(store?._id) > -1) store.isVisible = activate
            return store
          })
        })
        )
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const handleClick = (rowIndex: number) => {
    navigate(storeList.data[rowIndex].id || storeList.data[rowIndex]._id)
  }

  const customToolbar = (selectedRows: number[]) => {
    const handleClick = (activate: boolean) => {
      handleChangeStatus(
        storeList.data.filter((_, index: number) => selectedRows.includes(index)).map((store: IStore) => store._id),
        activate
      )
    }

    return (
      <VisibilityTableToolbar
        data={storeList.data}
        handleChangeStatus={handleChangeVisibility}
        selectedRows={selectedRows}
        extraButtons={
          <>
            <ToolbarButton onClick={() => handleClick(false)} icon={<MdAppBlocking />} label="Block" />
            <ToolbarButton onClick={() => handleClick(true)} icon={<MdAppShortcut />} label="Remove Block" />
          </>
        }
      />
    )
  }

  const setFilter = (filter: IStoreFilter) => {
    setTableState((prev) => ({ ...prev, filter, skip: 0 }))
    setHasFilterSet(!!(filter.code || filter.businessName || filter.pendingApproval || filter.contactName))
  }

  return (
    <Page title="Stores">
      <Toolbar title="Stores" onFilterOpen={filterDisclosure.onOpen} hasFilterSet={hasFilterSet} />
      <Box mt={3}>
        <CustomDataTable
          singleSelection
          showCheckbox
          serverSidePagination
          customToolbar={customToolbar}
          onEditClick={handleClick}
          data={storeList}
          tableColumns={tableColumns}
          onTableChange={onTableChange}
          tableState={tableState}
        />
      </Box>
      <StoreFilter isOpen={filterDisclosure.isOpen} onClose={filterDisclosure.onClose} onSubmit={setFilter} />
    </Page>
  )
}

export default Store
