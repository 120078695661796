import {
  Flex,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import OrderCancelTypeEnum from 'src/enum/OrderCancelType.enum'
import { enumKeys } from 'src/helpers/enumHelper'
import { IOrderCancellationInitialValueProps, orderCancellationInitialValue } from 'src/types/Order'
import validationSchema from 'src/views/Order/orderCancellationValidationSchema'
import { PrimaryButton } from '../Button/PrimaryButton'
import CustomRadioButton from '../CustomRadioButton'
import { ChakraSpan } from '../Form/ChakraSpan'
import { Input } from '../Form/Input'

interface OrderCancellationReasonModalProps {
  orderFee?: number
  orderTotal?: number
  open: boolean
  handleClose(): void
  handleConfirm(values: IOrderCancellationInitialValueProps): void
}

const options = enumKeys(OrderCancelTypeEnum).map((key) => ({
  label: OrderCancelTypeEnum[key],
  value: key,
}))

const OrderCancellationReasonModal: React.FC<OrderCancellationReasonModalProps> = ({ open, handleClose, handleConfirm, orderFee, orderTotal }) => {
  const onSubmit = (values: IOrderCancellationInitialValueProps) => {
    handleConfirm(values)
    handleClose()
  }

  const { errors, touched, values, handleBlur, handleChange, handleSubmit } = useFormik<IOrderCancellationInitialValueProps>({
    initialValues: orderCancellationInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Modal isOpen={open} onClose={handleClose} size="xl">
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>Cancel Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="3">
              Do you want to cancel this order? <br />
              <ChakraSpan fontWeight="bold" color="red.500">
                This process cannot be undone!
              </ChakraSpan>
            </Text>
            <SimpleGrid spacing={4}>
              <GridItem>
                <Input
                  error={errors.cancelReason}
                  label="Cancellation Reason"
                  name="cancelReason"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cancelReason}
                />
              </GridItem>
              <GridItem>
                <CustomRadioButton
                  options={options}
                  error={errors.cancelType}
                  touched={touched}
                  label="Who's responsible for this cancellation?"
                  name="cancelType"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cancelType}
                />
              </GridItem>
              <GridItem>
                <Text>Total to be refunded: ${getAmountToBeRefunded(orderTotal ?? 0, values.cancelType, orderFee)}</Text>
              </GridItem>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <PrimaryButton onClick={handleClose} variant="outline">
                Cancel
              </PrimaryButton>
              <PrimaryButton type="submit" autoFocus>
                Confirm
              </PrimaryButton>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

const getAmountToBeRefunded = (orderTotal: number, cancelType: string, chargeFee: number) => {
  switch (cancelType) {
    case 'APP_FAULT':
      return orderTotal

    case 'CUSTOMER_FAULT':
      return orderTotal - chargeFee

    case 'BUSINESS_FAULT':
      return orderTotal

    default:
      return orderTotal - chargeFee
  }
}

export default OrderCancellationReasonModal
