export const generateMultipartBodyForm = (data: any) => {
  let bodyFormData = new FormData()
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      if (data[key].length === 0) {
        bodyFormData.append(key, '[]')
        return
      } else if (data[key].length === 1) {
        bodyFormData.append(key, JSON.stringify(data[key]))
        return
      }

      data[key].forEach((item: any) => {
        if (typeof item === 'object') {
          bodyFormData.append(key, JSON.stringify(item))
        } else {
          bodyFormData.append(key, item)
        }
      })
      return
    } else {
      if (data[key] !== undefined) bodyFormData.append(key, data[key])
    }

  })

  return bodyFormData
}