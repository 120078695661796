import {
  Flex,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import authCodeValidationSchema from 'src/views/Broadcasting/authCodeValidationSchema'
import { PrimaryButton } from '../Button/PrimaryButton'
import { Input } from '../Form/Input'

interface AuthenticationCodeModalProps {
  open: boolean
  handleClose(): void
  handleConfirm(authCode: string): void
}

const AuthenticationCodeModal: React.FC<AuthenticationCodeModalProps> = ({ open, handleClose, handleConfirm }) => {
  const onSubmit = ({ authCode }: { authCode: string }) => {
    handleConfirm(authCode)
    handleClose()
  }

  const { errors, values, handleBlur, handleChange, handleSubmit } = useFormik<{ authCode: string }>({
    initialValues: { authCode: '' },
    onSubmit,
    validationSchema: authCodeValidationSchema,
  })

  return (
    <Modal isOpen={open} onClose={handleClose} size="xl">
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>Cancel Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="3">Please, type the authentication code to proceed.</Text>
            <SimpleGrid spacing={4}>
              <GridItem>
                <Input
                  error={errors.authCode}
                  label="Authentication Code"
                  name="authCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.authCode}
                />
              </GridItem>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <PrimaryButton onClick={handleClose} variant="outline">
                Cancel
              </PrimaryButton>
              <PrimaryButton type="submit" autoFocus>
                Confirm
              </PrimaryButton>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default AuthenticationCodeModal
