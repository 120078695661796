import { Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import Page from 'src/components/Page'

const NotFound: React.FC = () => {
  return (
    <Page title="404" h="calc(100vh - 70px)" display="flex" justifyContent="center" alignItems="center">
      <Flex flexDirection="column" height="100%" justifyContent="center">
        <Heading textAlign="center">404: The page you are looking for isn't here</Heading>
        <Text align="center">You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation</Text>
      </Flex>
    </Page>
  )
}

export default NotFound
