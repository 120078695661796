import { Button, ButtonProps } from '@chakra-ui/react'

export interface PrimaryButtonProps extends ButtonProps {
  isDangerous?: boolean
}

export function PrimaryButton({ children, isDangerous, ...args }: PrimaryButtonProps) {
  return (
    <Button
      colorScheme={isDangerous ? 'red' : 'primary'}
      _hover={{ bgColor: args.variant === 'outline' ? 'primaryAlpha.300' : 'primaryAlpha.800' }}
      fontSize="sm"
      {...args}
    >
      {children}
    </Button>
  )
}
