import { TableColumn } from 'src/components/CustomDataTable'
import { IBusinessSuggestion } from 'src/types/BusinessSuggestion'

const tableColumns: TableColumn<IBusinessSuggestion>[] = [
  {
    label: 'Business Name',
    name: 'businessName',
  },
  {
    label: 'Manager Name',
    name: 'managerName',
  },
  {
    label: 'Manager Email',
    name: 'managerEmail',
  },
  {
    label: 'Customer',
    name: 'referrer',
    options: {
      customRender: (value: IBusinessSuggestion) => (value.referrer ? `${value.referrer.firstName} ${value.referrer.lastName}` : '-'),
    },
  },
  {
    label: 'Email Sent',
    name: 'isEmailSent',
    isBoolean: true,
  },
]

export default tableColumns
