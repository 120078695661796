import { IAuth, IAuthMFA, IAuthResponse } from 'src/types/Auth'
import { api } from '.'

type IAuthApi = {
  signin(user: IAuth): Promise<IAuthResponse>
  validateMfa(mfa: IAuthMFA): Promise<IAuthResponse>
  forgotPassword(payload: Pick<IAuth, 'email'>): Promise<IAuthResponse>
  resetPassword(user: IAuth): Promise<IAuthResponse>
}

const AuthApi = (): IAuthApi => {
  const authApi = '/admin/auth'

  const signin = (user: IAuth): Promise<IAuthResponse> => api.post(`${authApi}/signin`, user)

  const validateMfa = (mfa: IAuthMFA): Promise<IAuthResponse> => api.post(`${authApi}/validate-mfa`, mfa)

  const forgotPassword = (payload: Pick<IAuth, 'email'>): Promise<IAuthResponse> => api.post(`${authApi}/forgot-password`, payload)

  const resetPassword = (user: IAuth): Promise<IAuthResponse> => api.post(`${authApi}/reset-password-email`, user)

  return { signin, validateMfa, forgotPassword, resetPassword }
}

export default AuthApi()
