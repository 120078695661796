import AnalyticsQuickTipTriggerEnum from "src/enum/AnalyticsQuickTipTriggerEnum"

export interface IAnalyticsQuickTip {
  id?: string
  message: string
  trigger: AnalyticsQuickTipTriggerEnum
  isActive?: boolean
}

export const analyticsQuickTipInitialValue: IAnalyticsQuickTip = {
  message: '',
  trigger: AnalyticsQuickTipTriggerEnum.REVENUE_TREND_DECREASING,
}
