import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import FaqApi from 'src/api/faq'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IFaq, faqInitialValue } from 'src/types/Faq'
import validationSchema from 'src/views/Faq/validationSchema'
import FaqForm from './FaqForm'

const CreateFaq: React.FC = () => {
  const pageName: string = 'Create Faq'
  const navigate = useNavigate()

  const { dispatch } = React.useContext(AppContext)

  const onSubmit = (values: IFaq) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    FaqApi.create({ ...values } as IFaq)
      .then(() => {
        AlertSuccess('Faq saved successfully!')
        navigate('/app/faq')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: faqInitialValue,
    onSubmit,
    validationSchema,
  })

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar title={pageName} showBack={true} buttons={<PrimaryButton type="submit">Save</PrimaryButton>} />
        <FaqForm formik={formik} />
      </form>
    </Page>
  )
}

export default CreateFaq
