import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { IOrder, IOrderCancellationInitialValueProps } from 'src/types/Order'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { api } from '.'

type IOrderApi = {
  get(params: PaginationData<IOrder>): Promise<PaginationResponse<IOrder>>
  listByCustomer(customerId: string, params: PaginationData<IOrder>): Promise<PaginationResponse<IOrder>>
  getById(id: string): Promise<IOrder>
  cancelOrder(id: string, values: IOrderCancellationInitialValueProps): Promise<{ success: boolean }>
  create(pb: IOrder): Promise<{ success: boolean }>
  update(pb: IOrder): Promise<{ success: boolean }>
}

const OrderApi = (): IOrderApi => {
  const orderApi = '/admin/order'

  const get = (params: PaginationData<IOrder>): Promise<PaginationResponse<IOrder>> => api.get(`${orderApi}${generateParamsFromPagination(params)}`)
  const listByCustomer = (customerId: string, params: PaginationData<IOrder>): Promise<PaginationResponse<IOrder>> =>
    api.get(`${orderApi}/list-by-customer${generateParamsFromPagination(params)}&userId=${customerId}`)
  const getById = (id: string): Promise<IOrder> => api.get(`${orderApi}/${id}`)
  const cancelOrder = (id: string, values: IOrderCancellationInitialValueProps): Promise<{ success: boolean }> =>
    api.post(`${orderApi}/${id}`, values)
  const create = (pb: IOrder): Promise<{ success: boolean }> => api.post(orderApi, pb)
  const update = (pb: IOrder): Promise<{ success: boolean }> => api.patch(`${orderApi}/${pb._id}`, pb)

  return {
    get,
    listByCustomer,
    getById,
    cancelOrder,
    create,
    update,
  }
}

export default OrderApi()
