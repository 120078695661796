import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Input } from 'src/components/Form/Input'
import { Textarea } from 'src/components/Form/Textarea'
import { FormCard } from 'src/components/Layout/FormCard'
import { IBusinessFaq } from 'src/types/BusinessFaq'
import { Formik } from 'src/types/Formik'

const BusinessFaqForm: React.FC<{ formik: Formik<IBusinessFaq> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="Business FAQ Details">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <Input error={errors.title} label="Title" name="title" onBlur={handleBlur} onChange={handleChange} value={values.title} />
        </GridItem>
        <GridItem>
          <Textarea
            error={errors.description}
            label="Description"
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            rows={5}
          />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default BusinessFaqForm
