import { Center } from '@chakra-ui/react'
import { Rings } from 'react-loader-spinner'
import { theme } from 'src/styles/theme'

interface LoadingProps {
  useLoader: boolean
}

const Loading = ({ useLoader }: LoadingProps) => {
  return (
    <Center w="100%" h="100%" position="fixed" bgColor="rgba(0,0,0,0.3)" zIndex={9999} display={useLoader ? 'flex' : 'none'}>
      <Rings visible={useLoader} color={theme.colors.primary['500']} height={100} width={100} />
    </Center>
  )
}

export default Loading
