import { GridItem, SimpleGrid } from '@chakra-ui/react'
import OrderCancelTypeEnum from 'src/enum/OrderCancelType.enum'
import { formatDateToString } from 'src/helpers/stringHelper'
import IOrder from 'src/types/Order'
import { DataTextField } from '../Form/DataTextField'
import { FormCard } from './FormCard'

interface OrderCancellationCardProps {
  order: IOrder
}

export function OrderCancellationCard({ order }: OrderCancellationCardProps) {
  return (
    <FormCard title="Cancellation Details">
      <SimpleGrid columns={[1, 1, 2]}>
        <GridItem>
          <DataTextField title="Cancelled At">
            {order.cancelledAt ? formatDateToString(order.cancelledAt, { month: '2-digit', hour: '2-digit', minute: '2-digit' }) : '-'}
          </DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Responsible">{order.cancelType ? OrderCancelTypeEnum[order.cancelType] : '-'}</DataTextField>
        </GridItem>
        <GridItem colSpan={[1, 1, 2]}>
          <DataTextField title="Cancel Reason">{order.cancelReason ?? '-'}</DataTextField>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}
