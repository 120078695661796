import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { AmbassadorDetailsCard } from 'src/components/Layout/AmbassadorDetailsCard'
import { AmbassadorLocationCard } from 'src/components/Layout/AmbassadorLocationCard'
import { IAmbassador } from 'src/types/Ambassador'

interface AmbassadorFormProps {
  ambassador: IAmbassador
}

export function AmbassadorForm({ ambassador }: AmbassadorFormProps) {
  return (
    <SimpleGrid columns={[1, 1, 3]} gap="3">
      <GridItem colSpan={2}>
        <SimpleGrid columns={1}>
          <GridItem>
            <AmbassadorDetailsCard ambassador={ambassador} />
          </GridItem>
        </SimpleGrid>
      </GridItem>
      <GridItem>
        <SimpleGrid columns={1}>
          <GridItem>
            <AmbassadorLocationCard location={ambassador?.address} />
          </GridItem>
        </SimpleGrid>
      </GridItem>
    </SimpleGrid>
  )
}
