import { FormControl, FormErrorMessage, SelectProps, forwardRef } from '@chakra-ui/react'
import { Select as ChakraReactSelect, ChakraStylesConfig } from 'chakra-react-select'
import { FormikErrors } from 'formik'
import { Label } from './Label'

const chakraStyles: (removeBorder: boolean) => ChakraStylesConfig = (removeBorder) => ({
  container: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    focusBorderColor: 'primary.500',
    border: removeBorder ? 0 : '1px solid',
    borderColor: 'gray.800',
    bgColor: 'gray.100',
    _hover: {
      borderColor: 'gray.500',
    },
    _focus: {
      bgColor: 'gray.100',
      borderColor: 'primary.500',
    },
    fontSize: 'md',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    px: 2,
    cursor: 'inherit',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    bgColor: 'gray.100',
    m: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    bgColor: 'gray.100',
    p: 0,
  }),
  option: (provided) => ({
    ...provided,
    _hover: {
      bgColor: 'primary.600',
      color: 'white',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    bgColor: 'purple.100',
    color: 'white.500',
  }),
})

export interface MultiSelectOptionProps {
  value: string | number
  label: string
}

interface MultiSelectProps extends SelectProps {
  name: string
  id?: string
  label: string
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  isMulti?: boolean
  options: MultiSelectOptionProps[]
}

const MultiSelectBase = ({ name, error, id, label, isMulti = true, options, ...rest }: MultiSelectProps, ref: any) => {
  const handleChange = (newValue: MultiSelectOptionProps[]) => {
    rest.onChange({ target: { name, value: newValue.map((value) => value.value) } } as any)
  }

  const handleChangeSingle = (newValue: MultiSelectOptionProps) => {
    rest.onChange({ target: { name, value: newValue.value } } as any)
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => (rest.value as string[]).indexOf(option.value as string) >= 0)
        : options.find((option) => option.value === rest.value)
    } else {
      return isMulti ? [] : ({} as any)
    }
  }

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <Label htmlFor={id} label={label} mb="0" />}
      <ChakraReactSelect
        isMulti={isMulti}
        id={id}
        ref={ref}
        name={name}
        chakraStyles={chakraStyles(rest.border === 0)}
        options={options}
        value={getValue()}
        onChange={isMulti ? handleChange : handleChangeSingle}
      />
      <FormErrorMessage>{typeof error === 'string' ? error : ''}</FormErrorMessage>
    </FormControl>
  )
}

export const MultiSelect = forwardRef(MultiSelectBase)
