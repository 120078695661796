import { IAddress } from "./Address"
import { IUploadFile } from "./UploadFile"

export interface ICharity {
  id?: string
  name: string
  description: string
  picture?: string
  pictureFile?: IUploadFile
  amountPending?: number
  amountTransferred?: number
  stripeAccountId?: string
  hasBankAccount?: boolean
  stripeBankAccountToken?: string
  websiteUrl: string
  isActive?: boolean
  isFoodyBagCharity?: boolean
  isAllowedToReceiveDonations?: boolean
  isAllowedToReceiveBags?: boolean
  contactName?: string
  phoneNumber?: string
  email?: string
  abn?: string
  legalName?: string
  bsb?: string
  accountNumber?: string
  address?: IAddress
  addressPlaceId?: string
  locationDescription?: string
  isPendingApproval?: boolean


  [key: string]: any
}

export const charityInitialValue: ICharity = {
  name: '',
  description: '',
  websiteUrl: '',
  isFoodyBagCharity: false,
  isAllowedToReceiveDonations: false,
}
