import { TableColumn } from 'src/components/CustomDataTable'
import { IReviewHighlight } from 'src/types/ReviewHighlight'

const tableColumns: TableColumn<IReviewHighlight>[] = [
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'Active',
    name: 'isActive',
    isBoolean: true,
  },
]

export default tableColumns
