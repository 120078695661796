import { TableColumn } from 'src/components/CustomDataTable'
import { OrderStatusColumn } from 'src/components/Layout/OrderStatusColumn'
import { formatDateToString } from 'src/helpers/stringHelper'
import IOrder from 'src/types/Order'

const tableColumns: TableColumn<IOrder>[] = [
  {
    label: 'Code',
    name: 'code',
  },
  {
    label: 'Customer Name',
    name: 'completeName',
  },
  {
    label: 'Business Name',
    name: 'businessName',
  },
  {
    label: 'Status',
    options: {
      customRender: (order) => (order.status ? <OrderStatusColumn status={order.status} /> : '-'),
    },
  },
  {
    label: 'Total',
    name: 'orderTotal',
    options: {
      customRender: (order) => {
        return order?.orderTotal ? `$ ${order.orderTotal.toFixed(2)}` : '-'
      },
    },
  },
  {
    label: 'Date',
    name: 'createdAt',
    options: {
      customRender: (order) => (order ? formatDateToString(order.createdAt) : '-'),
    },
  },
  {
    label: 'Pickup Date',
    name: 'collectDate',
    options: {
      customRender: (order) => (order?.collectDate ? formatDateToString(order.collectDate) : '-'),
    },
  },
]

export default tableColumns
