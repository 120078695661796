import { Card, Flex, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { FilterButton } from '../Button/FilterButton'
import { PrimaryButton } from '../Button/PrimaryButton'

interface ToolbarProps {
  title: string
  buttons?: ReactNode
  showBack?: boolean
  hasFilterSet?: boolean
  onFilterOpen?: () => void
}

const Toolbar = ({ title = '', buttons, showBack = false, onFilterOpen, hasFilterSet, ...rest }: ToolbarProps) => {
  const navigate = useNavigate()
  const handleClick = () => navigate(-1)

  return (
    <Card {...rest}>
      <Flex align="center" p="3" justify="space-between">
        <Text variant="text-18-semi" color="primary.500">
          {title}
        </Text>
        <Flex gap={2}>
          {buttons && buttons}
          {onFilterOpen && <FilterButton onFilterOpen={onFilterOpen} isActive={hasFilterSet} />}
          {showBack && (
            <PrimaryButton variant="outline" onClick={handleClick}>
              Back
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
    </Card>
  )
}

export default Toolbar
