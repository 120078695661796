import { TableColumn } from 'src/components/CustomDataTable'
import { formatDateToString } from 'src/helpers/stringHelper'
import IUser from 'src/types/User'

const customerInviteesTableColumns: TableColumn<IUser>[] = [
  {
    label: 'Name',
    options: {
      customRender: (user) => `${user.firstName} ${user.lastName}`,
    }
  },
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'Phone Number',
    name: 'loginPhoneNumber',
  },
  {
    label: 'Registred At',
    name: 'createdAt',
    options: {
      customRender: (user) => (user ? formatDateToString(user.createdAt, { month: '2-digit', hour: '2-digit', minute: '2-digit' }) : '-'),
    },
  },
]

export default customerInviteesTableColumns
