import { Box, Center, Divider, Flex, Image, Text, VStack } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import ProductApi from 'src/api/product'
import { useAppContext } from 'src/contexts/AppContext'
import productTypeEnum from 'src/enum/productTypeEnum'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import IProduct, { updateProductInitialValue } from 'src/types/Product'
import productStoreValidationSchema from 'src/views/Store/productStoreValidationSchema'
import { AlertSuccess } from '../Alert'
import { PrimaryButton } from '../Button/PrimaryButton'
import { Input } from '../Form/Input'
import { ProductDataBadge } from './ProductDataBadge'

interface ProductCardProps {
  product: IProduct
  changeProductStatus?: (id: string) => void
  togglePriceCheck?: (id: string) => void
  onUpdatePicture?: (product: IProduct) => void
}

export function ProductCard({ product, changeProductStatus, togglePriceCheck, onUpdatePicture }: ProductCardProps) {
  const { dispatch } = useAppContext()

  const onSubmit = async (values: IProduct) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    ProductApi.update(product._id, { itemsInBag: values.itemsInBag } as IProduct)
      .then((result: IProduct) => {
        if (result._id) AlertSuccess('Product updated successfully!')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const { errors, handleBlur, handleChange, setFieldValue, handleSubmit, values } = useFormik({
    initialValues: updateProductInitialValue,
    onSubmit,
    validationSchema: productStoreValidationSchema,
  }) as unknown as Formik<IProduct>

  useEffect(() => {
    setFieldValue('itemsInBag', product?.itemsInBag || 0)
  }, [setFieldValue, product?.itemsInBag])

  return (
    <Flex
      my="2"
      w="100%"
      maxW="21rem"
      minH="6.25rem"
      borderRadius="xl"
      overflow="hidden"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)"
      align="center"
      direction="column"
      bg="gray.100"
    >
      <Center objectFit="cover" w="100%" h="10rem" overflow="hidden">
        <Image src={product.picture} alt={product.name} minW="100%" minH="100%" />
      </Center>
      <Flex w="100%" direction="column" justify="space-between" align="space-between" p=".5rem .75rem" flexGrow="1">
        <VStack align="flex-start" mb="4" justify="space-between" h="100%">
          <Box w="100%">
            <Text variant="text-20-bold">{product.name}</Text>
            <Text variant="text-16-reg" noOfLines={3} minH="4rem">
              {product.description}
            </Text>
          </Box>
          <Box w="100%">
            <Flex gap="2">
              <ProductDataBadge data={productTypeEnum[product.type]} />
              <ProductDataBadge data={product.packageType} />
              <ProductDataBadge data={product.isGlutenFree ? 'Gluten-free' : 'Contains Gluten'} />
            </Flex>
            <Divider my="2" borderColor="gray.300" />
            <Text variant="text-16-reg" color="gray.500">
              Store Price: ${product.regularPrice.toFixed(2)}
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Foody Bag Price: ${product.grocerPrice.toFixed(2)}
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Default Quantity: {product.defaultQuantity}
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Items available now: {product.quantityAvailable}
            </Text>
            <Flex w="100%" alignItems="center">
              <Text variant="text-16-reg" color="gray.500" w="10rem">
                Items in Bag:
              </Text>
              <Input
                w={14}
                error={errors.itemsInBag}
                name="itemsInBag"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.itemsInBag}
                type="number"
              />
            </Flex>
            <Divider my="2" borderColor="gray.300" />
            <Text variant="text-16-reg" color="gray.500">
              Item bought: {product.totalOrders} times
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Unique customers: {product.totalUniqueCustomers}
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Purchase once: {product.oneTime}%
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Purchase twice: {product.twoTimes}%
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Purchase three times: {product.threeTimes}%
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Purchase four or more times: {product.moreTimes}%
            </Text>
            <Text variant="text-16-reg" color="gray.500">
              Average not sold: {product.averageRemaining}
            </Text>
          </Box>
        </VStack>
        <Flex flexDirection="column" gap={2}>
          <PrimaryButton onClick={() => handleSubmit()} variant="solid">
            Save
          </PrimaryButton>
          <PrimaryButton variant="outline" onClick={() => onUpdatePicture(product)}>
            Update picture
          </PrimaryButton>
          {togglePriceCheck && (
            <PrimaryButton variant={product.isActive ? 'outline' : 'solid'} onClick={() => togglePriceCheck(product._id.toString())}>
              {product.ignorePriceCheckForLowPrice ? 'Enable low price check' : 'Disable low price check'}
            </PrimaryButton>
          )}
          {changeProductStatus && (
            <PrimaryButton variant={product.isActive ? 'outline' : 'solid'} onClick={() => changeProductStatus(product._id.toString())}>
              {product.isActive ? 'Hide' : 'Show'}
            </PrimaryButton>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
