import { GridItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid } from '@chakra-ui/react'
import BroadcastActionEnum from 'src/enum/BroadcastAction.enum'
import BroadcastStatusEnum from 'src/enum/BroadcastStatus.enum'
import { formatDateToString } from 'src/helpers/stringHelper'
import { IBroadcasting } from 'src/types/Broadcasting'
import { PrimaryButton } from '../Button/PrimaryButton'
import { DataTextField } from '../Form/DataTextField'

interface BroadcastDetailsModalProps {
  broadcast: IBroadcasting
  onClose: () => void
  isOpen: boolean
}

export function BroadcastDetailsModal({ broadcast, onClose, isOpen }: BroadcastDetailsModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Broadcast Details {broadcast?.id}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={[1, 2, 3]} gap={2}>
            <GridItem colSpan={[1, 2, 3]}>
              <DataTextField title="Title">{broadcast?.title}</DataTextField>
            </GridItem>
            <GridItem colSpan={[1, 2, 3]}>
              <DataTextField title="Content">{broadcast?.content}</DataTextField>
            </GridItem>
            <DataTextField title="Broadcasted At">
              {broadcast?.createdAt ? formatDateToString(broadcast.createdAt, { hour: '2-digit', minute: '2-digit' }) : '-'}
            </DataTextField>
            <DataTextField title="Location">{broadcast?.location ?? '-'}</DataTextField>
            <DataTextField title="Action">{broadcast?.action ? BroadcastActionEnum[broadcast.action] : '-'}</DataTextField>
            <DataTextField title="Success on broadcasting to">
              {broadcast?.successCount ? `${broadcast.successCount} user${broadcast.successCount > 1 ? 's' : ''}` : '0 users'}
            </DataTextField>
            <DataTextField title="Fail on broadcasting to">
              {broadcast?.failureCount ? `${broadcast.failureCount} user${broadcast.failureCount > 1 ? 's' : ''}` : '0 users'}
            </DataTextField>
            <DataTextField title="Click count">
              {broadcast?.clickCount ? `${broadcast.clickCount} user${broadcast.clickCount > 1 ? 's' : ''}` : '0 users'}
            </DataTextField>
            <DataTextField title="Status">{broadcast?.status ? BroadcastStatusEnum[broadcast.status] : '-'}</DataTextField>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
