import { IProductCategory } from 'src/types/ProductCategory'
import { api } from '.'

type IProductCategoryApi = {
  get(): Promise<IProductCategory[]>
}

const ProductCategoryApi = (): IProductCategoryApi => {
  const productApi = '/product-category'

  const get = (): Promise<IProductCategory[]> => api.get(productApi)

  return {
    get,
  }
}

export default ProductCategoryApi()
