import { REFRESH_TOKEN_API } from 'src/constants'
import { api } from '..'

export type RefreshTokenData = {
  refreshToken: string
}

export type RefreshTokenResponse = {
  refreshToken: string
  accessToken: string
}

export const refreshTokenApi = async (values: RefreshTokenData): Promise<RefreshTokenResponse> => {
  const response = (await api.post(REFRESH_TOKEN_API, values)) as RefreshTokenResponse
  return response
}
