import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppVersionApi from 'src/api/appVersion'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableProps, CustomDataTableState } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { IAppVersion } from 'src/types/AppVersion'
import { PaginationResponse } from 'src/types/Pagination'
import tableColumns from './tableColumns'

const AppVersion = () => {
  const navigate = useNavigate()
  const [appVersions, setAppVersionList] = React.useState<PaginationResponse<IAppVersion>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    AppVersionApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IAppVersion>) => {
        if (data) setAppVersionList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setAppVersionList, tableState])

  const handleAdd = () => navigate('new')

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const dataTableProps: CustomDataTableProps<IAppVersion> = {
    tableColumns,
    data: appVersions,
    onViewClick: (rowIndex: number) => navigate(appVersions.data[rowIndex].id || appVersions.data[rowIndex]._id),
    onTableChange,
    tableState,
    serverSidePagination: true,
  }

  return (
    <Page title="App Version">
      <Toolbar title="App Version" buttons={<PrimaryButton onClick={handleAdd}>Add Version</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable {...dataTableProps} />
      </Box>
    </Page>
  )
}

export default AppVersion
