import { Flex, GridItem, SimpleGrid } from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { MdOutlineDeleteForever } from 'react-icons/md'
import addressApi from 'src/api/address'
import { CustomIconButton } from 'src/components/CustomIconButton'
import { AutoCompleteInput } from 'src/components/Form/AutoCompleteInput'
import { Checkbox } from 'src/components/Form/Checkbox'
import ImageUpload from 'src/components/Form/ImageUpload'
import { Input } from 'src/components/Form/Input'
import { InputColor } from 'src/components/Form/InputColor'
import { Textarea } from 'src/components/Form/Textarea'
import { FormCard } from 'src/components/Layout/FormCard'
import { useAppContext } from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import { IPromotion } from 'src/types/Promotion'
import { IUploadFile } from 'src/types/UploadFile'
import { debounce } from 'throttle-debounce'

const PromotionForm: React.FC<{ formik: Formik<IPromotion> }> = ({ formik }) => {
  const { dispatch } = useAppContext()
  const [addressList, setAddressList] = useState<{ label: string; value: string }[]>([])
  const { errors, values, handleBlur, setFieldValue, handleChange } = formik

  const onUpload = React.useCallback(
    (name: string, file: IUploadFile) => {
      setFieldValue(name, file)
    },
    [setFieldValue]
  )

  const handleAddDetails = () => {
    setFieldValue('details', !values.details ? [] : [...values.details, ''])
  }

  const handleDeleteDetails = (index: number) => {
    setFieldValue(
      'details',
      values.details.filter((_, i) => i !== index)
    )
  }

  const handleSearchAddress = useMemo(
    () =>
      debounce(800, (address?: string) => {
        if (!address || address.length <= 3) {
          setAddressList([{ label: 'Remove location', value: null }])
          return false
        }
        dispatch({ type: Types.Loading, payload: { loading: true } })
        addressApi
          .getByAddress(address, true)
          .then((addressesResult) => {
            setAddressList(addressesResult.map((address) => ({ label: address.description, value: address.placeId })))
          })
          .catch((err: Error) => console.log(err))
          .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
      }),
    [dispatch]
  )

  const handleAddressSelect = useCallback(
    (option: { value: string; label: string }) => {
      setFieldValue('placeId', option.value)
      setFieldValue('locationDescription', option.label)
    },
    [setFieldValue]
  )

  return (
    <FormCard title="Promotion">
      <SimpleGrid columns={[1, 1, 4]} spacing={4}>
        <GridItem>
          <ImageUpload onUpload={onUpload} name="iconFile" showPreview={true} currentPicture={values.icon} />
        </GridItem>
        <GridItem colSpan={3}>
          <SimpleGrid columns={1} spacing={4}>
            <GridItem>
              <Input error={errors.slug} label="Slug (website path)" name="slug" onBlur={handleBlur} onChange={handleChange} value={values.slug} />
            </GridItem>
            <GridItem>
              <Input error={errors.title} label="Title" name="title" onBlur={handleBlur} onChange={handleChange} value={values.title} />
            </GridItem>
            <GridItem>
              <Input error={errors.subtitle} label="Subtitle" name="subtitle" onBlur={handleBlur} onChange={handleChange} value={values.subtitle} />
            </GridItem>
            <GridItem>
              <Textarea
                rows={6}
                error={errors.summary}
                label="Summary"
                name="summary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.summary}
              />
            </GridItem>
            <GridItem display="flex" gap={4}>
              <InputColor
                name="colors.0"
                label="Color #1 (hex)"
                value={(values?.colors?.length > 0 && values?.colors[0]) ?? ''}
                error={errors.colors && errors.colors[0]}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <InputColor
                name="colors.1"
                label="Color #2 (hex)"
                value={(values?.colors?.length > 0 && values?.colors[1]) ?? ''}
                error={errors.colors && errors.colors[1]}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </GridItem>
            <GridItem>
              <Flex gap={2} alignItems="end">
                <Input
                  name="detailsTitle"
                  label={`Details Title`}
                  error={errors.detailsTitle}
                  value={values?.detailsTitle}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <CustomIconButton
                  variant="solid"
                  color="white"
                  p="4"
                  aria-label="Add Details"
                  _hover={{ bgColor: 'primaryAlpha.800' }}
                  icon={<BiPlus style={{ fontSize: '32px', width: '32px' }} />}
                  label="Add Details"
                  onClick={handleAddDetails}
                />
              </Flex>
              {values.details?.map((details, i) => (
                <GridItem mt={4} key={`details.${i}`}>
                  <Flex alignItems="end" gap={2}>
                    <Input
                      name={`details.${i}`}
                      label={`Details - Topic ${i + 1}`}
                      value={details}
                      error={errors.details && errors.details[i]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <CustomIconButton
                      variant="outline"
                      fontSize="xl"
                      color="red.500"
                      _hover={{ bgColor: 'red.200', color: 'red.700' }}
                      px={2}
                      icon={<MdOutlineDeleteForever />}
                      aria-label="Delete T&C"
                      onClick={() => handleDeleteDetails(i)}
                    />
                  </Flex>
                </GridItem>
              ))}
            </GridItem>
            <GridItem>
              <AutoCompleteInput
                label="Location - State (optional) - Australia only"
                name="locationDescription"
                error={errors.placeId}
                options={addressList}
                getData={handleSearchAddress}
                onValueSelected={handleAddressSelect}
                onChange={handleChange}
                value={values.locationDescription}
              />
            </GridItem>
            <GridItem>
              <Checkbox
                error={errors.isForEmail}
                label="For Email"
                name="isForEmail"
                onBlur={handleBlur}
                onChange={handleChange}
                defaultChecked={values.isForEmail}
                checked={values.isForEmail}
                isChecked={values.isForEmail}
              />
            </GridItem>
          </SimpleGrid>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default PromotionForm
