import productTypeEnum from 'src/enum/productTypeEnum'
import { getKeyEnum } from 'src/helpers/enumHelper'
import * as Yup from 'yup'

export default Yup.object().shape({
  grocerId: Yup.string().required('Store is required'),
  name: Yup.string().required('Name is required'),
  type: Yup.string().required('Type is required'),
  packageType: Yup.string().required('Package Type is required'),
  regularPrice: Yup.number()
    .min(3, ({ min }) => `Regular Price must be greater than or equal to ${min}`)
    .required('Regular Price is required'),
  grocerPrice: Yup.number()
    .min(3, ({ min }) => `Foody Bag Price must be greater than or equal to ${min}`)
    .max(60, ({ max }) => `Foody Bag Price must be less than or equal to ${max}`)
    .when('type', {
      is: (type: string) => type === getKeyEnum(productTypeEnum.PACKAGE, productTypeEnum),
      then: (schema) =>
        schema.test({
          test: (grocerPrice, schema) => {
            if (grocerPrice) return schema.parent.regularPrice - schema.parent.regularPrice * 0.6 >= grocerPrice
            return true
          },
          message: 'Foody Bag price needs to be discounted by at least 60%',
        }),
    })
    .required('Foody Bag Price is required'),
  dailyLimit: Yup.number()
    .min(1, ({ min }) => `Daily Limit must be empty or greater than or equal to ${min}`)
    .optional(),
  quantityAvailable: Yup.number().min(0).required('Quantity Available is required'),
  defaultQuantity: Yup.number().min(0).required('Default Quantity is required'),
  category: Yup.string().required('Category is required'),
  description: Yup.string().required('Description is required'),
})
