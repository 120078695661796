import { Box, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MerchandiseApi from 'src/api/merchandise'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableProps, CustomDataTableState } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { IMerchandise } from 'src/types/Merchandise'
import { PaginationResponse } from 'src/types/Pagination'
import tableColumns from './tableColumns'

const Merchandise = () => {
  const navigate = useNavigate()
  const [merchandiseList, setMerchandiseList] = React.useState<PaginationResponse<IMerchandise>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const dataTableProps: CustomDataTableProps<IMerchandise> = {
    tableColumns,
    data: { count: merchandiseList.count ?? 0, data: merchandiseList.data ?? [] },
    onTableChange,
    tableState,
    serverSidePagination: true,
    onEditClick: (rowIndex: number) => navigate(merchandiseList.data[rowIndex].id)
  }

  const getMerchandiseList = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    MerchandiseApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IMerchandise>) => {
        setMerchandiseList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setMerchandiseList, tableState])

  React.useEffect(() => {
    getMerchandiseList()
  }, [getMerchandiseList])

  const handleAdd = () => navigate('new')

  return (
    <Page title="Merchandise">
      <Toolbar
        title="Merchandise"
        buttons={
          <Flex gap={2}>
            <PrimaryButton onClick={handleAdd}>Add Merchadise</PrimaryButton>
          </Flex>
        }
      />
      <Box mt={3}>
        <CustomDataTable {...dataTableProps} />
      </Box>
    </Page>
  )
}

export default Merchandise
