import { Textarea as ChakraTextarea, TextareaProps as ChakraTextareaProps, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { FormikErrors } from 'formik'
import { ForwardRefRenderFunction, forwardRef } from 'react'
import { Label } from './Label'

interface TextareaProps extends ChakraTextareaProps {
  name: string
  label?: string
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
}

const TextareaBase: ForwardRefRenderFunction<HTMLTextAreaElement, TextareaProps> = ({ name, label, error = null, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <Label htmlFor={name} label={label} mb="0" />}

      <ChakraTextarea
        id={name}
        name={name}
        focusBorderColor="primary.500"
        borderColor="gray.300"
        border="1px solid"
        variant="filled"
        size="md"
        ref={ref}
        position="relative"
        {...rest}
      />
      {!!error && <FormErrorMessage>{typeof error === 'string' ? error : ''}</FormErrorMessage>}
    </FormControl>
  )
}

export const Textarea = forwardRef(TextareaBase)
