import { Badge, Flex, Image, Text } from '@chakra-ui/react'
import { IStore } from 'src/types/User'
import { FormCard } from './FormCard'

interface StoreCharityCardProps {
  store: IStore
}

export function StoreCharityCard({ store }: StoreCharityCardProps) {
  return (
    <FormCard title="Charity">
      <Flex gap="4">
        <Image src={store?.charity?.picture} w="10rem" />
        <Flex flexDirection="column">
          <Text fontWeight="bold">{store?.charity?.name}</Text>
          <Text>{store?.charity?.description}</Text>
          <Badge colorScheme={store.charity?.isFoodyBagCharity ? "yellow" : "blue"} mt="20px">{store?.charity?.isFoodyBagCharity ? "This is the default charity, this store hasn't picked one" : "Charity picked by the store"}</Badge>
        </Flex>

      </Flex>
    </FormCard>
  )
}
