export interface IReviewHighlight {
  _id?: string
  name: string
  isActive: boolean

  [key: string]: any
}

export const reviewHighlightInitialValue: IReviewHighlight = {
  name: '',
  isActive: false,
}
