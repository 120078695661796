import { FormLabel, FormLabelProps } from '@chakra-ui/react'

interface LabelProps extends FormLabelProps {
  htmlFor: string
  label: string
}

export function Label({ htmlFor, label, ...args }: LabelProps) {
  return (
    <FormLabel {...args} htmlFor={htmlFor}>
      {label}
    </FormLabel>
  )
}
