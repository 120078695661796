import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { IEmailLog } from 'src/types/EmailLog'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { api } from '.'

type IEmailLogApi = {
  listByCustomer(customerId: string, params: PaginationData<IEmailLog>): Promise<PaginationResponse<IEmailLog>>
}

const EmailLogApi = (): IEmailLogApi => {
  const emailLogApi = '/admin/email-logs'

  const listByCustomer = (customerId: string, params: PaginationData<IEmailLog>): Promise<PaginationResponse<IEmailLog>> =>
    api.get(`${emailLogApi}/${customerId}${generateParamsFromPagination(params)}`)

  return { listByCustomer }
}

export default EmailLogApi()
