import { Stripe } from '@stripe/stripe-js'
import ActionMap from 'src/types/ActionMap'
import AppContextInitialState from 'src/types/AppContextInitialState'
import IUser from 'src/types/User'

export enum Types {
  Authenticate = 'AUTHENTICATE',
  RestoreLogin = 'RESTORE_LOGIN',
  SetUser = 'SET_USER',
  Logout = 'LOGOUT',
  Loading = 'LOADING',
  UpdateUser = 'UPDATE_USER',
  SetStripe = 'SET_STRIPE',
}

type AppPayload = {
  [Types.Authenticate]: {
    isAuthenticated: boolean
    user: IUser
    token: string
    refreshToken: string
  }
  [Types.SetUser]: { user: IUser }
  [Types.RestoreLogin]: {}
  [Types.Logout]: {}
  [Types.Loading]: {
    loading: boolean
  }
  [Types.SetStripe]: {
    stripe: Stripe
  }
  [Types.UpdateUser]: {}
}

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>]

export const appReducer = (state: AppContextInitialState, action: AppActions) => {
  switch (action.type) {
    case 'AUTHENTICATE':
      localStorage.setItem('fbaToken', action.payload.token)
      localStorage.setItem('fbaRefreshToken', action.payload.refreshToken)
      localStorage.setItem('fbaUser', action.payload.user._id as string)

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      }
    case 'SET_USER':
      return {
        ...state,
        user: action.payload.user,
      }
    case 'RESTORE_LOGIN':
      const token = localStorage.getItem('fbaToken')
      const refreshToken = localStorage.getItem('fbaRefreshToken')
      const user = localStorage.getItem('fbaUser')

      return {
        ...state,
        user: { id: user } as IUser,
        isAuthenticated: token && user ? true : false,
        token: token ?? null,
        refreshToken: refreshToken ?? null,
      }
    case 'LOGOUT':
      localStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        refreshToken: null,
      }
    case 'LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      }
    case 'SET_STRIPE':
      return {
        ...state,
        stripe: action.payload.stripe,
      }
    default:
      return state
  }
}
