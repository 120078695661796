import React, { useEffect, useRef, useState } from 'react'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box, Button } from '@chakra-ui/react'
import { CSVLink } from 'react-csv'
import StoreApi from 'src/api/store'
import { FormCard } from 'src/components/Layout/FormCard'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { Types } from 'src/reducers/AppReducer'

const StoreReports = () => {
  const { dispatch } = React.useContext(AppContext)
  const [downloadData, setDownloadData] = useState<any[]>([])
  const csvLinkEl = useRef<any>()
  const [reportFileName, setReportFileName] = useState<string>('')

  const getStoreFavouritesDataForCSV = async () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    const data = await StoreApi.getStoreFavouritesForCSV().finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    setDownloadData(data)
    setReportFileName('store-favourites-with-location.csv')
  }

  useEffect(() => {
    if (csvLinkEl?.current && downloadData.length > 0) csvLinkEl.current.link.click()
  }, [reportFileName, downloadData])

  const headers = [
    { key: 'businessName', label: 'Store name' },
    { key: 'favoritesCount', label: 'Favourites' },
    { key: 'suburb', label: 'Suburb' },
    { key: 'latitude', label: 'Latitude' },
    { key: 'longitude', label: 'Longitude' },
    { key: 'totalSold', label: 'Total Bags Sold' },
  ]

  return (
    <Page title="Store Reports">
      <Toolbar title="Store Reports" />
      <Box mt={3}>
        <FormCard title="Store with Location and favourites" minW="22.5rem" mt="0">
          <Button onClick={getStoreFavouritesDataForCSV}>Download </Button>
          {/* @ts-ignore-next-line */}
          <CSVLink data={downloadData} headers={headers} filename={reportFileName} ref={csvLinkEl} />
        </FormCard>
      </Box>
    </Page>
  )
}

export default StoreReports
