import { Flex, GridItem, Heading, SimpleGrid } from '@chakra-ui/react'
import { Formik } from 'formik'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthApi from 'src/api/auth'
import { AlertError, AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { Input } from 'src/components/Form/Input'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { AuthInitialValue } from 'src/types/Auth'
import validationSchema from './validationSchema'

const ResetPassword = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search])
  const { dispatch } = React.useContext(AppContext)

  const onSubmit = React.useCallback(
    (data: any) => {
      if (data.password !== data.confirmPassword) {
        AlertError('Password and confirm password do not match')
        return
      }
      dispatch({ type: Types.Loading, payload: { loading: true } })
      AuthApi.resetPassword({ password: data.password, resetPasswordToken: params.get('resetPasswordToken') })
        .then(() => {
          AlertSuccess('Password reset successfully')
          navigate('/login', { replace: true })
        })
        .catch((err: Error) => {
          console.log(err)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    },
    [navigate, dispatch, params]
  )

  return (
    <Page title="Reset Password">
      <Flex flexDirection="column" height="100%" justifyContent="center">
        <Formik initialValues={AuthInitialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, handleBlur, handleChange, handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SimpleGrid spacing={3}>
                <GridItem>
                  <Heading variant="h3">Reset Password</Heading>
                </GridItem>
                <GridItem>
                  <Input
                    error={errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    error={errors.confirmPassword}
                    label="Confirm Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.confirmPassword}
                  />
                </GridItem>
                <GridItem>
                  <PrimaryButton type="submit">Save</PrimaryButton>
                </GridItem>
              </SimpleGrid>
            </form>
          )}
        </Formik>
      </Flex>
    </Page>
  )
}

export default ResetPassword
