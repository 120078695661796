import { Card, CardBody, CardHeader, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import StoreApi from 'src/api/store'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IStore } from 'src/types/User'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableState, TableColumn } from './CustomDataTable'

const tableColumns: TableColumn<IStore>[] = [
  {
    label: 'Business Name',
    name: 'businessName',
  },
  {
    label: 'Favourites',
    name: 'favoritesCount',
  },
]

const GrocerListWithFavourites: React.FC = () => {
  const { dispatch } = React.useContext(AppContext)
  const [businesses, setBusinesses] = React.useState<IStore[]>([])
  // const [dateRange, setDateRange] = React.useState<string>(DASHBOARD_FILTER_DATES[0].value)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
    resultsPerPage: 10,
  })

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.getStoresWithFavourites({
      sort: 'favoritesCount',
      order: 'desc',
      dateRange: undefined,
    })
      .then((result: IStore[]) => {
        if (result) setBusinesses(result)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch])

  return (
    <Card>
      <CardHeader mb={0} pb={0}>
        <Text>Stores with Favourites</Text>
        {/* <MultiSelect
          isMulti={false}
          label="Category"
          name="category"
          // onBlur={handleBlur}
          // onChange={handleChange}
          value={dateRange}
          options={DASHBOARD_FILTER_DATES}
        /> */}
      </CardHeader>
      <CardBody>
        <CustomDataTable data={businesses} tableState={tableState} onTableChange={onTableChange} tableColumns={tableColumns} />
      </CardBody>
    </Card>
  )
}

export default GrocerListWithFavourites
