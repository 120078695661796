import { GridItem, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import ProductApi from 'src/api/product'
import AddProductModal from 'src/components/AddProductModal'
import { StoreCharityCard } from 'src/components/Layout/StoreCharityCard'
import { StoreContactDetailsCard } from 'src/components/Layout/StoreContactDetailsCard'
import { StoreDetailsCard } from 'src/components/Layout/StoreDetailsCard'
import { StoreProductsCard } from 'src/components/Layout/StoreProductsCard'
import { StorePromoVideoCard } from 'src/components/Layout/StorePromoVideoCard'
import { StoreSettingsCard } from 'src/components/Layout/StoreSettingsCard'
import { StoreSocialMediaCard } from 'src/components/Layout/StoreSocialMediaCard'
import UpdateProductPictureModal from 'src/components/UpdateProductPictureModal'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { Formik } from 'src/types/Formik'
import IProduct from 'src/types/Product'
import { IStore } from 'src/types/User'

interface StoreDetailsFormProps { store: IStore; setStore: React.Dispatch<React.SetStateAction<IStore>>; formik: Partial<Formik<IStore>> }

const StoreDetailsForm: React.FC<StoreDetailsFormProps> = ({
  store,
  setStore,
  formik,
}) => {
  const disclosure = useDisclosure()
  const pictureDisclosure = useDisclosure()
  const { dispatch } = React.useContext(AppContext)
  const [product, setProduct] = useState({} as IProduct)

  const changeProductStatus = React.useCallback(
    (productId: string) => {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      ProductApi.changeStatus(productId)
        .then(() => {
          setStore((prev) => ({
            ...prev,
            products: prev.products.map((p: IProduct) => (p._id === productId ? { ...p, isActive: !p.isActive } : p)),
          }))
        })
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    },
    [dispatch, setStore]
  )

  const togglePriceCheck = React.useCallback(
    (productId: string) => {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      ProductApi.togglePriceCheck(productId)
        .then(() => {
          setStore((prev) => ({
            ...prev,
            products: prev.products.map((p: IProduct) =>
              p._id === productId ? { ...p, ignorePriceCheckForLowPrice: !p.ignorePriceCheckForLowPrice } : p
            ),
          }))
        })
        .catch((err: Error) => console.log(err))
        .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
    },
    [dispatch, setStore]
  )

  const handleOpenUpdatePicture = (product: IProduct) => {
    pictureDisclosure.onOpen()
    setProduct(product)
  }

  return (
    <SimpleGrid columns={[1, 1, 2]} spacing={3}>
      <GridItem>
        <StoreDetailsCard store={store} />
        <StorePromoVideoCard store={store} formik={formik} />
        <StoreCharityCard store={store} />
      </GridItem>
      <GridItem>
        <StoreContactDetailsCard store={store} />
        <StoreSettingsCard formik={formik} isOnTrialSubscription={store.isInCommissionTrial} />
        <StoreSocialMediaCard store={store} formik={formik} />
      </GridItem>
      <GridItem colSpan={2}>
        <StoreProductsCard store={store} onCreate={disclosure.onOpen} onUpdatePicture={handleOpenUpdatePicture} changeProductStatus={changeProductStatus} togglePriceCheck={togglePriceCheck} />
      </GridItem>
      <AddProductModal open={disclosure.isOpen} handleClose={disclosure.onClose} store={store} setStore={setStore} />
      <UpdateProductPictureModal open={pictureDisclosure.isOpen} handleClose={pictureDisclosure.onClose} product={product} setStore={setStore} />
    </SimpleGrid>
  )
}

export default StoreDetailsForm
