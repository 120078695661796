import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Checkbox } from 'src/components/Form/Checkbox'
import { FormCard } from 'src/components/Layout/FormCard'
import { Formik } from 'src/types/Formik'
import { ISettings } from 'src/types/Settings'

const SettingsForm: React.FC<{ formik: Formik<ISettings> }> = ({ formik }) => {
  const { errors, handleBlur, handleChange, values } = formik

  return (
    <FormCard title="Settings">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <Checkbox error={errors.isAutoPaymentOn} label="Auto Payment" name="isAutoPaymentOn" onBlur={handleBlur} onChange={handleChange} isChecked={values.isAutoPaymentOn} />
        </GridItem>
        <GridItem>
          <Checkbox error={errors.isAutoPaymentCharityOn} label="Auto Payment for charities" name="isAutoPaymentCharityOn" onBlur={handleBlur} onChange={handleChange} isChecked={values.isAutoPaymentCharityOn} />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default SettingsForm
