import { Flex } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { useParams } from 'react-router-dom'
import BusinessSuggestionApi from 'src/api/businessSuggestion'
import { AlertSuccess } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IBusinessSuggestion, businessSuggestionInitialValue } from 'src/types/BusinessSuggestion'
import validationSchema from 'src/views/BusinessSuggestion/validationSchema'
import BusinessSuggestionForm from './BusinessSuggestionForm'

const EditBusinessSuggestion = () => {
  const pageName: string = 'Edit Store Suggestion'
  const { dispatch } = React.useContext(AppContext)
  const params = useParams()

  const onSubmit = (values: IBusinessSuggestion) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessSuggestionApi.update({ ...values, _id: values.id, id: undefined })
      .then(() => {
        AlertSuccess('Business Suggestion saved successfully!')
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const formik = useFormik({
    initialValues: businessSuggestionInitialValue,
    onSubmit,
    validationSchema,
  })

  const { values, setValues } = formik

  const sendEmail = () => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessSuggestionApi.sendEmail(params.id as string)
      .then(() => {
        AlertSuccess('Email scheduled successfully!')
        setValues((prev) => ({ ...prev, isEmailSent: true }))
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    BusinessSuggestionApi.getById(params.id as string)
      .then((res: IBusinessSuggestion) => {
        setValues(res)
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }, [dispatch, params.id, setValues])

  if (!values) return null

  return (
    <Page title={pageName}>
      <form onSubmit={formik.handleSubmit}>
        <Toolbar
          title={pageName}
          showBack={true}
          buttons={
            <Flex gap={2}>
              <PrimaryButton type="submit">Save</PrimaryButton>
              <PrimaryButton variant="outline" onClick={sendEmail}>
                Send Email
              </PrimaryButton>
            </Flex>
          }
        />
        <BusinessSuggestionForm formik={formik} />
      </form>
    </Page>
  )
}

export default EditBusinessSuggestion
