import { Badge, GridItem, SimpleGrid } from '@chakra-ui/react'
import { Formik } from 'src/types/Formik'
import { IStore } from 'src/types/User'
import CopyLinkButton from '../CopyLinkButton'
import { Input } from '../Form/Input'
import { FormCard } from './FormCard'

interface StoreSettingsCardProps {
  formik: Partial<Formik<IStore>>
  isOnTrialSubscription: boolean
}

export function StoreSettingsCard({ formik, isOnTrialSubscription }: StoreSettingsCardProps) {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="Store Settings">
      {isOnTrialSubscription && (
        <Badge colorScheme="red" my="2">
          This store is on the commission trial (0% commission).
        </Badge>
      )}
      <SimpleGrid columns={[1, 1, 2]} spacing={2}>
        <GridItem>
          <Input
            error={errors.commissionPercentage}
            type="number"
            label="Commission Percentage"
            name="commissionPercentage"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.commissionPercentage}
          />
        </GridItem>
        <GridItem>
          <Input
            error={errors.statementEmail}
            label="Statement Email"
            name="statementEmail"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.statementEmail}
          />
        </GridItem>
        <GridItem colSpan={[1, 1, 2]} display="flex" alignItems="flex-end" >
          <CopyLinkButton shortUrl={values.shortUrl} />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}
