import { Box, Table, TableContainer } from '@chakra-ui/react'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { flattenObject } from 'src/helpers/objectHelper'
import { PaginationResponse } from 'src/types/Pagination'
import { CustomDataTableBody } from './CustomDataTableBody'
import { CustomDataTableHeader } from './CustomDataTableHeader'
import { Pagination } from './Pagination'

export type CustomDataTableOnChangeActions = 'changePage'

export interface CustomDataTableState {
  currentPage: number
  skip?: number
  lastId?: string
  limit: number
  totalItems: number
  resultsPerPage?: number
  filter?: any
}

export interface CustomDataTableProps<T extends { id?: string; _id?: string }> {
  tableColumns: TableColumn<T>[]
  showCheckbox?: boolean
  tableState?: CustomDataTableState
  data: PaginationResponse<T> | T[]
  onTableChange?: (action: CustomDataTableOnChangeActions, tableStatePagination: CustomDataTableState) => void
  onEditClick?: (rowIndex: number) => void
  onRowClick?: (rowIndex: number) => void
  onViewClick?: (rowIndex: number) => void
  serverSidePagination?: boolean
  singleSelection?: boolean
  customToolbar?: (rowsSelectedIndex: number[]) => ReactNode
}

export interface TableColumn<T> {
  label: string
  name?: string
  isBoolean?: boolean
  options?: {
    customRender?: (values: T) => string | ReactNode
  }
}

interface SelectedRowsProps {
  index: number
  selected: boolean
}

export function CustomDataTable<T extends { id?: string; _id?: string }>({
  tableColumns,
  data,
  showCheckbox,
  onEditClick,
  onRowClick,
  onViewClick,
  onTableChange,
  tableState,
  serverSidePagination = false,
  singleSelection = false,
  customToolbar,
}: CustomDataTableProps<T>) {
  const [tableData, setTableData] = useState<T[]>([])
  const [selectedRows, setSelectedRows] = useState<SelectedRowsProps[]>([])
  const hasSelectedRow = selectedRows.some((row) => row.selected)

  const handleLocalPagination = useCallback(() => {
    if (!serverSidePagination && tableState) {
      const skip = tableState.skip || 0
      const limit = tableState.limit || 10
      const newTableData = (data as T[]).slice(skip, skip + limit).map((dataItem) => flattenObject<T>(dataItem))
      setTableData(newTableData)
    } else {
      setTableData((data as T[]).map((dataItem) => flattenObject<T>(dataItem)))
    }
  }, [tableState, data, serverSidePagination])

  useEffect(() => {
    if (serverSidePagination) {
      const paginationData = data as PaginationResponse<T>
      setTableData((paginationData as PaginationResponse<T>).data.map((dataItem) => flattenObject<T>(dataItem)))
    } else {
      handleLocalPagination()
    }
  }, [data, serverSidePagination, handleLocalPagination])

  useEffect(() => {
    setSelectedRows(tableData.map((_row, index) => ({ index, selected: false })))
  }, [tableData])

  const handleChangePage = (pageTo: number) => {
    if ((serverSidePagination || tableState) && onTableChange) {
      onTableChange('changePage', { ...tableState, skip: (pageTo - 1) * tableState.limit, currentPage: pageTo })
    }
  }

  useEffect(() => {
    if (!serverSidePagination && tableState) handleLocalPagination()
  }, [tableState, serverSidePagination, handleLocalPagination])

  const toggleAllCheckBoxes = () => {
    const newSelectedRows = [...selectedRows]
    const allSelected = newSelectedRows.every((row) => row.selected)
    if (allSelected) {
      newSelectedRows.forEach((row) => (row.selected = false))
    } else {
      newSelectedRows.forEach((row) => (row.selected = true))
    }
    setSelectedRows(newSelectedRows)
  }

  const handleToggleCheckbox = (index: number) => {
    const newSelectedRows = [...selectedRows]
    newSelectedRows[index].selected = !newSelectedRows[index].selected
    setSelectedRows(newSelectedRows)
  }

  return (
    <Box>
      <TableContainer borderRadius="md" overflowX="scroll" border="1px solid" borderColor="gray.200">
        <Box
          px="4"
          py={hasSelectedRow ? '4' : 0}
          borderBottom={hasSelectedRow ? '1px solid' : 0}
          borderColor="gray.200"
          h="100%"
          maxH={hasSelectedRow ? '100%' : 0}
          overflow="hidden"
          transition="all 0.2s ease-in-out"
        >
          {customToolbar && customToolbar(selectedRows.filter((row) => row.selected).map((row) => row.index))}
        </Box>
        <Table colorScheme="gray" variant="simple" size="sm">
          <CustomDataTableHeader
            isAllCheckboxesChecked={selectedRows.every((row) => row.selected)}
            showCheckbox={showCheckbox}
            singleSelection={singleSelection}
            onCheckBoxClick={toggleAllCheckBoxes}
            tableColumns={tableColumns}
            showActionsColumn={!!(onEditClick || onViewClick)}
          />
          <CustomDataTableBody
            data={data}
            tableColumns={tableColumns}
            tableData={tableData}
            showCheckbox={showCheckbox}
            selectedRows={selectedRows}
            onEditClick={onEditClick}
            onViewClick={onViewClick}
            onRowClick={onRowClick}
            serverSidePagination={serverSidePagination}
            handleToggleCheckbox={handleToggleCheckbox}
            singleSelection={singleSelection}
          />
        </Table>
      </TableContainer>
      {(serverSidePagination || tableState) && (
        <Box p="4">
          <Pagination
            handleChangePage={handleChangePage}
            totalItems={serverSidePagination ? (data as PaginationResponse<T>)?.count : (data as T[]).length}
            limit={tableState.limit}
            skip={tableState.skip}
            currentPage={tableState.currentPage}
          />
        </Box>
      )}
    </Box>
  )
}
