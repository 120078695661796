import { ISuggestionReason } from 'src/types/SuggestionReason'
import { api } from '.'

type ISuggestionReasonApi = {
  get(): Promise<ISuggestionReason[]>
  getById(id: string): Promise<ISuggestionReason>
  changeStatus(idList: { idList: string[] }, activate: boolean): Promise<{ success: boolean }>
  create(pb: ISuggestionReason): Promise<{ success: boolean }>
  update(pb: ISuggestionReason): Promise<{ success: boolean }>
}

const SuggestionReasonApi = (): ISuggestionReasonApi => {
  const reasonApi = '/admin/reason'

  const get = (): Promise<ISuggestionReason[]> => api.get(reasonApi)
  const create = (pb: ISuggestionReason): Promise<{ success: boolean }> => api.post(reasonApi, pb)
  const update = (pb: ISuggestionReason): Promise<{ success: boolean }> => api.patch(`${reasonApi}/${pb._id}`, pb)
  const getById = (id: string): Promise<ISuggestionReason> => api.get(`${reasonApi}/${id}`)
  const changeStatus = (idList: { idList: string[] }, activate: boolean): Promise<{ success: boolean }> =>
    api.patch(`${reasonApi}/${activate ? 'activate' : 'deactivate'}`, idList)

  return {
    get,
    getById,
    changeStatus,
    create,
    update,
  }
}

export default SuggestionReasonApi()
