import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { theme } from 'src/styles/theme'
import { IBusinessFaq } from 'src/types/BusinessFaq'
import { IFaq } from 'src/types/Faq'
import { PrimaryButton } from '../Button/PrimaryButton'
import { StrictModeDroppable } from '../StrictModeDroppable'

interface SortFaqModalProps {
  faqs: IFaq[] | IBusinessFaq[]
  open: boolean
  handleClose(): void
  handleConfirm(faqList: IFaq[]): void
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  border: isDragging ? 0 : '1px solid #eee',
  borderRadius: 5,

  // change background colour if dragging
  background: isDragging ? theme.colors.primary['500'] : '#fff',
  color: isDragging ? '#fff' : '#000',
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? theme.colors.primaryAlpha['500'] : 'transparent',
  padding: grid,
  width: '100%',
  borderRadius: 5,
})

const SortFaqModal: React.FC<SortFaqModalProps> = ({ faqs, open, handleClose, handleConfirm }) => {
  const [faqList, setFaqList] = useState<IFaq[]>([])

  const onSubmit = () => {
    handleConfirm(faqList)
    handleClose()
  }

  const reorder = (list: IFaq[], startIndex: number, endIndex: number) => {
    const result: IFaq[] = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(faqList, result.source.index, result.destination.index)
    setFaqList(items)
  }

  useEffect(() => {
    setFaqList(faqs)
  }, [faqs])

  return (
    <Modal isOpen={open} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sort Faqs</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="sm" mb={4}>
            Drag and drop the faqs to sort them.
          </Text>
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {faqList.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {item.title}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <PrimaryButton onClick={handleClose} variant="outline">
              Cancel
            </PrimaryButton>
            <PrimaryButton onClick={onSubmit} autoFocus>
              Save
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SortFaqModal
