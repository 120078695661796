
export interface ISettings {
  _id?: string
  id?: string
  isAutoPaymentOn: boolean
  isAutoPaymentCharityOn: boolean
}

export const settingsInitialValue: ISettings = {
  isAutoPaymentOn: false,
  isAutoPaymentCharityOn: false,
}
