import { ISettings } from 'src/types/Settings'
import { api } from '.'

type ISettingsApi = {
  get(): Promise<ISettings>
  update(settings: ISettings): Promise<{ success: boolean }>
}

const SettingsApi = (): ISettingsApi => {
  const settingsApi = '/admin/settings'

  const get = (): Promise<ISettings> => api.get(settingsApi)
  const update = (settings: ISettings): Promise<{ success: boolean }> => api.patch(settingsApi, settings)

  return {
    get,
    update,
  }
}

export default SettingsApi()
