import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PromotionalBannerApi from 'src/api/promotionalBanner'
import AppContext from 'src/contexts/AppContext'
// import CustomDatatable from 'src/components/CustomDatatable'
import { Box } from '@chakra-ui/react'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableState } from 'src/components/CustomDataTable'
import { VisibilityTableToolbar } from 'src/components/CustomDataTable/VisibilityTableToolbar'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { PaginationResponse } from 'src/types/Pagination'
import { IPromotionalBanner } from 'src/types/PromotionalBanner'
import tableColumns from './tableColumns'

const PromotionalBanner = () => {
  const navigate = useNavigate()
  const [bannerList, setPromotionalBannerList] = React.useState<PaginationResponse<IPromotionalBanner>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const [tableState, setTableState] = useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  React.useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionalBannerApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<IPromotionalBanner>) => {
        setPromotionalBannerList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setPromotionalBannerList, tableState])

  const handleChangeStatus = (idList: string[], activate: boolean) => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    PromotionalBannerApi.changeStatus({ idList }, activate)
      .then(() => {
        setPromotionalBannerList((banners: PaginationResponse<IPromotionalBanner>) => ({
          data: banners.data.map((banner: IPromotionalBanner) => {
            if (idList.indexOf(banner.id) > -1) banner.isActive = activate
            return banner
          }),
          count: banners.count,
        }))
      })
      .catch((err: Error) => console.log(err))
      .finally(() => dispatch({ type: Types.Loading, payload: { loading: false } }))
  }

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  const handleClick = (rowIndex: number) => bannerList?.data && navigate(bannerList.data[rowIndex]?.id || bannerList.data[rowIndex]?._id)
  const handleAdd = () => navigate('new')

  const customToolbar = (selectedRows: number[]) => {
    return <VisibilityTableToolbar data={bannerList.data} handleChangeStatus={handleChangeStatus} selectedRows={selectedRows} />
  }

  return (
    <Page title="Promotional Banner">
      <Toolbar title="Promotional Banner" buttons={<PrimaryButton onClick={handleAdd}>Add Banner</PrimaryButton>} />
      <Box mt={3}>
        <CustomDataTable
          showCheckbox
          serverSidePagination
          customToolbar={customToolbar}
          onEditClick={handleClick}
          data={bannerList || []}
          tableColumns={tableColumns}
          tableState={tableState}
          onTableChange={onTableChange}
        />
      </Box>
    </Page>
  )
}

export default PromotionalBanner
