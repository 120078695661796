import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { IUser } from 'src/types/User'
import { api } from '.'

type IBusinessOwnerApi = {
  get(params: PaginationData<IUser>): Promise<PaginationResponse<IUser>>
  getById(id: string): Promise<IUser>
  changeStatus(idList: string[]): Promise<{ success: boolean }>
}

const BusinessOwnerApi = (): IBusinessOwnerApi => {
  const businessOwnerApi = '/admin/business-owner'

  const get = (params: PaginationData<IUser>): Promise<PaginationResponse<IUser>> => api.get(`${businessOwnerApi}${generateParamsFromPagination(params)}`)
  const getById = (id: string): Promise<IUser> => api.get(`${businessOwnerApi}/${id}`)
  const changeStatus = (idList: string[]): Promise<{ success: boolean }> => api.post(`${businessOwnerApi}/${idList}/status`)

  return {
    get,
    getById,
    changeStatus,
  }
}

export default BusinessOwnerApi()
