import { Avatar, Box, Card, CardBody, Flex, GridItem, SimpleGrid, Text, useDisclosure } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import StoreApi from 'src/api/store'
import { AlertSuccess } from 'src/components/Alert'
import { DataTextField } from 'src/components/Form/DataTextField'
import { StorePaymentDetailsModal } from 'src/components/Overlay/StorePaymentDetailsModal'
import { useAppContext } from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { IStore, IStorePaymentGroup } from 'src/types/User'

export function StorePayments() {
  const disclosure = useDisclosure()
  const [storeGroupList, setStoreGroupList] = useState<IStorePaymentGroup[]>([])
  const [selectedStoreGroup, setSelectedStoreGroup] = useState<IStorePaymentGroup>()
  const { dispatch } = useAppContext()
  const { onClose } = disclosure

  const groupStores = useCallback(
    (stores: IStore[]) => {
      let storeGroups: IStorePaymentGroup[] = []
      stores.forEach((store) => {
        const storeGroupIndex = storeGroups.findIndex((storeGroup) => storeGroup.businessOwnerId === store.businessOwnerId.toString())

        if (storeGroupIndex === -1) {
          storeGroups.push({
            stores: [store],
            businessOwnerId: store.businessOwnerId.toString(),
            ordersCount: store.ordersQuantity,
            totalToBePaid: store.total,
          })
        } else {
          storeGroups[storeGroupIndex].stores.push(store)
          storeGroups[storeGroupIndex].ordersCount += store.ordersQuantity
          storeGroups[storeGroupIndex].totalToBePaid += store.total
        }
      })

      if (storeGroups.length === 0) onClose()

      return storeGroups
    },
    [onClose]
  )

  useEffect(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    StoreApi.getWithPayment()
      .then((data: IStore[]) => {
        setStoreGroupList(groupStores(data))
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, groupStores, setStoreGroupList])

  const handleSelectStoreGroup = (storeGroup: IStorePaymentGroup) => {
    setSelectedStoreGroup(storeGroup)
    disclosure.onOpen()
  }

  const createTransfer = async (store: IStore, displayConfirmation: boolean) => {
    try {
      dispatch({ type: Types.Loading, payload: { loading: true } })
      const result = await StoreApi.createTransfer(store._id)
      if (result) {
        if (displayConfirmation) {
          AlertSuccess(`Transfer to ${store.businessName} completed successfully`)
          const groupedStores = groupStores(result)
          setStoreGroupList(groupedStores)
          setSelectedStoreGroup((prev) => (prev ? groupedStores.find((storeGroup) => storeGroup.businessOwnerId === prev.businessOwnerId) : prev))
        }
        dispatch({ type: Types.Loading, payload: { loading: false } })
        return { success: true }
      }
    } catch (error) {
      console.log(error)
    }

    dispatch({ type: Types.Loading, payload: { loading: false } })
    return { success: false }
  }

  return (
    <Box>
      <Box mt={3}>
        <SimpleGrid spacing={2} columns={[1, 1, 3]}>
          {storeGroupList.map((storeGroup) => (
            <GridItem cursor="pointer" key={storeGroup.businessOwnerId} w="100%" onClick={() => handleSelectStoreGroup(storeGroup)}>
              <Card>
                <CardBody>
                  <Flex gap={4} justify="center" direction="column">
                    <Flex gap={4} align="center">
                      <Avatar src={storeGroup.stores[0].businessLogo} />
                      <Text variant="text-18-bold">{storeGroup.stores[0].businessName}</Text>
                    </Flex>
                    <Flex justify="space-between">
                      <DataTextField title="To be paid">
                        <Text variant="text-16-reg">$ {(storeGroup.totalToBePaid / 100).toFixed(2)}</Text>
                      </DataTextField>
                      <DataTextField title="Number of Orders">
                        <Text variant="text-16-reg">{storeGroup.ordersCount}</Text>
                      </DataTextField>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      <StorePaymentDetailsModal
        onTransferClick={createTransfer}
        storeGroup={selectedStoreGroup}
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      />
    </Box>
  )
}