import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { IAddress } from 'src/types/Address'
import { DataTextField } from '../Form/DataTextField'
import { FormCard } from './FormCard'

interface AmbassadorLocationCardProps {
  location: IAddress
}

export function AmbassadorLocationCard({ location }: AmbassadorLocationCardProps) {
  return (
    <FormCard title="Ambassador Location">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        <GridItem>
          <DataTextField title="Suburb">{location?.suburb ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="State">{location?.state ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Country">{location?.country ?? '-'}</DataTextField>
        </GridItem>
        <GridItem>
          <DataTextField title="Postcode">{location?.postcode ?? '-'}</DataTextField>
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}
