import OrderCancelTypeEnum from 'src/enum/OrderCancelType.enum'
import OrderStatusEnum from 'src/enum/OrderStatusEnum'
import IUser, { IStore } from './User'
import { IVoucher } from './Voucher'

export interface IOrder {
  _id?: string
  id: string
  status: OrderStatusEnum
  completeName: string
  total?: number
  orderTotal: number
  voucherId?: string
  gst: number
  paymentMethod: string
  receiptNumber: string
  voucherUsed?: number
  creditsUsed?: number
  amountRoundedUp?: number
  amountTransferredToBusiness?: number
  amountTransferredToAmbassador?: number
  providerFees?: number
  qrCode?: string
  code: string
  history: IOrderHistory[]
  items: IOrderItem[]
  pickupTime: {
    from: Date
    to: Date
  }

  cancelReason?: string
  cancelType?: keyof typeof OrderCancelTypeEnum
  cancelledAt?: Date
  fee?: number

  collectDate: Date
  hasReview: boolean
  user?: IUser
  voucher?: IVoucher
  grocer?: IStore
  createdAt?: Date
}

export interface IOrderItem {
  productId: string
  price: number
  quantity: number
  name: string
  description: string
  picture: string
}

export interface IOrderHistory {
  status: keyof typeof OrderStatusEnum
  comment: string
  createdAt: Date
}

export interface IOrderFilter {
  grocerList?: string[]
  code?: string
  customer?: string
  dateFrom?: Date | null
  dateTo?: Date | null
}

export type IOrderCancellationInitialValueProps = Pick<IOrder, 'cancelReason' | 'cancelType'>

export const initialOrderFilter = {
  grocerList: [],
  code: null,
  customer: null,
  dateFrom: null,
  dateTo: null,
}

export const orderCancellationInitialValue: IOrderCancellationInitialValueProps = {
  cancelReason: '',
  cancelType: undefined,
}

export const orderInitialValue: IOrder = {
  id: '',
  status: OrderStatusEnum.READY,
  completeName: '',
  orderTotal: 0,
  gst: 0,
  paymentMethod: '',
  receiptNumber: '',
  voucherUsed: 0,
  creditsUsed: 0,
  code: '',
  history: [],
  items: [],
  pickupTime: {
    from: new Date(),
    to: new Date(),
  },
  collectDate: new Date(),
  hasReview: false,
  voucher: {
    amount: 0,
    code: '',
  },
}

export default IOrder
