import { generateParamsFromPagination } from 'src/helpers/paginationHelper'
import { PaginationData } from 'src/types/Paginate'
import { PaginationResponse } from 'src/types/Pagination'
import { IPromotionalBanner } from 'src/types/PromotionalBanner'
import { api } from '.'

type IPromotionalBannerApi = {
  get(params: PaginationData<IPromotionalBanner>): Promise<PaginationResponse<IPromotionalBanner>>
  listActive(): Promise<IPromotionalBanner[]>
  getById(id: string): Promise<IPromotionalBanner>
  changeStatus(idList: { idList: string[] }, activate: boolean): Promise<{ success: boolean }>
  create(pb: IPromotionalBanner): Promise<{ success: boolean }>
  update(pb: Partial<IPromotionalBanner>): Promise<{ success: boolean }>
}

const PromotionalBannerApi = (): IPromotionalBannerApi => {
  const bannerApi = '/admin/promotional-banner'

  const get = (params: PaginationData<IPromotionalBanner>): Promise<PaginationResponse<IPromotionalBanner>> =>
    api.get(`${bannerApi}${generateParamsFromPagination(params)}`)
  const listActive = (): Promise<IPromotionalBanner[]> => api.get('/promotional-banner')
  const create = (pb: IPromotionalBanner): Promise<{ success: boolean }> => api.post(bannerApi, pb)
  const update = (pb: Partial<IPromotionalBanner>): Promise<{ success: boolean }> => {
    const { _id, ...rest } = pb
    return api.patch(`${bannerApi}/${_id}`, rest)
  }
  const getById = (id: string): Promise<IPromotionalBanner> => api.get(`${bannerApi}/${id}`)
  const changeStatus = (idList: { idList: string[] }, activate: boolean): Promise<{ success: boolean }> =>
    api.patch(`${bannerApi}/change-status`, { idList: idList.idList, activate })

  return {
    get,
    listActive,
    getById,
    changeStatus,
    create,
    update,
  }
}

export default PromotionalBannerApi()
