import { Checkbox, Th, Thead, Tr } from '@chakra-ui/react'
import { TableColumn } from '.'

export interface CustomDataTableHeaderProps {
  showCheckbox: boolean
  showActionsColumn: boolean
  tableColumns: TableColumn<any>[]
  isAllCheckboxesChecked: boolean
  singleSelection?: boolean
  onCheckBoxClick?: () => void
}

export function CustomDataTableHeader({
  showCheckbox,
  isAllCheckboxesChecked,
  tableColumns,
  showActionsColumn,
  onCheckBoxClick,
  singleSelection,
}: CustomDataTableHeaderProps) {
  return (
    <Thead bg="gray.300" h="3rem">
      <Tr>
        {showCheckbox && (
          <Th px={['4', '4', '6']} width="8">
            <Checkbox borderColor="black" isChecked={isAllCheckboxesChecked} onChange={onCheckBoxClick} disabled={singleSelection} />
          </Th>
        )}
        {tableColumns.map((column, index) => (
          <Th key={`${column.name}-${index}`} textAlign={column.isBoolean ? 'center' : 'left'} textTransform="initial" fontSize="sm" color="gray.800">
            {column.label}
          </Th>
        ))}
        {showActionsColumn && (
          <Th isNumeric fontSize="sm" color="gray.800" textTransform="initial" pr="4">
            Actions
          </Th>
        )}
      </Tr>
    </Thead>
  )
}
