import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import SurveyApi from 'src/api/survey'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { ISurvey } from 'src/types/Survey'
import tableColumns from './tableColumns'

const Survey = () => {
  const navigate = useNavigate()
  const [surveyList, setSurveyList] = React.useState<ISurvey[]>([])
  const { dispatch } = React.useContext(AppContext)

  const getSurveyList = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    SurveyApi.get()
      .then((data: ISurvey[]) => {
        setSurveyList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setSurveyList])

  React.useEffect(() => {
    getSurveyList()
  }, [getSurveyList])

  const handleClick = (rowIndex: number) => navigate(surveyList[rowIndex].id)

  const handleAdd = () => navigate('new')

  return (
    <Page title="Surveys">
      <Toolbar
        title="Surveys"
        buttons={
          <Flex gap={2}>
            <PrimaryButton onClick={handleAdd}>Add Survey</PrimaryButton>
          </Flex>
        }
      />
      <Box mt={3}>
        <CustomDataTable onEditClick={handleClick} data={surveyList || []} tableColumns={tableColumns} />
      </Box>
    </Page>
  )
}

export default Survey
