import { GridItem, SimpleGrid } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import FileUpload from 'src/components/Form/FileUpload'
import ImageUpload from 'src/components/Form/ImageUpload'
import { Input } from 'src/components/Form/Input'
import { FormCard } from 'src/components/Layout/FormCard'
import { IAccountManager } from 'src/types/AccountManager'
import { Formik } from 'src/types/Formik'
import { IUploadFile } from 'src/types/UploadFile'

const AccountManagerForm: React.FC<{ formik: Formik<IAccountManager> }> = ({ formik }) => {
  const { errors, values, handleBlur, handleChange, setFieldValue } = formik

  const onUpload = useCallback(
    (name: string, _uploadFile: IUploadFile, _preview: string, file: File) => {
      setFieldValue(name, file)
    },
    [setFieldValue]
  )

  const onUploadTermsAndConditions = useCallback(
    (name: string, file: File) => {
      setFieldValue(name, file)
    },
    [setFieldValue]
  )

  return (
    <FormCard title="Account Manager Details">
      <SimpleGrid columns={[1, 1, 4]} spacing={4}>
        <GridItem>
          <Input error={errors.code} label="Code" name="code" onBlur={handleBlur} onChange={handleChange} value={values.code} />
        </GridItem>
        <GridItem>
          <Input error={errors.firstName} label="First Name" name="firstName" onBlur={handleBlur} onChange={handleChange} value={values.firstName} />
        </GridItem>
        <GridItem>
          <Input error={errors.lastName} label="Last Name" name="lastName" onBlur={handleBlur} onChange={handleChange} value={values.lastName} />
        </GridItem>
        <GridItem>
          <Input
            error={errors.companyName}
            label="Company Name"
            name="companyName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.companyName}
          />
        </GridItem>
        <GridItem>
          <Input
            error={errors.phoneNumber}
            type="number"
            label="Phone Number"
            name="phoneNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.phoneNumber}
          />
        </GridItem>
        <GridItem colSpan={[1, 1, 2]}>
          <Input error={errors.email} label="Email" name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} />
        </GridItem>
        <GridItem>
          <Input
            error={errors.trialDurationInDays}
            label="Trial Duration in Days"
            name="trialDurationInDays"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.trialDurationInDays}
            type="number"
          />
        </GridItem>
        <GridItem>
          <ImageUpload onUpload={onUpload} name="pictureFile" showPreview={true} currentPicture={values.picture} label="Partner Image" />
        </GridItem>
        <GridItem>
          <FileUpload
            onUpload={onUploadTermsAndConditions}
            name="termsAndConditionsFile"
            label="Terms and Conditions (PDF)"
            defaultUrl={values.termsAndConditions}
          />
        </GridItem>
      </SimpleGrid>
    </FormCard>
  )
}

export default AccountManagerForm
