import { Box, Input as ChakraInput, InputProps as ChakraInputProps, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { FormikErrors } from 'formik'
import { ChangeEvent, ForwardRefRenderFunction, forwardRef } from 'react'
import { NUMBER_ONLY_REGEX } from 'src/constants'
import { Label } from './Label'

export interface InputProps extends ChakraInputProps {
  name: string
  label?: string
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  isCurrency?: boolean
  disabled?: boolean
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ name, label, error = null, onChange, isCurrency, ...rest }, ref) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (rest.type === 'number') {
      if (event.target.value === '' || NUMBER_ONLY_REGEX.test(event.target.value)) {
        onChange(event)
      } else {
        return
      }
    }
    onChange(event)
  }

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <Label htmlFor={name} label={label} mb="0" />}

      <Box
        _before={
          isCurrency && {
            content: '"$"',
            position: 'absolute',
            fontSize: '1rem',
            left: '0.75rem',
            top: error ? '50%' : '69%',
            transform: 'translateY(-50%)',
            color: 'gray.500',
            zIndex: 4,
          }
        }
      >
        <ChakraInput
          id={name}
          name={name}
          focusBorderColor="primary.500"
          borderColor="gray.300"
          border="1px solid"
          variant="filled"
          size="md"
          ref={ref}
          position="relative"
          onChange={handleChange}
          pl={isCurrency ? '1.5rem' : '4'}
          {...rest}
        />
      </Box>
      {!!error && <FormErrorMessage>{typeof error === 'string' ? error : ''}</FormErrorMessage>}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
