import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CharityApi from 'src/api/charity'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { CustomDataTable, CustomDataTableOnChangeActions, CustomDataTableState } from 'src/components/CustomDataTable'
import Toolbar from 'src/components/Layout/Toolbar'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { generatePaginationData } from 'src/helpers/paginationHelper'
import { Types } from 'src/reducers/AppReducer'
import { ICharity } from 'src/types/Charity'
import { PaginationResponse } from 'src/types/Pagination'
import tableColumns from './tableColumns'

const Charity = () => {
  const navigate = useNavigate()
  const [charityList, setCharityList] = React.useState<PaginationResponse<ICharity>>({ data: [], count: 0 })
  const { dispatch } = React.useContext(AppContext)
  const [tableState, setTableState] = React.useState<CustomDataTableState>({
    currentPage: 1,
    skip: 0,
    limit: 10,
    totalItems: 0,
  })

  const getCharityList = React.useCallback(() => {
    dispatch({ type: Types.Loading, payload: { loading: true } })
    CharityApi.get(generatePaginationData(tableState))
      .then((data: PaginationResponse<ICharity>) => {
        setCharityList(data)
      })
      .finally(() => {
        dispatch({ type: Types.Loading, payload: { loading: false } })
      })
  }, [dispatch, setCharityList, tableState])

  React.useEffect(() => {
    getCharityList()
  }, [getCharityList])

  const handleClick = (rowIndex: number) => navigate(charityList.data[rowIndex].id)

  const handleAdd = () => navigate('new')

  const onTableChange = (_action: CustomDataTableOnChangeActions, newTableState: CustomDataTableState) => {
    setTableState(newTableState)
  }

  return (
    <Page title="Beneficiaries">
      <Toolbar
        title="Beneficiaries"
        buttons={
          <Flex gap={2}>
            <PrimaryButton onClick={handleAdd}>Add Beneficiary</PrimaryButton>
          </Flex>
        }
      />
      <Box mt={3}>
        <CustomDataTable onEditClick={handleClick} data={charityList} tableColumns={tableColumns} tableState={tableState}
          onTableChange={onTableChange} serverSidePagination />
      </Box>
    </Page>
  )
}

export default Charity
