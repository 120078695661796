import { Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/react'
import { useFormik } from 'formik'
import React from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import AuthApi from 'src/api/auth'
import { dismissAllAlerts } from 'src/components/Alert'
import { PrimaryButton } from 'src/components/Button/PrimaryButton'
import { Input } from 'src/components/Form/Input'
import Page from 'src/components/Page'
import AppContext from 'src/contexts/AppContext'
import { Types } from 'src/reducers/AppReducer'
import { AuthInitialValue, IAuthResponse } from 'src/types/Auth'
import validationSchema from './validationSchema'

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { dispatch } = React.useContext(AppContext)
  const [error, setError] = React.useState<boolean>(false)

  const params = React.useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const onSubmit = React.useCallback(
    (values: any) => {
      dismissAllAlerts()
      dispatch({ type: Types.Loading, payload: { loading: true } })
      AuthApi.signin({ email: values.email, password: values.password })
        .then((data: IAuthResponse) => {
          if (data.qrCode)
            return navigate(`/mfa?code=${encodeURIComponent(data.qrCode)}&email=${encodeURIComponent(values.email)}`, { replace: true })
          if (data.success) return navigate(`/mfa?email=${encodeURIComponent(values.email)}`, { replace: true })
          setError(false)
          dispatch({
            type: Types.Authenticate,
            payload: {
              user: data.user,
              isAuthenticated: true,
              token: data.token,
              refreshToken: data.refreshToken,
            },
          })
          const urlRequested: string | null = params.get('url')
          navigate(urlRequested ? urlRequested.replace(window.location.origin, '') : '/app/dashboard', { replace: true })
        })
        .catch((err) => {
          console.log(err)
          setError(true)
        })
        .finally(() => {
          dispatch({ type: Types.Loading, payload: { loading: false } })
        })
    },
    [navigate, dispatch, params]
  )

  const { errors, values, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: AuthInitialValue,
    onSubmit,
    validationSchema,
  })

  React.useEffect(() => {
    if (error) resetForm()
  }, [error, resetForm])

  return (
    <Page title="Login">
      <Flex direction="column" height="100%" minW={['100%', '100%', '20rem']} justify="center" gap="6">
        <form onSubmit={handleSubmit}>
          <SimpleGrid minChildWidth="240px" spacing="4" w="100%" mb="3">
            <GridItem>
              <Input error={errors.email} label="Email" name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} />
            </GridItem>
            <GridItem>
              <Input
                error={errors.password}
                label="Password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
              />
            </GridItem>
          </SimpleGrid>

          <RouterLink to="/forgot-password">
            <Text color="primary" variant="body1">
              Forgot password
            </Text>
          </RouterLink>

          <PrimaryButton w="100%" type="submit" mt="4">
            Log in
          </PrimaryButton>
        </form>
      </Flex>
    </Page>
  )
}

export default Login
