import {
  Box,
  Center,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IStore, IStorePaymentGroup } from 'src/types/User'
import { PrimaryButton } from '../Button/PrimaryButton'
import { StorePaymentCard } from '../Layout/StorePaymentCard'
import ConfirmationModal from './ConfirmationModal'

interface StorePaymentDetailsModalProps {
  storeGroup: IStorePaymentGroup
  onClose: () => void
  onTransferClick: (store: IStore, displayConfirmation: boolean) => Promise<{ success: boolean }>
  isOpen: boolean
}

export function StorePaymentDetailsModal({ onTransferClick, storeGroup, onClose, isOpen }: StorePaymentDetailsModalProps) {
  const confirmationDisclosure = useDisclosure()
  const [isProcessingPayments, setIsProcessingPayments] = useState<boolean>(false)
  const [paymentsMade, setPaymentsMade] = useState<string[]>([])

  const handleTransferToAll = async () => {
    setIsProcessingPayments(true)
    for (let index = 0; index < storeGroup?.stores.length; index++) {
      const store = storeGroup?.stores[index]
      const result = await onTransferClick(store, index === storeGroup.stores.length - 1)
      if (result.success) {
        setPaymentsMade((prev) => [...prev, store._id])
      }
    }
  }

  const handleClose = () => {
    setIsProcessingPayments(false)
    setPaymentsMade([])
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Store Group: {storeGroup?.businessOwnerId.toString() || '-'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isProcessingPayments ? (
            <Center h="100%" w="100%" flexDirection="column" gap={4}>
              {paymentsMade.length === storeGroup?.stores?.length ? (
                <Flex alignItems="center" gap={2}>
                  <FaRegCheckCircle color="green" />
                  <Text>Store group payment completed</Text>
                </Flex>
              ) : (
                <Text>Processing payments...</Text>
              )}
              <Center w="full" flexDirection="column" gap={0}>
                <Progress
                  hasStripe={paymentsMade.length < storeGroup?.stores.length}
                  isAnimated={paymentsMade.length < storeGroup?.stores.length}
                  value={paymentsMade.length}
                  size="lg"
                  w="full"
                  maxW="xl"
                  borderRadius="lg"
                  max={storeGroup?.stores.length}
                />
                <Text fontSize="sm">
                  {paymentsMade.length} completed of {storeGroup?.stores.length} stores
                </Text>
              </Center>
              <Divider my={4} />
              <Center w="full" flexWrap="wrap" gap={10} mb={4} maxW="80%">
                {storeGroup?.stores.map((store) => (
                  <Flex key={store._id} alignItems="center" gap={2}>
                    <Box opacity={paymentsMade.includes(store._id) ? 1 : 0} transition="opacity 2s linear">
                      <FaRegCheckCircle />
                    </Box>
                    <Text
                      textDecoration={paymentsMade.includes(store._id) ? 'line-through' : 'none'}
                      animation={paymentsMade.includes(store._id) ? 'strike 2s linear' : 'none'}
                    >
                      {`${store.businessName} - ${store.suburb}`}
                    </Text>
                  </Flex>
                ))}
              </Center>
            </Center>
          ) : (
            <>
              {storeGroup?.stores.length > 0 ? (
                <Flex gap="4" wrap="wrap" justify="center">
                  {storeGroup?.stores.map((store) => (
                    <StorePaymentCard store={store} onTransferClick={onTransferClick} key={store._id} />
                  ))}
                </Flex>
              ) : (
                <Center h={['100%', '100%', '8rem']}>No pending payments for this group</Center>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <PrimaryButton
            isDangerous
            onClick={confirmationDisclosure.onOpen}
            isDisabled={storeGroup?.totalToBePaid <= 0 || paymentsMade.length === storeGroup?.stores.length}
            mr="2"
          >
            Pay All Stores
          </PrimaryButton>
          <PrimaryButton onClick={handleClose}>Close</PrimaryButton>
        </ModalFooter>
      </ModalContent>
      <ConfirmationModal handleClose={confirmationDisclosure.onClose} open={confirmationDisclosure.isOpen} handleConfirm={handleTransferToAll} />
    </Modal>
  )
}
