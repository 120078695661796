import { Input as ChakraInput, InputProps as ChakraInputProps, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { FormikErrors } from 'formik'
import { ForwardRefRenderFunction, forwardRef } from 'react'
import { ChakraSpan } from './ChakraSpan'
import { Label } from './Label'

interface InputColorProps extends ChakraInputProps {
  name: string
  label?: string
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  disabled?: boolean
}

const InputColorBase: ForwardRefRenderFunction<HTMLInputElement, InputColorProps> = ({ name, label, value, error = null, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <Label htmlFor={name} label={label} />}

      <Flex gap={4} align="center">
        <Flex justify="center" align="center" borderRadius="full" overflow="hidden" w="3rem" h="3rem">
          <ChakraInput
            id={name}
            name={name}
            focusBorderColor="red.500"
            bgColor="transparent"
            variant="filled"
            _hover={{
              bgColor: 'gray.900',
            }}
            p="0"
            size="lg"
            ref={ref}
            w="5rem"
            h="5rem"
            minW="5rem"
            value={value !== '' ? value : '#000000'}
            {...rest}
            type="color"
          />
        </Flex>
        {value ? (
          <ChakraSpan fontSize="2xl" fontWeight="bold">
            {value}
          </ChakraSpan>
        ) : (
          <ChakraSpan fontStyle="italic" color="gray.500">
            Click on the circle to select a color
          </ChakraSpan>
        )}
      </Flex>
      {!!error && <FormErrorMessage>{typeof error === 'string' ? error : ''}</FormErrorMessage>}
    </FormControl>
  )
}

export const InputColor = forwardRef(InputColorBase)
