import { TableColumn } from 'src/components/CustomDataTable'
import { IStore } from 'src/types/User'

const tableColumns: TableColumn<IStore>[] = [
  {
    label: 'Code',
    name: 'code',
  },
  {
    label: 'Name',
    name: 'businessName',
    options: {
      customRender: (store) => {
        return `${store.businessName} - ${store['address.suburb']}`
      },
    },
  },
  {
    label: 'Contact',
    name: 'contactName',
  },
  {
    label: 'Active',
    name: 'active',
    isBoolean: true,
  },
  {
    label: 'Visible',
    name: 'isVisible',
    isBoolean: true,
  },
  {
    label: 'Approved',
    name: 'approved',
    isBoolean: true,
  },
]

export default tableColumns
