import OrderCancelTypeEnum from 'src/enum/OrderCancelType.enum'
import { enumKeys } from 'src/helpers/enumHelper'
import * as Yup from 'yup'

export default Yup.object().shape({
  cancelReason: Yup.string().required('Reason is required'),
  cancelType: Yup.string()
    .required('Responsible for cancellation is required')
    .oneOf(enumKeys(OrderCancelTypeEnum), 'Responsible for cancellation is invalid'),
})
