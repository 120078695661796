import { Button, ButtonProps } from '@chakra-ui/react'

interface PaginationItemProps extends ButtonProps {
  pageNumber: number
  isCurrent?: boolean
}

export function PaginationItem({ isCurrent = false, pageNumber, ...args }: PaginationItemProps) {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        width="4"
        disabled
        _disabled={{ bg: 'primary.500', cursor: 'default' }}
        {...args}
        onClick={!isCurrent ? args.onClick : undefined}
      >
        {pageNumber}
      </Button>
    )
  }

  return (
    <Button
      size="sm"
      fontSize="xs"
      width="4"
      variant="outline"
      bg="gray.200"
      _hover={{
        bg: 'gray.300',
      }}
      {...args}
    >
      {pageNumber}
    </Button>
  )
}
