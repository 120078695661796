import { IconButton, IconButtonProps, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'

interface CustomIconButtonProps extends IconButtonProps {
  icon: ReactElement
  label?: string
}

export const CustomIconButton = ({ icon, label, ...args }: CustomIconButtonProps) => {
  return (
    <IconButton variant="ghost" _hover={{ backgroundColor: 'transparent' }} color="gray.800" minW="6" minH="6" {...args}>
      <>
        {icon}
        {label && <Text ml="2">{label}</Text>}
      </>
    </IconButton>
  )
}
