import { Flex, Text } from '@chakra-ui/react'
import { ImEye, ImEyeBlocked } from 'react-icons/im'
import { ToolbarButton } from 'src/components/Button/ToolbarButton'
import { IModelId } from 'src/types/Model'

interface VisibilityTableToolbarProps<T> {
  handleChangeStatus: (idList: string[], activate: boolean) => void
  selectedRows: number[]
  data: T[]
  extraButtons?: React.ReactNode
}

export function VisibilityTableToolbar<T extends IModelId>({ data, handleChangeStatus, selectedRows, extraButtons }: VisibilityTableToolbarProps<T>) {
  const handleClick = (activate: boolean) => {
    handleChangeStatus(
      data.filter((_, index: number) => selectedRows.includes(index)).map((item: T) => item.id || item._id),
      activate
    )
  }

  return (
    <Flex justify="space-between" align="center">
      <Text variant="text-18-bold">Actions with selected rows</Text>
      <Flex gap={2} justify="flex-end">
        <ToolbarButton onClick={() => handleClick(false)} icon={<ImEyeBlocked />} label="Hide" />
        <ToolbarButton onClick={() => handleClick(true)} icon={<ImEye />} label="Show" />
        {extraButtons}
      </Flex>
    </Flex>
  )
}
