import { Box, Center } from '@chakra-ui/react'
import { Formik } from 'src/types/Formik'
import { IStore } from 'src/types/User'
import { Input } from '../Form/Input'
import { FormCard } from './FormCard'

interface StorePromoVideoCardProps {
  store: IStore
  formik: Partial<Formik<IStore>>
}

export function StorePromoVideoCard({ store, formik }: StorePromoVideoCardProps) {
  const { errors, values, handleBlur, handleChange } = formik

  return (
    <FormCard title="Store Promo Video Card">
      <Center flexDirection="column">
        <Box borderRadius="15px" overflow={'hidden'} width="fit-content">
          {
            store?.businessVideo && (
              <iframe title='YouTube video player' width='250' src={store?.businessVideo} allowFullScreen />
            )
          }
        </Box>
        <Input
          error={errors.businessVideo}
          label="Video URL"
          name="businessVideo"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values?.businessVideo}
        />
      </Center>
    </FormCard>
  )
}
